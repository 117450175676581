import { useState } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import withAuth from '../../../components/hoc/withAuth';
import PowerBiReport from "../../../components/powerBi/PowerBiReport";


const PopulationCharacterization = () => {
    const [key, setKey] = useState('coreDemographic');

    const tabsContent = [
        {
            eventKey: 'coreDemographic',
            title: 'Core Demographics',
            reportName: 'coreDemographic'
        },
        {
            eventKey: 'financial-index',
            title: 'Financial Index',
        },
        {
            eventKey: 'geographicDispersion',
            title: 'Geographic Dispersion',
            reportName: 'geographicDispersion',
        },
        {
            eventKey: 'healthAccess',
            title: 'Health Access',
            reportName: 'healthAccess',
        },
        {
            eventKey: 'compareCensus',
            title: 'Compare Census Data',
            reportName: 'compareCensus',
        },
        {
            eventKey: 'technology',
            title: 'Technology Literacy',
            reportName: 'technology',
        },
        {
            eventKey: 'other-insights',
            title: 'Other Insights',
        }
    ];

    return (
        <div className="h-100 d-flex flex-column">
            <div className={`flex-grow-1 tab-container`}>
                <Tabs
                    defaultActiveKey="core-demographics"
                    activeKey={key}
                    onSelect={(k) => setKey(k)}
                    variant="underline"
                >
                    {
                        tabsContent.map((content) => (
                            <Tab
                                key={content.eventKey}
                                eventKey={content.eventKey}
                                title={content.title}
                                className="flex-grow-1 mt-1"
                            >
                                {content.eventKey === key && <PowerBiReport
                                    title={content.title}
                                    reportName={content.reportName}
                                />}
                            </Tab>
                        ))
                    }
                </Tabs>
            </div>
        </div>
    );
};

export default withAuth(PopulationCharacterization);
