import { useEffect, useMemo, useState } from "react";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import { Calendar, momentLocalizer, Views, Navigate as navigate } from "react-big-calendar";
import withAuth from '../../../components/hoc/withAuth';
import Icon from "../../../components/ui/Icon";
import styles from './CalendarView.module.scss';

const localizer = momentLocalizer(moment);

const EventsComponent = ({ event }) => {
    const navigate = useNavigate();

    return (
        <div
            className="wrapper"
            onClick={() =>
                navigate(
                    `/advisory-group-management/operations/meeting-details/${event.id}/${event.aGId}`
                )
            }>
            {event.title}
        </div>
    );
};

const CustomToolbar = ({
    label,
    localizer: { messages },
    onNavigate,
    // onView,
    // view,
    // views,
}) => (
    <div className="rbc-toolbar d-flex align-items-center">
        <Button
            type="button"
            variant="link"
            onClick={() => onNavigate(navigate.PREVIOUS)}
            aria-label={messages.previous}
        >
            <Icon icon="arrow-down" size="12" className="rotate-90" />
        </Button>
        <div className={`${styles.monthTitle} text-center mx-2`}>{label}</div>
        <Button
            type="button"
            variant="link"
            onClick={() => onNavigate(navigate.NEXT)}
            aria-label={messages.next}
        >
            <Icon icon="arrow-up" size="12" className="rotate-90" />
        </Button>
    </div>
);

const CalendarView = ({ data }) => {

    const [events, setEvents] = useState([]);

    const { components, formats } = useMemo(
        () => ({
            components: {
                toolbar: CustomToolbar,
                event: EventsComponent,
            },
            formats: {
                // the 'date' on each day cell of the 'month' view
                dateFormat: 'D',
                // the day of the week header in the 'month' view
                weekdayFormat: (date, culture, localizer) =>
                    localizer.format(date, 'dddd', culture),
                // the day header in the 'week' and 'day' (Time Grid) views
                /*dayFormat: (date, culture, localizer) =>
                    localizer.format(date, 'dddd Do', culture),*/
                // the time in the gutter in the Time Grid views
                /*timeGutterFormat: (date, culture, localizer) =>
                    localizer.format(date, 'hh:mm a', culture),*/
            },
        }),
        []
    )

    useEffect(() => {
        if (data) {
            let eventsList = data.map((meet) => {
                return {
                    id: meet.id,
                    title: `${meet.shortName} - ${meet.meetingLocation}`,
                    allDay: true,
                    start: new Date(moment(meet.nextMeetingTime).startOf('day')),
                    end: new Date(moment(meet.nextMeetingTime).endOf('day')),
                    aGId: meet.aGId
                }
            });
            setEvents(eventsList);
        }
    }, [data]);

    return (
        <div className="flex-grow-1">
            <Calendar
                formats={formats}
                components={components}
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                views={[Views.MONTH]}
                style={{ height: 500 }}
                popup
            />
        </div>
    );
};

export default withAuth(CalendarView);
