import React from "react";
import withAuth from '../../../components/hoc/withAuth';
import Placeholder from '../../../components/ui/Placeholder';

const Assessment = () => {
    return (
        <Placeholder title='Assessment' type="graphic" />
    );
};

export default withAuth(Assessment);
