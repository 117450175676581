import React, { useEffect, useState, useContext } from 'react';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import PowerBiStarExplorer from './powerBiStarExplorer';
import { useSelector } from 'react-redux';
import withAuth from '../../components/hoc/withAuth';

function Home() {

    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    const TextContent = () => {
        return (
            <>
                <h2 className="h4">A New Model for 2021</h2>
                <p>As we look at the results for the 2021 CMS Hospital Stars Program, it is important to note that this was the first year for the Version 4.0 model which includes the concept of creating peer groups based on the number of measure groups for which hospitals report at least three measures. The new model also recategorized several safety groups into a single Patient Safety category. The interactive graphs above provide some insight into the size of the different peer groups as well as their relative makeup both in terms of hospital size and the best and worst performing states and territories.</p>
            </>
        )
    }

    useEffect(() => {
        if (isAppLoaded && report.home.embedId)
            setReportConfig({
                ...report.home,
                accessToken: report.accessToken,
            });
    }, [report, isAppLoaded]);

    return (
        <PowerBiStarExplorer
            reportConfig={reportConfig}
            content={<TextContent />}
        />
    );
}

export default withAuth(Home);
