import React, { useEffect, useState, useContext } from 'react';
import { useSelector } from 'react-redux'
import { AppLoadContext } from '../../components/ui/AppLoadContext'
import PowerBiStarExplorer from './powerBiStarExplorer';
import withAuth from "../../components/hoc/withAuth";

function StateOverview() {
    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    useEffect(() => {
        if (isAppLoaded && report.stateOverview.embedId)
            setReportConfig({ ...report.stateOverview, accessToken: report.accessToken });
    }, [report, isAppLoaded]);

    return (
        <PowerBiStarExplorer
            reportConfig={reportConfig}
            title='State Overview'
        />
    );
}

export default withAuth(StateOverview);
