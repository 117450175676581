import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import styles from "./RecruitmentMemberArchiveConfirmModal.module.scss";


const RecruitmentMemberArchiveConfirmModal = ({show, members, message, onConfirm, onCancel, onHideCallback}) => {
    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback}>
            <Modal.Header>
                <Modal.Title>Archive AG Members</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <div className="mb-4 text-end">
                        <Button variant="light" className="me-2" onClick={onCancel}>Close</Button>
                        <Button variant="primary" onClick={onConfirm}>Confirm</Button>
                    </div>
                    <div className={styles.message}>
                        {message}
                    </div>
                    <div className="mt-2 px-4">
                        {members?.map(member => (
                            <div className="d-flex gap-2 mb-2" key={member.id}>
                                <div>{`${member.firstName} ${member.lastName}`}</div>
                                <div>Member ID: {member.memberId}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
)
}

export default RecruitmentMemberArchiveConfirmModal;
