function SessionLogout() {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center p-3 p-md-5">
                <h1 className="fw-bold">Session Logout</h1>
                <div className="h5">This account has been logged in from another session.</div>
            </div>
        </div>
    );
}

export default SessionLogout;
