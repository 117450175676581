import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import withAuth from "../../../components/hoc/withAuth";
import ActionBar from "../../../components/ui/ActionBar";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { getProgramsByStatus } from "../../../ducks/programManagement";
import { isUserAdmin, toDateTime } from "../../../utils/utils";
import { PROGRAM_TYPE, US_STATE, PROGRAM_MEETING_FREQUENCY, PROGRAM_MEETING_TYPE, PROGRAM_STATUS, FORM_ACTION_TYPE } from "../../../constants";
import OverflowText from "../../../components/ui/OverflowTooltip";
import ProgramModal from "./ProgramModal";
import Icon from "../../../components/ui/Icon";
import GridButton from "../../../components/ui/GridButton";

const ArchivedPrograms = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const programs = useSelector((state) => state.programManagement.archivedPrograms);
    const currentUser = useSelector((state) => state.user.currentUser);
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);

    //Edit Program
    const handleEdit = (data) => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.Edit);
        setCurrentRowData(data);
    }
    //View Program
    const handleView = (data) => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.View);
        setCurrentRowData(data);
    }
    //Copy Program
    const handleCopy = (data) => {
        const copyData = { ...data, name: '' };
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.Copy);
        setCurrentRowData(copyData);
    }
    const handleModalClose = () => {
        setShowModal(false);
        setCurrentRowData(null);
    }

    const columns = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text'
        },
        {
            id: 'type',
            header: 'Type',
            accessorKey: 'type',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_TYPE[row.original.type]}
                </OverflowText>
            ),
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_STATUS[row.original.status]}
                </OverflowText>
            ),
        },
        {
            id: 'dateTime',
            header: 'Last Update',
            accessorKey: 'modifiedDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.modifiedDateTime)),
            cell: ({ row }) => `${toDateTime(row.original.modifiedDateTime, true)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            id: 'targetSize',
            header: 'Target Size',
            accessorKey: 'targetSize',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.targetSize}
                </OverflowText>
            ),
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {US_STATE[row.original.market]}
                </OverflowText>
            ),
        },
        {
            id: 'meetingFrequency',
            header: 'Meeting Frequency',
            accessorKey: 'meetingFrequency',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_FREQUENCY[row.original.meetingFrequency]}
                </OverflowText>
            ),
        },
        {
            id: 'meetingType',
            header: 'Meeting Type',
            accessorKey: 'meetingType',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_TYPE[row.original.meetingType]}
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 120,
            cell: ({ row }) => (
                <>
                    {
                        !isUserAdmin(currentUser) ? (
                            <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                                onClick={() => { handleView(row.original) }}>
                                <Icon icon="view" size={16} />
                            </GridButton>
                        ) : (
                            <>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                                    onClick={() => { handleEdit(row.original) }}>
                                    <Icon icon="edit" size={16} />
                                </GridButton>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Copy"
                                    onClick={() => { handleCopy(row.original) }}>
                                    <Icon icon="duplicate" size={16} />
                                </GridButton>
                            </>
                        )
                    }
                </>
            )
        },
    ];

    const defaultSort = [{
        id: 'shortName',
        desc: false
    }];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getProgramsByStatus(currentUser?.companyId, PROGRAM_STATUS.Archived));
        }
    }, [isAppLoaded, currentUser]);

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" variant="outline-primary"
                    onClick={() => navigate(`/advisory-group-management/program-management`)}>
                    View Active
                </Button>
            </ActionBar>

            <div className="flex-grow-1">
                {programs === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        columns={columns}
                        data={programs}
                        sort={defaultSort}
                        enableFilters={false}
                    />
                )}
            </div>

            <ProgramModal
                show={showModal}
                onCancel={handleModalClose}
                action={action}
                data={currentRowData}
            />
        </div>
    );
}

export default withAuth(ArchivedPrograms);
