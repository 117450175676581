import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styles from './NavigationBox.module.scss';
import { setLocation } from '../../ducks/navigation';
import { NAVIGATION_BOX_TYPE } from "../../constants";
import Icon from './Icon';

function NavigationBox(props) {
    const {
        path,
        locationName,
        pageName,
        icon,
        children,
        classname,
        onModalOpen = null,
        type = NAVIGATION_BOX_TYPE.LINK
    } = props;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const navigationBoxStyle = `${styles.navigationBox} ${styles[classname]} ${type !== NAVIGATION_BOX_TYPE.LINK ? 'cursor-pointer' : ''} d-flex flex-column rounded-3 h-100`;

    const NavigationBoxInner = () => (
        <>
            <div className="d-flex flex-row">
                <Icon
                    icon={icon}
                    size={32}
                />
                <h4 className={`${styles.pageLinkText} mb-0 ms-3`}>{pageName}</h4>
            </div>
            <label className={`${styles.navigationDesc}`}>{children}</label>
        </>
    );

    return (
        <>
        {
            type === NAVIGATION_BOX_TYPE.LINK ?
                <NavLink
                    to={path}
                    className={navigationBoxStyle}
                    onClick={(e) => {
                        e.preventDefault();
                        dispatch(
                            setLocation({
                                path: path,
                                name: locationName,
                                page: pageName,
                            })
                        );
                        navigate(path);
                    }}>
                    <NavigationBoxInner />
                </NavLink>
                :
                <div onClick={onModalOpen}
                     className={navigationBoxStyle}>
                    <NavigationBoxInner />
                </div>
        }
        </>
    );
}

export default NavigationBox;
