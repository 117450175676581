import Placeholder from "../../components/ui/Placeholder";
import withAuth from "../../components/hoc/withAuth";

const MarketAssessmentHome = () => {
    return (
        <div className="flex-grow-1">
            <Placeholder
                title="The Market Assessment application will allow business analysts to explore the consumer behaviors, preferences, and trends of specific geographic markets."
                type="graphic"
            />
        </div>
    );
}

export default withAuth(MarketAssessmentHome);
