import withAuth from "../../../components/hoc/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import React, { useContext, useEffect } from "react";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { toDate } from "../../../utils/utils";
import { AUDIENCE_APPLICATION, AUDIENCE_SEGMENT, CMS_CONTENT_TYPE, CONTENT_RECORD_STATUS } from "../../../constants";
import OverflowText from "../../../components/ui/OverflowTooltip";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import ActionBar from "../../../components/ui/ActionBar";
import { getArchivedContent } from "../../../ducks/content";
import { Button } from "react-bootstrap";

const ArchivedContent = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const contentList = useSelector((state) => state.content.archivedContents);

    const columns = [
        {
            id: 'updatedDateTime',
            header: 'Date Updated',
            accessorKey: 'updatedDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.updatedDateTime)),
            cell: ({ row }) => `${toDate(row.original.updatedDateTime)}`,
            sortingFn: 'datetime',
            size: 150,
            enableColumnFilter: false,
        },
        {
            id: 'type',
            header: 'Content Type',
            accessorKey: 'type',
            accessorFn: (row) => `${CMS_CONTENT_TYPE[row.type]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {CMS_CONTENT_TYPE[row.original.type]}
                </OverflowText>
            ),
            minSize: 180,
            size: 180,
        },
        {
            id: 'title',
            header: 'Title',
            accessorKey: 'title',
            cell: ({ row, cell }) => (<OverflowText id={cell.id} >{row.original.title}</OverflowText>),
            minSize: 200,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
        {
            id: 'audienceSegment',
            header: 'Audience Segment',
            accessorKey: 'audienceSegment',
            accessorFn: (row) => `${AUDIENCE_SEGMENT[row.audienceSegment]}`,
            cell: ({ row }) => AUDIENCE_SEGMENT[row.original.audienceSegment],
            size: 155,
        },
        {
            id: 'audienceApplication',
            header: 'Audience Application',
            accessorKey: 'audienceApplication',
            accessorFn: (row) => `${AUDIENCE_APPLICATION[row.audienceApplication]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {AUDIENCE_APPLICATION[row.original.audienceApplication]}
                </OverflowText>
            ),
            size: 165,
        },
        {
            id: 'fileName',
            header: 'Content File',
            accessorKey: 'fileName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.fileName}
                </OverflowText>
            ),
            maxSize: 250,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
    ];

    const defaultSort = [
        {
            id: 'updatedDateTime',
            desc: true,
        },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getArchivedContent(CONTENT_RECORD_STATUS.Archived));
        }
    }, [isAppLoaded]);

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" variant="outline-primary"
                    onClick={() => navigate(`/admin/content-management`)}>
                    View Active
                </Button>
            </ActionBar>
            {
                contentList === null ?
                    <Loader />
                    :
                    <DataGrid
                        columns={columns}
                        data={contentList}
                        sort={defaultSort}
                    />
            }
        </div>
    )
}

export default withAuth(ArchivedContent);
