import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import withAuth from "../../../components/hoc/withAuth";
import ActionBar from "../../../components/ui/ActionBar";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { getCohortsByStatus } from "../../../ducks/cohort";
import { toDateTime } from "../../../utils/utils";
import { COHORT_FILE_STATUS } from "../../../constants";

const ArchivedCohorts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const cohorts = useSelector((state) => state.cohort.archiveCohorts);
    const currentUser = useSelector((state) => state.user.currentUser);

    const columns = [
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
            maxSize: 400,
            sortingFn: 'text'
        },
        {
            id: 'retentionRiskStatus',
            header: 'Retention Risk Status',
            accessorKey: 'retentionRiskStatus',
        },

        {
            id: 'dateTime',
            header: 'Retention Risk Status Date',
            accessorKey: 'retentionRiskStatusDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.retentionRiskStatusDateTime)),
            cell: ({ row }) => `${toDateTime(row.original.retentionRiskStatusDateTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            id: 'retentionRiskMonitoringStatus',
            header: 'Monitoring Status',
            accessorKey: 'retentionRiskMonitoringStatus',
        },
        {
            id: 'retentionRiskMonitoringStatusDateTime',
            header: 'Monitoring Date',
            accessorKey: 'retentionRiskMonitoringStatusDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.retentionRiskMonitoringStatusDateTime)),
            cell: ({ row }) => `${toDateTime(row.original.retentionRiskMonitoringStatusDateTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            id: 'status',
            header: 'Cohort status',
            accessorKey: 'status',
            cell: ({ row }) => (
                <div className="text-decoration-underline text-secondary cursor-pointer" onClick={() => {
                    navigate(`/admin/cohort-upload-overview/${row.original.id}`);
                }}>{row.original.status}</div>
            ),
        },
    ];

    const defaultSort = [{
        id: 'dateTime',
        desc: true
    }];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohortsByStatus(currentUser?.companyId, COHORT_FILE_STATUS.ARCHIVED));
        }
    }, [isAppLoaded, currentUser]);

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" variant="outline-primary"
                    onClick={() => navigate(-1)}>
                    View Active
                </Button>
            </ActionBar>
            <div className="flex-grow-1">
                {cohorts === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        columns={columns}
                        data={cohorts.filter((item) => item.training === 'N' && item.status === COHORT_FILE_STATUS.ENRICHED)}
                        sort={defaultSort}
                        enableFilters={false}
                    />
                )}
            </div>
        </div>
    );
}

export default withAuth(ArchivedCohorts);
