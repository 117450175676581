import React from 'react';
import styles from './RemoveCheckbox.module.scss';

const RemoveCheckbox = ({ id, checked, onRemove }) => {
    return (
        <div className={styles.customCheckbox}>
            <input
                type="checkbox"
                id={id}
                checked={checked}
                onChange={onRemove}
            />
            <label
                htmlFor={id}
                className={`${styles.cssLabel} position-relative`}>
            </label>
        </div>
    );
};

export default RemoveCheckbox;
