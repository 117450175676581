import Icon from "./Icon";
import GridButton from "./GridButton";
import styles from "./CommentItem.module.scss";

const CommentItem = ({ id, date, userName, onDelete, children }) => {
    const handleDelete = (id) => {
        if (onDelete && id) {
            onDelete(id)
        }
    }

    return (
        <li className={`${styles.commentList} d-flex align-items-start`}>
            <div className="flex-grow-1">
                {(userName || date) && (
                    <div
                        className={`${styles.commentHead} d-flex justify-content-between align-items-center`}>
                        <div className="flex-grow-1 fw-normal">
                            {userName && (
                                <span className="d-inline-block me-2 fw-bold">
                                    {userName}
                                </span>
                            )}
                            {date}
                        </div>
                    </div>
                )}
                <div className={`${styles.commentBody} lh-sm`}>{children}</div>
            </div>
            {onDelete && (
                <GridButton
                    className={`bg-transparent flex-shrink-0 border-0 ${styles.deleteButton}`}
                    variant="light"
                    size="sm"
                    title="Delete"
                    onClick={() => handleDelete(id)}>
                    <Icon
                        icon="delete"
                        size={16}
                    />
                </GridButton>
            )}
        </li>
    );
}

export default CommentItem;
