import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const eacParticipationResponsesApi = {
    GetEacParticipationResponses: (companyId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiEacParticipationResponses.endpoint}/EACParticipationResponses/${companyId}`,
            headers,
        })
    },
    CreateEACResponseParams: (data) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiEacParticipationResponses.endpoint}/EACParticipationResponses`,
            headers,
            data: data
        })
    },
};

export default eacParticipationResponsesApi;
