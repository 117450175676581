import { useContext, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import LoadingButton from "../../../../components/ui/LoadingButton";
import { AG_MEETING_STATUS_INVERSE, ALERT_TYPE } from "../../../../constants";
import { updateMeetingStatus } from "../../../../ducks/advisoryGroup";
import { AlertContext } from "../../../../context/AlertContext";
import messages from "../../../../utils/helper/messages";

const DocumentationModal = ({ show, meetingId, onCancel, onUpdate, currentUserId }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const [loading, setLoading] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setLoading(true);

        const submitData = {
            meetingId: meetingId,
            status: AG_MEETING_STATUS_INVERSE.Documented,
            lastModifiedUser: currentUserId,
            cancellationReason: null
        }

        dispatch(updateMeetingStatus(submitData, () => {
            setLoading(false);
            showAlert(messages.success.updateMeetingStatusDocumented, ALERT_TYPE.SUCCESS);
            onUpdate();
            onCancel();
        }));
    }

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Documentation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="text-end mb-4">
                        <Button variant="outline-primary" type="button" onClick={onCancel}>Cancel</Button>
                    </div>
                    <p className="text-center">Confirm that meeting documentation has been completed.</p>
                    <div className="text-center my-4">
                        <LoadingButton type="submit" loading={loading} disabled={false}>Confirm</LoadingButton>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    )
}

export default DocumentationModal;
