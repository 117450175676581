import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import {
    AG_MEETING_STATUS_INVERSE,
    ALERT_TYPE,
    ALIGN,
    MEETING_STATUS,
    POSITIONS,
    PROGRAM_MEETING_TYPE, TIMEZONES,
    USER_STATUS_VALUE
} from '../../../../constants';
import {
    combineDateTimeTimezone,
    isAfterToday,
    isBeforeToday,
    isObjectEmpty,
    omitProps,
    optionValues,
} from '../../../../utils/utils';
import { AppLoadContext } from "../../../../components/ui/AppLoadContext";
import { AlertContext } from "../../../../context/AlertContext";
import { getAdvisoryGroupData, updateAdvisoryGroupMeetingDetails } from "../../../../ducks/advisoryGroup";
import messages from "../../../../utils/helper/messages";
import { getCompanyUsersById } from "../../../../ducks/company";

const EditMeetingModal = ({ show, onCancel, onUpdate, data }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const statusOptions = optionValues(MEETING_STATUS);
    const futureStatusOptions = optionValues(omitProps(MEETING_STATUS, AG_MEETING_STATUS_INVERSE.Completed));
    const meetingTypeOptions = optionValues(PROGRAM_MEETING_TYPE);
    const currentUser = useSelector((state) => state.user.currentUser);
    const agData = useSelector((state) => state.advisoryGroup.masterList);
    const companyUsers = useSelector((state) => state.company.users);

    const [isLoading, setIsLoading] = useState(false);
    const [agOptions, setAgOptions] = useState(null);
    const [ownerOptions, setOwnerOptions] = useState(null);
    const [meetingDetails, setMeetingDetails] = useState(null);
    const [isPastMeeting, setIsPastMeeting] = useState(false);
    const [isFutureMeeting, setIsFutureMeeting] = useState(false);

    const arr = [
        {
            key: 'aGId',
            labelName: 'AG Group',
            placeholderName: 'AG Group',
            type: 'options',
            optionValues: agOptions,
            disabled: isPastMeeting,
        },
        {
            key: 'title',
            labelName: 'Meeting Title',
            placeholderName: 'Meeting Title',
            type: 'text',
            maxLength: 15,
            disabled: isPastMeeting,
        },
        {
            key: 'status',
            labelName: 'Meeting Status',
            placeholderName: 'Select Meeting Status',
            type: 'options',
            optionValues: isFutureMeeting ? futureStatusOptions : statusOptions,
            disabled: isPastMeeting && data.status === 'C' // Completed
        },
        {
            key: 'meetingDate',
            labelName: 'Meeting Date',
            placeholderName: 'Meeting Date',
            type: 'date',
            disabled: isPastMeeting,
        },
        {
            key: 'meetingTime',
            labelName: 'Meeting Time',
            placeholderName: 'Meeting Time',
            type: 'time',
            disabled: isPastMeeting,
            cols: 6,
        },
        {
            key: 'meetingTimeZone',
            labelName: 'Meeting Time Zone',
            placeholderName: 'Meeting Time Zone',
            type: 'options',
            optionValues: optionValues(TIMEZONES),
            disabled: isPastMeeting,
            cols: 6,
        },
        {
            key: 'type',
            labelName: 'Meeting Type',
            placeholderName: 'Select Meeting Type',
            type: 'options',
            optionValues: meetingTypeOptions,
            disabled: isPastMeeting,
        },
        {
            key: 'ownerId',
            labelName: 'Meeting Owner',
            placeholderName: 'Meeting Owner',
            type: 'options',
            optionValues: ownerOptions,
            disabled: isPastMeeting,
        }
    ];

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        const { id, aGId, title, meetingDate, meetingTimeZone, type, ownerId } = data;

        const submitData = {
            meetingId: id,
            status: formData.status,
            aGId: isPastMeeting ? aGId : formData.aGId,
            title: isPastMeeting ? title : formData.title,
            meetingDate: isPastMeeting ? meetingDate : combineDateTimeTimezone(formData.meetingDate, formData.meetingTime, formData.meetingTimeZone),
            meetingTimeZone: isPastMeeting ? meetingTimeZone : formData.meetingTimeZone,
            type: isPastMeeting ? type : formData.type,
            owner: isPastMeeting ? ownerId : formData.ownerId,
            lastModifiedUser: currentUser?.id
        }

        dispatch(updateAdvisoryGroupMeetingDetails(submitData, () => {
            setIsLoading(false);
            showAlert(messages.success.updateMeeetingDetails, ALERT_TYPE.SUCCESS);
            onUpdate();
            onCancel();
        }));
    }

    const handleMeetingDetailsChange = (update) => {
        setMeetingDetails((details) => ({ ...details, ...update }));
    }

    const handleInputChange = (name, value) => {
        handleMeetingDetailsChange({ [name]: value });
    }

    const isValidMeetingInfo = () => {
        return !isObjectEmpty(meetingDetails);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
            dispatch(getCompanyUsersById(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (agData && agData.length > 0) {
            setAgOptions(agData.map(ag => ({ id: ag.id, value: ag.shortName })))
        }
    }, [agData]);

    useEffect(() => {
        if (companyUsers && companyUsers.length > 0) {
            const users = companyUsers.filter(item => item.recordStatus !== USER_STATUS_VALUE.Archived);
            setOwnerOptions(users.map(user => ({ id: user.id, value: user.name })))
        }
    }, [companyUsers]);

    useEffect(() => {
        if (data !== null) {
            setMeetingDetails(data);
            if (isBeforeToday(data.meetingDate)) {
                setIsPastMeeting(true);
            }
            if (isAfterToday(data.meetingDate)) {
                setIsFutureMeeting(true);
            }
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Edit Meeting</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        loading={isLoading}
                        disabled={!isValidMeetingInfo()}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default EditMeetingModal;
