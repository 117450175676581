import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col } from 'react-bootstrap';
import withAuth from '../../../components/hoc/withAuth';
import DataGrid from "../../../components/DataGrid";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import OverflowText from "../../../components/ui/OverflowTooltip";
import Icon from "../../../components/ui/Icon";
import GridButton from "../../../components/ui/GridButton";
import { AG_STATUS, US_STATE } from "../../../constants";
import { getAdvisoryGroupRecruitmentData, getViewMemberPagesData } from "../../../ducks/advisoryGroup";
import Loader from "../../../components/ui/Loader";
import NavigationBoxDashboard from '../../../components/ui/NavigationBoxDashboard';
import ViewMemberPagesModal from "./ViewMemberPagesModal";

const Recruitment = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const recruitmentData = useSelector((state) => state.advisoryGroup.recruitmentList);
    const currentUser = useSelector((state) => state.user.currentUser);

    const [showModal, setShowModal] = useState(false);

    const columns = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text',
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            accessorFn: (row) => (US_STATE[row.market] ?? ''),
        },
        {
            id: 'targetSize',
            header: 'Target Size',
            accessorKey: 'targetSize',
        },
        {
            id: 'activeMemberCount',
            header: '# of Active Members',
            accessorKey: 'activeMemberCount',
        },
        {
            id: 'appCount',
            header: '# of Active Applications',
            accessorKey: 'appCount',
        },
        {
            id: 'openInvitationCount',
            header: '# of Open Invitations',
            accessorKey: 'openInvitationCount',
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 80,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                                onClick={() =>  navigate(`/advisory-group-management/recruitment/application-detail`, { state: { agId: row.original.id } }) }>
                        <Icon icon="view" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Outreach"
                                onClick={() => navigate('/advisory-group-management/recruitment/recruiting-outreach') }>
                        <Icon icon="outreach" size={14} />
                    </GridButton>
                    {row.original.status === AG_STATUS.New && (
                        <GridButton variant="light" size="sm" className="me-1 my-1" title="Select"
                                onClick={() => navigate(`/advisory-group-management/recruitment/panel-selection`, { state: { agId: row.original.id } })}>
                            <Icon icon="recruit" size={14} />
                        </GridButton>
                    )}
                    {row.original.status === AG_STATUS.Active && (
                        <GridButton variant="light" size="sm" className="me-1 my-1" title="Maintain"
                                onClick={() => navigate(`/advisory-group-management/recruitment/panel-maintenance`, { state: { agId: row.original.id } })}>
                            <Icon icon="analyze" size={14} />
                        </GridButton>
                    )}
                </>
            ),
        },
    ];

    const handleMemberModalOpen = () => {
        setShowModal(true);
        dispatch(getViewMemberPagesData(currentUser?.companyId));
    }

    const handleMemberModalClose = () => {
        setShowModal(false);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupRecruitmentData(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    const NavContent = [
        {
            path: '/advisory-group-management/recruitment/recruiting-analytics',
            locationName: 'Recruiting Analytics',
            pageName: 'Recruiting Analytics',
            icon: 'recruiting-analytics',
            classname: 'purple',
            description: "Access standard reports and graphs that provide insight into the performance of the recruiting campaigns"
        },
        {
            type: 'modal',
            onModalOpen: handleMemberModalOpen,
            path: '/advisory-group-management/recruitment/view-member-pages',
            locationName: 'View Member Pages',
            pageName: 'View Member Pages',
            icon: 'view-member-pages',
            classname: 'yellow',
            description: 'View consumer facing web presence where members may apply to participate in an advisory group or access advisory group materials',
        }
    ];

    return (
        <>
            <Row>
                <Col className='px-4'>
                    <NavigationBoxDashboard data={NavContent} cols={2} />
                    <div className="d-flex flex-column mt-4">
                        <div className="flex-grow-1">
                            {recruitmentData === null ? (
                                <Loader />
                            ) : (
                                <DataGrid
                                    columns={columns}
                                    data={recruitmentData}
                                    enableFilters={false}
                                />
                            )}
                        </div>
                    </div>
                </Col>
            </Row>
            {showModal && (
                <ViewMemberPagesModal
                    show={showModal}
                    onCancel={handleMemberModalClose}
                />
            )}
        </>
    );
};

export default withAuth(Recruitment);
