import React, { useState, useEffect, useMemo, useRef } from "react";
import { Table, Form, Button } from 'react-bootstrap';
import {
    getPaginationRowModel,
    getSortedRowModel,
    flexRender,
    getCoreRowModel,
    getExpandedRowModel,
    getGroupedRowModel,
    getFacetedRowModel,
    useReactTable,
    getFilteredRowModel,
    getFacetedUniqueValues,
} from '@tanstack/react-table';
import { FaSortUp, FaSortDown, FaFilter } from "react-icons/fa";
import styles from './DataGrid.module.scss';
import { rankItem } from '@tanstack/match-sorter-utils';
import Icon from "./ui/Icon";
import {
    MdKeyboardArrowLeft,
    MdKeyboardArrowRight,
    MdKeyboardDoubleArrowLeft,
    MdKeyboardDoubleArrowRight
} from "react-icons/md";
import { isDateObject, toDateTime } from "../utils/utils";

const FilterItem = ({ filter, columnFilter, onSelect }) => {
    const [active, setActive] = useState(false);

    const checkIsActive = () => {
        if (isDateObject(columnFilter)) {
            let filterDate = new Date(filter)
            return filterDate.getTime() === columnFilter.getTime()
        }
        return filter === columnFilter
    }

    useEffect(() => {
        let filterActive = checkIsActive();
        setActive(filterActive);
    }, [columnFilter]);

    return (
        <li
            onClick={() => onSelect(filter)}
            className={active ? 'active' : ''}
        >
            {filter}
        </li>
    )
}

const RowHeader = ({ table, header, gridRef }) => {
    const [width, setWidth] = useState(0);
    const [leftPosition, setLeftPosition] = useState(0);
    const [topPosition, setTopPosition] = useState(0);
    const thRef = useRef(null);
    const divRef = useRef(null);

    const [isFilterSelected, setFilterSelected] = useState(false);
    const [isDivVisible, setDivVisibility] = useState(false);

    const firstValue = table.getPreFilteredRowModel().flatRows[0]?.getValue(header.column.id);

    const handleItemClick = (item) => {
        if (item === 'None') {
            header.column.setFilterValue('');
            setFilterSelected(false);
        }
        else {
            setWidth(thRef?.current.clientWidth + 1);
            if (isDateObject(firstValue)) {
                let dateObject = new Date(item);
                header.column.setFilterValue(dateObject);
            } else {
                header.column.setFilterValue(item);
            }
            setFilterSelected(true);
        }

        setDivVisibility(false);
    };

    const getFilterPosition = () => thRef?.current.offsetLeft - gridRef?.current.parentNode.scrollLeft;
    const getFilterTopPosition = () => thRef?.current.offsetTop + thRef?.current.clientHeight;

    const handleIconClick = (event) => {
        event.stopPropagation();
        setWidth(thRef?.current.clientWidth + 1);
        setDivVisibility(!isDivVisible);
        setLeftPosition(getFilterPosition());
        setTopPosition(getFilterTopPosition());
    };

    const sortedUniqueValues = useMemo(
        () => {
            let keysArr = header.column.getFacetedUniqueValues().keys();
            if (isDateObject(firstValue)) {
                keysArr = [...keysArr].map(key => {
                    return toDateTime(key)
                })
            }
            return Array.from(keysArr);
        },
        [header.column.getFacetedUniqueValues()]
    )
    const sortedUniqueValuesWithNull = ['None', ...sortedUniqueValues];

    useEffect(() => {
        const handleClickOutside = (event) => {
            const icon = document.querySelector(`[data-filter='${header.id}']`);
            if (divRef.current && !divRef.current.contains(event.target) && !icon.contains(event.target)) {
                setDivVisibility(false);
            }
        };
        document.querySelectorAll('table').forEach(table => table.addEventListener('click', handleClickOutside));
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        let scrollContainer = gridRef?.current.parentNode;
        setWidth(thRef?.current.clientWidth + 1);
        setLeftPosition(getFilterPosition());
        setTopPosition(getFilterTopPosition());

        function handleResize() {
            setWidth(thRef?.current.clientWidth + 1);
            setLeftPosition(getFilterPosition());
            setTopPosition(getFilterTopPosition());
        }

        const handleScroll = () => {
            setLeftPosition(getFilterPosition());
            setTopPosition(getFilterTopPosition());
        }

        window.addEventListener('resize', handleResize)
        scrollContainer.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('resize', handleResize);
            scrollContainer.removeEventListener('scroll', handleScroll);
        }
    }, []);

    return (
        <th
            key={header.id}
            ref={thRef}
            className={header.isPlaceholder ? 'isPlaceholder' : ''}
            colSpan={header.colSpan}
            style={{
                width: header.column.columnDef.size ?? undefined,
                maxWidth: header.column.columnDef.maxSize ?? undefined,
                minWidth: header.column.columnDef.minSize ?? undefined,
            }}>
            {header.isPlaceholder ? null : (
                <>
                    <div
                        className="d-flex flex-nowrap"
                        data-id={header.id}>
                        <div
                            {...{
                                className: header.column.getCanSort()
                                    ? 'cursor-pointer select-none flex-grow-1'
                                    : (header.subHeaders?.length > 0 ? 'flex-grow-1 text-center' : 'flex-grow-1'),
                                onClick:
                                    header.column.getToggleSortingHandler(),
                            }}>
                            {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                            )}
                            {header.column.getCanSort() ?
                                ({
                                    asc: (<FaSortUp className={`position-relative ${styles.sortUp}`} />),
                                    desc: (<FaSortDown className={`position-relative ${styles.sortDown}`} />),
                                }[header.column.getIsSorted().toString()] ?? (<span className={`d-inline-block ${styles.noSort}`}></span>))
                                : <></> // Remove empty span from columns that don't have sorting
                            }
                        </div>
                        {header.column.getCanFilter() ? (
                            <div
                                className={`${styles.filterParent} flex-shrink-0 cursor-pointer px-2 me-n2`}
                                onClick={handleIconClick}>
                                {!isFilterSelected ? (
                                    <Icon
                                        data-filter={header.id}
                                        icon="filter"
                                        size={16}
                                    />
                                ) : (
                                    <FaFilter
                                        data-filter={header.id}
                                        className={styles.filterSelected}
                                    />
                                )}
                            </div>
                        ) : null}
                    </div>
                    {isDivVisible && (
                        <div
                            ref={divRef}
                            className={`${styles.filterItemsParent} flex-shrink-0`}
                            style={{ width: width, left: leftPosition, top: topPosition }}>
                            <ul className={styles.dropdownContent}>
                                {sortedUniqueValuesWithNull.map(
                                    (filter) => (
                                        <FilterItem
                                            key={filter}
                                            columnFilter={header.column.getFilterValue()}
                                            filter={filter}
                                            onSelect={() =>
                                                handleItemClick(filter)
                                            }
                                        />
                                    )
                                )}
                            </ul>
                        </div>
                    )}
                </>
            )}
        </th>
    );
};

const DataGrid = (
    {
        data,
        columns,
        enableFilters,
        rowClick,
        sort,
        groupBy,
        columnDisplay,
        onExportDataChange,
        gridContainerClass = '',
        pagination = true
    }
) => {

    const [grouping, setGrouping] = useState(groupBy || []);
    const [sorting, setSorting] = useState(sort || []);
    const [columnVisibility, setColumnVisibility] = useState(columnDisplay || {});
    const pageSizes = [10, 20, 30, 40, 50];

    const [columnFilters, setColumnFilters] = useState([])
    const [globalFilter, setGlobalFilter] = useState('');
    const [resetTable, setResetTable] = useState(false);
    const gridRef = useRef(null);

    const fuzzyFilter = (row, columnId, value, addMeta) => {
        const itemRank = rankItem(row.getValue(columnId), value)
        addMeta({
            itemRank
        })
        return itemRank.passed
    }

    const table = useReactTable({
        data,
        columns,
        autoResetExpanded: false,
        defaultColumn: {
            size: undefined,
            minSize: 60,
            maxSize: 300,
        },
        filterFns: {
            fuzzy: fuzzyFilter
        },
        state: {
            expanded: true,
            grouping,
            sorting,
            columnVisibility,
            columnFilters,
            globalFilter,
        },
        onColumnFiltersChange: setColumnFilters,
        onGroupingChange: setGrouping,
        getSubRows: row => row.subRows,
        onSortingChange: setSorting,
        enableSortingRemoval: false,
        onColumnVisibilityChange: setColumnVisibility,
        getSortedRowModel: getSortedRowModel(),
        getCoreRowModel: getCoreRowModel(),
        getExpandedRowModel: getExpandedRowModel(),
        getGroupedRowModel: getGroupedRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getFilteredRowModel: getFilteredRowModel(),
        globalFilterFn: fuzzyFilter,
        onGlobalFilterChange: setGlobalFilter,
        getFacetedRowModel: getFacetedRowModel(),
        getFacetedUniqueValues: getFacetedUniqueValues(),
        enableColumnFilters: enableFilters,
        manualPagination: !pagination,
    });

    // Get the row count
    const rowCount = table.getCoreRowModel().rows.length;

    useEffect(() => {
        if (table.getState().columnFilters[0]?.id === "fullName") {
            if (table.getState().sorting[0]?.id !== "fullName") {
                table.setSorting([{ id: "fullName", desc: false }])
            }
        }
    }, [table.getState().columnFilters[0]?.id]);

    useEffect(() => {
        if (onExportDataChange) {
            const sortedRows = table.getSortedRowModel().rows.map((row) => row.original);
            onExportDataChange(sortedRows);
        }
    }, [sorting]);

    useEffect(() => {
        if (onExportDataChange) {
            const filteredRows = table.getFilteredRowModel().rows.map((row) => row.original);
            onExportDataChange(filteredRows);
        }
    }, [columnFilters]);

    return (
        <>
            <div
                className={`${styles.dataGridContainer
                    } ${gridContainerClass} rounded bg-white position-relative ${enableFilters !== false ? styles.hasFilters : ''
                    }`}>
                <Table
                    bordered
                    striped
                    hover
                    responsive
                    className={styles.dataGrid}
                    ref={gridRef}>
                    <thead>
                        {table.getHeaderGroups().map((headerGroup) => (
                            <tr
                                className={`${styles.tableHeader} ${(table.getHeaderGroups().length - 1) === headerGroup.depth ? 'flatHeader' : 'hasSubHeaders'}`}
                                key={headerGroup.id}>
                                {headerGroup.headers.map((header) => (
                                    <RowHeader
                                        key={header.id}
                                        table={table}
                                        header={header}
                                        gridRef={gridRef}
                                    />
                                ))}
                            </tr>
                        ))}
                    </thead>

                    <tbody>
                        {table.getRowModel().rows.length === 0 && (
                            <tr>
                                <td
                                    className="text-center text-secondary py-2"
                                    colSpan={
                                        table.getHeaderGroups()[
                                            table.getHeaderGroups().length - 1
                                        ].headers.length
                                    }>
                                    No data
                                </td>
                            </tr>
                        )}
                        {table.getRowModel().rows.map((row) => (
                            <tr
                                key={row.id}
                                className={row.subRows?.length > 0 ? 'hasSubRows' : 'isFlatRow'}
                                style={{
                                    cursor: rowClick ? 'pointer' : 'auto',
                                }}>
                                {row.getVisibleCells().map((cell) => (
                                    <td
                                        key={cell.id}
                                        onClick={() => {
                                            if (rowClick) {
                                                rowClick(row.original);
                                            }
                                        }}
                                        style={{
                                            maxWidth:
                                                cell.column.columnDef.maxSize,
                                        }}
                                        data-id={cell.id}>
                                        {cell.getIsGrouped() ? (
                                            flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )
                                            /*({row.subRows.length}) // To show rows length */
                                        ) : cell.getIsAggregated() ? (
                                            // If the cell is aggregated, use the Aggregated
                                            // renderer for cell
                                            flexRender(
                                                cell.column.columnDef
                                                    .aggregatedCell ??
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )
                                        ) : cell.getIsPlaceholder() ? null : (
                                            // For cells with repeated values, render null
                                            // Otherwise, just render the regular cell
                                            flexRender(
                                                cell.column.columnDef.cell,
                                                cell.getContext()
                                            )
                                        )}
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
            {pagination && rowCount > pageSizes[0] ? (
                <div
                    className={`d-flex flex-wrap justify-content-between mt-3 ${styles.pagination}`}>
                    <div className="d-flex align-items-center pt-1">
                        <Form.Select
                            value={table.getState().pagination.pageSize}
                            onChange={(e) => {
                                table.setPageSize(Number(e.target.value));
                            }}
                            size="sm"
                            className={`w-auto ${styles.paginationSelect}`}>
                            <>
                                {pageSizes.map((pageSize) => (
                                    <option
                                        key={pageSize}
                                        value={pageSize}>
                                        Show {pageSize}
                                    </option>
                                ))}
                            </>
                        </Form.Select>
                        <span className="text-nowrap ms-2">per page</span>
                    </div>
                    {table.getPageCount() > 1 && (
                        <div className="d-flex align-items-center pt-1">
                            {table.getPageCount() > 2 && (
                                <Button
                                    onClick={() => table.setPageIndex(0)}
                                    disabled={!table.getCanPreviousPage()}
                                    variant="link"
                                    size="sm"
                                    className="d-flex align-items-center ps-1">
                                    <MdKeyboardDoubleArrowLeft />
                                    <span className={`${styles.pageNavLabel} ms-1`}>
                                        First
                                    </span>
                                </Button>
                            )}
                            <Button
                                onClick={() => table.previousPage()}
                                disabled={!table.getCanPreviousPage()}
                                variant="link"
                                size="sm"
                                className="d-flex align-items-center ps-1">
                                <MdKeyboardArrowLeft />
                                <span className={`${styles.pageNavLabel} ms-1`}>
                                    Previous
                                </span>
                            </Button>
                            <span className="d-flex align-items-center mx-2">
                                <span>Page</span>
                                <Form.Control
                                    type="number"
                                    value={
                                        resetTable === true
                                            ? ''
                                            : table.getState().pagination
                                                  .pageIndex + 1
                                    }
                                    onChange={(e) => {
                                        const page = e.target.value;
                                        if (
                                            page === '' ||
                                            page > table.getPageCount()
                                        ) {
                                            setResetTable(true);
                                        } else {
                                            setResetTable(false);
                                            table.setPageIndex(Number(page) - 1);
                                        }
                                    }}
                                    size="sm"
                                    className={`mx-2 ${styles.paginationInput}`}
                                />
                                <span className="text-nowrap">
                                    of {table.getPageCount()}
                                </span>
                            </span>
                            <Button
                                onClick={() => table.nextPage()}
                                disabled={!table.getCanNextPage()}
                                variant="link"
                                size="sm"
                                className="d-flex align-items-center pe-1">
                                <span className={`${styles.pageNavLabel} me-1`}>
                                    Next
                                </span>
                                <MdKeyboardArrowRight />
                            </Button>
                            {table.getPageCount() > 2 && (
                                <Button
                                    onClick={() =>
                                        table.setPageIndex(table.getPageCount() - 1)
                                    }
                                    disabled={!table.getCanNextPage()}
                                    variant="link"
                                    size="sm"
                                    className="d-flex align-items-center pe-1">
                                    <span className={`${styles.pageNavLabel} me-1`}>
                                        Last
                                    </span>
                                    <MdKeyboardDoubleArrowRight />
                                </Button>
                            )}
                        </div>
                    )}
                </div>
            ) : null}
        </>
    );
}

export default DataGrid;
