const ActionBar = ({ title, back = false, titleClass= '', children }) => {
    const wrapperStyles = (title || back) ? 'justify-content-between' : 'justify-content-end';

    return (
        <div className={`d-flex align-items-center flex-shrink-0 mb-2 py-1 ${wrapperStyles}`}>
            {title && <h1 className={`action-title mb-0 ${titleClass}`}>{title}</h1>}
            {children}
        </div>
    );
};

export default ActionBar;
