import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import React from "react";

const OverflowText = ({children, label, tooltipStyle = '', placement = 'top'}) => {
    return (
        <OverlayTrigger
            placement={placement}
            overlay={<Tooltip style={{position: "fixed"}} className={tooltipStyle}>{label ? label : children}</Tooltip>}
        >
            <div className="text-truncate mw-100">{children}</div>
        </OverlayTrigger>
    )
}

export default OverflowText;
