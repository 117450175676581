import React, { useContext, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Alert, Modal } from "react-bootstrap";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import {
    ALIGN,
    COHORT_TRAINING_FLAG,
    MIME_TYPES,
    POSITIONS,
    COHORT_MARKET_TYPE,
    US_STATE,
    ALERT_TYPE
} from "../../constants";
import SharedForm from "../../components/ui/Form";
import FileProgress from "../../components/ui/FileProgress";
import { isObjectEmpty, isValidName, optionValues } from "../../utils/utils";
import api from "../../services/api/memberAnalyticsService";
import messages from "../../utils/helper/messages";
import { AlertContext } from "../../context/AlertContext";

const NewCohortModal = ({ show, onCancel, onUpload }) => {
    const initialCohortState = { training: 'N', marketType: COHORT_MARKET_TYPE.Single, market: US_STATE.AL };
    const currentUser = useSelector((state) => state.user.currentUser);
    const { showAlert } = useContext(AlertContext);

    const allowedFileTypes = [MIME_TYPES.CSV];
    const trainingFlagOptions = optionValues(COHORT_TRAINING_FLAG);
    const marketTypeOptions = optionValues(COHORT_MARKET_TYPE);
    const marketOptions = optionValues(US_STATE);
    const [isLoading, setIsLoading] = useState(false);
    const [cohortDetails, setCohortDetails] = useState(initialCohortState);
    const [tempMarket, setTempMarket] = useState(initialCohortState);

    const [file, setFile] = useState("");
    const [progress, setProgress] = useState(0);
    const progressRef = useRef(null);

    const isMarketTypeMultiple = COHORT_MARKET_TYPE[cohortDetails.marketType] === COHORT_MARKET_TYPE.Multiple;


    const arr = [
        { key: 'name', labelName: 'Cohort Name', placeholderName: 'Cohort Name', type: 'text' },
        { key: 'training', labelName: 'Training Cohort', placeholderName: 'Training Cohort', type: 'options', optionValues: trainingFlagOptions },
        { key: 'marketType', labelName: 'Market Type', placeholderName: 'Market Type', type: 'options', optionValues: marketTypeOptions },
        {
            key: 'market', labelName: 'Market', placeholderName: 'Market',
            type: `${isMarketTypeMultiple ? 'text' : 'options'}`, optionValues: marketOptions,
            disabled: isMarketTypeMultiple, maxLength: 2
        },
        {
            key: 'file', labelName: 'Content', placeholderName: 'Upload File', type: 'file',
            allowedTypes: allowedFileTypes, fileNameKey: 'fileName'
        }
    ]

    const Submit = async () => {
        setIsLoading(true);

        const file = cohortDetails.file;

        let cohortName = cohortDetails.name;
        let fileObj = new File([file], `${currentUser.companyId}_${cohortName}_${file.name}`, { type: file.type });

        // set properties to assign to blob metadata
        fileObj.companyId = currentUser.companyId;
        fileObj.uploaderUserId = currentUser.id;
        fileObj.cohortName = cohortName;
        fileObj.originalFileName = file.name;
        fileObj.training = cohortDetails.training;
        fileObj.marketType = COHORT_MARKET_TYPE[cohortDetails.marketType];
        fileObj.market = cohortDetails.market;

        try {
            await api.uploadFile(fileObj, (event) => {
                progressRef.current = Math.round(
                    (100 * event.loaded) / event.total
                );
                setProgress(progressRef.current);
                if (progressRef.current === 100) {
                    setCohortDetails(initialCohortState);
                    setProgress(0);
                }
            }).then(() => {
                showAlert(`${fileObj.name} ${messages.success.cohortUpload}`, ALERT_TYPE.SUCCESS);
                setIsLoading(false);
                onUpload();
            });
        } catch (error) {
            setIsLoading(false);
            setProgress(0);
            showAlert(`${messages.error.cohortUpload} ${fileObj.name} ${error}.`, ALERT_TYPE.ERROR);
        }
    }

    const isValidCohortInfo = () => {
        if (isObjectEmpty(cohortDetails)) {
            return false;
        }

        return isValidName(cohortDetails.name) && (cohortDetails.fileName || cohortDetails.file)
    }

    const handleCohortDetailsChange = (update) => {
        setCohortDetails((details) => ({ ...details, ...update }))
    }

    const onHideCallback = () => {
        setCohortDetails(initialCohortState);
        setFile("");
        onCancel();
    }

    const inputChange = (name, value) => {
        if (name === "file") {
            handleCohortDetailsChange({ fileName: value.name, file: value });
            setFile(value);
        } else if (name === "marketType") {
            handleCohortDetailsChange({ [name]: value });
            if (COHORT_MARKET_TYPE[value] === COHORT_MARKET_TYPE.Multiple) {
                setTempMarket(cohortDetails.market);
                handleCohortDetailsChange({ market: 'ZZ' });
            } else {
                handleCohortDetailsChange({ market: tempMarket });
            }
        } else {
            handleCohortDetailsChange({ [name]: value });
        }
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={onHideCallback} size="md">
            <Modal.Header closeButton>
                <Modal.Title>New Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={cohortDetails}
                        onSubmit={Submit}
                        onCancel={onHideCallback}
                        onInputChanged={inputChange}
                        loading={isLoading}
                        disabled={!isValidCohortInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                    />
                    {progress > 0 && (
                        <FileProgress
                            file={file}
                            progress={progress}
                            wrapperStyle="mt-2"
                        />
                    )}
                </div>
                {
                    !isValidName(cohortDetails.name) &&
                    <div className="px-xl-3">
                        <Alert
                            variant="warning"
                            className="mt-2 py-2 lh-sm"
                        >
                            <div className="d-flex align-items-center">
                                <BsFillExclamationTriangleFill size={14} className="me-2 flex-shrink-0" />
                                {messages.error.cohortNameValidation}
                            </div>
                        </Alert>
                    </div>
                }
            </Modal.Body>
        </Modal>
    )
}

export default NewCohortModal;
