import DataGrid from "../../components/DataGrid";
import Icon from "../../components/ui/Icon";
import GridButton from "../../components/ui/GridButton";
import React from "react";

const GroupedReactTable = () => {

    const data = [
        {
            meetingDate: '01/29/2024',
            advisoryGroupName: 'AG 1',
            location: 'Biloxi',
            confirmed: '10',
            noResponse: '2',
            declined: '1',
            attended: '8',
            cancelled: '1',
            noShow: '1',
        },
        {
            meetingDate: '01/29/2024',
            advisoryGroupName: 'AG 1',
            location: 'Hattliesburg',
            confirmed: '6',
            noResponse: '0',
            declined: '1',
            attended: '4',
            cancelled: '0',
            noShow: '2',
        },
        {
            meetingDate: '01/30/2024',
            advisoryGroupName: 'AG 2',
            location: 'Biloxi',
            confirmed: '10',
            noResponse: '2',
            declined: '1',
            attended: '8',
            cancelled: '1',
            noShow: '1',
        },
        {
            meetingDate: '01/30/2024',
            advisoryGroupName: 'AG 2',
            location: 'Hattliesburg',
            confirmed: '6',
            noResponse: '0',
            declined: '1',
            attended: '4',
            cancelled: '0',
            noShow: '2',
        }
    ];

    const columns = [
        {
            header: 'Meeting Date',
            accessorKey: 'meetingDate',
        },
        {
            header: 'AG name',
            accessorKey: 'advisoryGroupName',
            cell: () => '',
            aggregatedCell: ({ row }) => row.original.advisoryGroupName
        },
        {
            header: 'Location',
            accessorKey: 'location',
        },
        {
            header: 'Pre-Meeting Response',
            columns: [
                {
                    header: 'Confirmed',
                    accessorKey: 'confirmed',
                },
                {
                    header: 'No Response',
                    accessorKey: 'noResponse',
                },
                {
                    header: 'Declined',
                    accessorKey: 'declined',
                },
            ],
        },
        {
            header: 'Meeting Participation',
            columns: [
                {
                    header: 'Attended',
                    accessorKey: 'attended',
                },
                {
                    header: 'Cancelled',
                    accessorKey: 'cancelled',
                },
                {
                    header: 'No Show',
                    accessorKey: 'noShow',
                },
            ],
        },
        {
            header: 'Actions',
            aggregatedCell: () => (
                <GridButton
                    variant="light"
                    size="sm"
                    className="me-1 my-1"
                    title="View">
                    <Icon
                        icon="view"
                        size={14}
                    />
                </GridButton>
            ),
        },
    ];

    return (
        <DataGrid
            columns={columns}
            data={data}
            enableFilters={false}
            groupBy={['meetingDate']}
        />
    );
}

export default GroupedReactTable;
