import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Row, Col } from 'react-bootstrap';
import withAuth from '../../../../components/hoc/withAuth';
import ActionBar from '../../../../components/ui/ActionBar';
import AgSelector from '../../../../components/ui/Selector';
import Icon from '../../../../components/ui/Icon';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import api from "../../../../services/api/advisoryGroupService";
import { useLocation, useNavigate } from 'react-router-dom';
import PanelMaintenanceScore from "../panel-selection/PanelScore";
import OverflowText from "../../../../components/ui/OverflowTooltip";
import DataGrid from "../../../../components/DataGrid";
import Loader from "../../../../components/ui/Loader";
import ConfirmModal from "../../../../components/ui/ConfirmModal";
import CollapsePanel from "../../../../components/ui/CollapsePanel";
import { AlertContext } from "../../../../context/AlertContext";
import RemoveCheckbox from "../../../../components/ui/RemoveCheckbox";
import gridStyles from './../../../../components/DataGrid.module.scss';
import messages from "../../../../utils/helper/messages";
import { toDate, sortingFnDateOnly, toDecimal } from "../../../../utils/utils";
import { AG_STATUS, AG_RECRUITMENT_MEMBER_STATUS, ALERT_TYPE, AG_MEMBER_STATUS, REVISED_SCORE_TYPE } from '../../../../constants';
import {
    getAdvisoryGroupData, getAdvisoryGroupPotentialMembers,
    getAdvisoryGroupSelectedMembers, resetPanelSelection, updateEnrolledMemberData, selectAgMembers
} from '../../../../ducks/advisoryGroup';

const PanelMaintenance = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);
    const advisoryGroups = useSelector((state) => state.advisoryGroup.masterList);

    const potentialMembers = useSelector((state) => state.advisoryGroup?.panelPotentialMembers);
    const enrolledMembers = useSelector((state) => state.advisoryGroup?.panelSelectedMembers);

    const [selectedAgId, setSelectedAgId] = useState(0);
    const [currentRepScore, setCurrentRepScore] = useState(null);
    const [state] = useState(location.state || {});
    const [enrolledMembersData, setEnrolledMembersData] = useState(enrolledMembers);
    const [potentialMemberIds, setPotentialMemberIds] = useState([]);
    const [enrolledMemberIds, setEnrolledMemberIds] = useState([]);
    const [tempMemberIds, setTempMemberIds] = useState([]);
    const [newMembers, setNewMembers] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [confirmModal, setConfirmModal] = useState(false);
    const [revisedRepScore, setRevisedRepScore] = useState(null);
    const [isMemberChecked, setIsMemberChecked] = useState(false);
    const [initialMemberStatus, setInitialMemberStatus] = useState({});
    const [updatedAgId, setUpdatedAgId] = useState(0);

    const agListActive = advisoryGroups?.filter(ag => ag.status === AG_STATUS.Active);

    const enrolledMembersRowActions = ({ row }) => {
        return (
            <div className="text-center">
                <RemoveCheckbox
                    id={row.original.id}
                    checked={
                        enrolledMemberIds?.includes(row.original.id) ||
                        tempMemberIds?.includes(row.original.id)
                    }
                    onRemove={() =>
                        handleEnrolledCheckboxChange(
                            row.original.id,
                            row.original.memberStatus,
                            event
                        )
                    }
                />
            </div>
        );
    };

    const potentialMembersRowActions = ({ row }) => {
        return (
            <div className='text-center'>
                <label key={row.original.id}>
                    <input
                        type="checkbox"
                        checked={potentialMemberIds.includes(row.original.id)}
                        onChange={() =>
                            handlePotentialCheckboxChange(
                                row.original.id,
                                event
                            )
                        }
                    />
                </label>
            </div>
        );
    };

    const handleEnrolledCheckboxChange = (memberId, memberStatus, event) => {
        setPotentialMemberIds([]);
        const updatedData = enrolledMembersData?.length > 0 && enrolledMembersData?.map((mem) => {
            if (event.target.checked) {
                if (memberStatus === AG_MEMBER_STATUS.Temporary) {
                    setTempMemberIds((prevIds) => [...prevIds, memberId]);
                }
                if (memberStatus === AG_RECRUITMENT_MEMBER_STATUS.Selected || memberStatus === AG_RECRUITMENT_MEMBER_STATUS.Enrolled) {
                    setEnrolledMemberIds((prevIds) => [...prevIds, memberId]);

                    if (!initialMemberStatus[mem.id]) {
                        setInitialMemberStatus((prev) => ({
                            ...prev,
                            [mem.id]: mem.memberStatus,
                        }));
                    }

                    const data = {
                        id: memberId,
                        memberStatus: AG_MEMBER_STATUS.Removed,
                    };

                    dispatch(updateEnrolledMemberData(data));
                }
            } else {
                if (enrolledMemberIds?.includes(memberId)) {
                    setEnrolledMemberIds((prevIds) => prevIds?.filter((prevId) => prevId !== memberId));

                    const prevMemStatus = {
                        ...mem,
                        memberStatus: initialMemberStatus[mem.id],
                    };
                    const resetData = {
                        id: memberId,
                        memberStatus: prevMemStatus.memberStatus,
                    };

                    dispatch(updateEnrolledMemberData(resetData));
                }
                if (tempMemberIds.includes(memberId)) {
                    setTempMemberIds((prevIds) => prevIds.filter((prevId) => prevId !== memberId));
                }
            }
            return mem;
        });
        setEnrolledMembersData(updatedData);
    };

    const handlePotentialCheckboxChange = (memberId, event) => {
        if (event.target.checked) {
            if (!potentialMemberIds.includes(memberId)) {
                setPotentialMemberIds((prevIds) => [...prevIds, memberId]);
            }
        } else if (potentialMemberIds.includes(memberId)) {
            setPotentialMemberIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== memberId)
            );
        }
    };

    const calculatePercentage = (row) => {
        const attendance = isNaN((row.attendedAttendance / row.totalAttendance) * 100) ? 0 : ((row.attendedAttendance / row.totalAttendance) * 100);

        if (row.memberStatus === AG_MEMBER_STATUS.Temporary) {
            return AG_MEMBER_STATUS.New;
        } else if ([AG_RECRUITMENT_MEMBER_STATUS.Selected, AG_MEMBER_STATUS.Removed, AG_RECRUITMENT_MEMBER_STATUS.Enrolled].includes(row.memberStatus) && attendance !== 0) {
            return `${toDecimal(attendance)}%`;
        } else if ([AG_RECRUITMENT_MEMBER_STATUS.Selected, AG_MEMBER_STATUS.Removed, AG_RECRUITMENT_MEMBER_STATUS.Enrolled].includes(row.memberStatus) && attendance === 0) {
            return '--';
        } else {
            return '--';
        }
    };

    const enrolledMembersColumns = [
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            cell: ({ row, cell }) => (<OverflowText id={cell.id} label={`${row.original.firstName} ${row.original.lastName}`}>{row.original.lastName}</OverflowText>),
            size: 150
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
            size: 100
        },
        {
            id: 'primaryLanguage',
            header: 'Language',
            accessorKey: 'primaryLanguage',
            size: 100
        },
        {
            id: 'totalAttendance',
            header: 'Attendance %',
            accessorFn: row => `${calculatePercentage(row)}`,
            sortingFn: 'alphanumeric',
            size: 100
        },
        {
            id: 'actions',
            header: 'Remove',
            cell: enrolledMembersRowActions,
            size: 100
        },
    ];

    const potentialMembersColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            enableColumnFilter: false,
            size: 150
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            enableColumnFilter: false,
            size: 150
        },
        {
            id: 'birthday',
            header: 'DOB',
            accessorFn: (row) => toDate(row.birthday),
            sortingFn: sortingFnDateOnly,
            sortType: 'date',
            filterFn: 'equalsString',
            size: 150
        },
        {
            id: 'race',
            header: 'Race',
            accessorKey: 'race',
            size: 150
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
            size: 150
        },
        {
            id: 'actions',
            header: 'Move',
            cell: potentialMembersRowActions,
            size: 50,
            minSize: 50,
            maxSize: 50,
        },
    ];

    const handleSelectChange = (e) => {
        if (newMembers?.length > 0 || enrolledMemberIds?.length > 0) {
            setUpdatedAgId(Number(e.target.value));
            setConfirmModal(true);
        } else {
            setSelectedAgId(Number(e.target.value));
        }
    };

    const handleReviseScore = () => {
        const tempSelectedMembers = newMembers ? newMembers : [];
        const tempRetiredMembers = enrolledMembers.filter(mem => mem.memberStatus === AG_MEMBER_STATUS.Removed) || [];
        const memberData = {
            tempSelectedMember: tempSelectedMembers,
            tempRetiredMember: tempRetiredMembers
        }

        setIsLoading(true);
        api.GetRevisedRepScore(selectedAgId, memberData).then(
            (res) => {
                const score = res?.data?.repScores?.find(score => score.type === REVISED_SCORE_TYPE.PanelScore);

                setRevisedRepScore(score?.aggregateScoreNumeric);
                setIsLoading(false);
            }
        );
    };

    const handleSave = () => {
        handleMoveToPotentialAndRetiredMembers();
        setIsLoading(true);
        setSelectedAgId(0);
    };

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updatePanelMaintenance, ALERT_TYPE.SUCCESS);
        setEnrolledMemberIds([]);
        setPotentialMemberIds([]);
        setEnrolledMembersData([]);
        setCurrentRepScore(null);
        setRevisedRepScore(null);
        dispatch(resetPanelSelection());
        dispatch(getAdvisoryGroupSelectedMembers(selectedAgId));
        dispatch(getAdvisoryGroupPotentialMembers(selectedAgId));
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleCancel = () => {
        const checkRemovedMembers = enrolledMembers.filter(mem => mem.memberStatus === AG_MEMBER_STATUS.Removed);
        if (newMembers?.length > 0 || checkRemovedMembers?.length > 0) {
            setConfirmModal(true);
        } else {
            resetPanelMaintenanceView();
        }
    };

    const resetPanelMaintenanceView = () => {
        setSelectedAgId(updatedAgId !== 0 ? updatedAgId : 0);
        setEnrolledMemberIds([]);
        setPotentialMemberIds([]);
        setEnrolledMembersData([]);
        setCurrentRepScore(null);
        setRevisedRepScore(null);
        dispatch(resetPanelSelection());
    }

    // Add to Enrolled members
    const handleMoveToEnrolledMembers = () => {
        let potentialMembersCopy = [...potentialMembers];
        let membersToSelect = [];
        for (let member of potentialMembers) {
            if (potentialMemberIds.includes(member.id)) {
                const memberIndexToRemove = potentialMembersCopy.findIndex(el => el.id === member.id);
                membersToSelect.push({
                    ...member,
                    prevMemberStatus: member.memberStatus, // save current status to temp key to restore when moving back
                    memberStatus: AG_MEMBER_STATUS.Temporary
                });
                potentialMembersCopy.splice(memberIndexToRemove, 1);
            }
        }
        dispatch(selectAgMembers({ newMembers: membersToSelect, updatedPotentialMembers: potentialMembersCopy }));
        setPotentialMemberIds([]);
    };

    // Remove from Enrolled members
    const handleMoveToPotentialAndRetiredMembers = () => {
        let enrolledMembersCopy = [...enrolledMembers];
        let memberData = [];

        for (let member of enrolledMembers) {
            let membersToRemove = [];
            const memberIndexToRemove = enrolledMembersCopy.findIndex(
                (el) => el.id === member.id
            );
            const memberCopy = {
                ...member,
                memberStatus: member.prevMemberStatus ?? member.memberStatus,
            };
            delete memberCopy['prevMemberStatus']; // restore to previous status and remove temp status
            membersToRemove.push(memberCopy);
            enrolledMembersCopy.splice(memberIndexToRemove, 1);

            const newMembers = membersToRemove;

            for (let newMember of newMembers) {
                if (newMember.memberStatus !== AG_RECRUITMENT_MEMBER_STATUS.Enrolled && newMember.memberStatus !== AG_RECRUITMENT_MEMBER_STATUS.Selected) {
                    memberData.push({
                        memberId: newMember.id,
                        memberStatus: enrolledMemberIds.includes(member.id)
                            ? AG_RECRUITMENT_MEMBER_STATUS.Retired
                            : tempMemberIds?.includes(newMember.id) ? newMember.memberStatus : AG_RECRUITMENT_MEMBER_STATUS.Selected,
                    });
                }
            }
        }
        api.UpdateRecruitmentMemberStatuses({ memberData: [...memberData], calculateRepScore: true, agId: selectedAgId })
            .then(handleSuccess)
            .catch(handleError);
        setEnrolledMemberIds([]);
        setTempMemberIds([]);
        setEnrolledMembersData([]);
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
    };

    const handleConfirm = () => {
        handleConfirmClose();
        showAlert(messages.success.noUpdatesPanelMaintenance, ALERT_TYPE.ERROR);
        resetPanelMaintenanceView();
    };

    useEffect(() => {
        if (enrolledMembers && (enrolledMembers.length === 0
            || enrolledMembers.every((mem) => mem.memberStatus === AG_MEMBER_STATUS.Temporary))) {
            setCurrentRepScore(null);
        }
        else {
            api.GetCurrentRepScore(selectedAgId, REVISED_SCORE_TYPE.PanelScore).then((res) => {
                setCurrentRepScore(res.data[0]?.aggregateScoreNumeric);
            });
        }
        setEnrolledMembersData(enrolledMembers);
    }, [enrolledMembers]);

    useEffect(() => {
        navigate(".", { replace: true });
    }, [navigate]);

    useEffect(() => {
        if (state && state.agId) {
            setSelectedAgId(state.agId);
        }
        selectedAgId === 0 && dispatch(resetPanelSelection());
    }, [location.pathname]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
    }, [isAppLoaded, dispatch, currentUser]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupSelectedMembers(selectedAgId));
            dispatch(getAdvisoryGroupPotentialMembers(selectedAgId));
        }
    }, [dispatch, isAppLoaded, currentUser, selectedAgId]);

    useEffect(() => {
        if (enrolledMembers) {
            let tempMembers = enrolledMembers.filter(
                (member) => member.memberStatus === AG_MEMBER_STATUS.Temporary
            );
            if (tempMembers?.length > 0) {
                setNewMembers(tempMembers);
            } else {
                setNewMembers([]);
            }
        }
    }, [enrolledMembers]);

    useEffect(() => {
        if (enrolledMemberIds?.length <= 0) {
            setIsMemberChecked(false);
        } else {
            setIsMemberChecked(true);
        }
    }, [enrolledMemberIds]);

    return (
        <>
            <ActionBar back={true}>
                <AgSelector
                    items={agListActive}
                    param="shortName"
                    value={selectedAgId}
                    placeholder="Select Advisory Group"
                    noRecordMessage="No advisory groups"
                    onSelectChange={handleSelectChange}
                    wrapperStyle="w-auto mb-3"
                />
                {selectedAgId !== 0 && (
                    <>
                        <div className="align-items-center">
                            <div className="d-flex gap-4 justify-content-center">
                                <PanelMaintenanceScore
                                    label="Current Score"
                                    score={currentRepScore}
                                />
                                <PanelMaintenanceScore
                                    label="Revised Score"
                                    score={revisedRepScore}
                                    loading={isLoading}
                                />
                            </div>
                        </div>
                        <div className="d-flex gap-2 justify-content-end">
                            <Button
                                variant="primary"
                                type="button"
                                disabled={
                                    isLoading || isMemberChecked
                                        ? !isMemberChecked
                                        : !newMembers?.length > 0
                                }
                                onClick={handleReviseScore}>
                                Revise Score
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                disabled={
                                    isLoading || isMemberChecked
                                        ? !isMemberChecked
                                        : !newMembers?.length > 0
                                }
                                onClick={handleSave}>
                                Save
                            </Button>
                            <Button
                                variant="primary"
                                type="button"
                                onClick={handleCancel}>
                                Close
                            </Button>
                        </div>
                    </>
                )}
            </ActionBar>
            {selectedAgId === 0 ? (
                <div className="border border-1 rounded bg-white p-3 shadow-sm mb-3">
                    Select an advisory group below to proceed with{' '}
                    <mark className="fw-bold">Panel Maintenance</mark> process.
                </div>
            ) : (
                <>
                    <div className="px-3 py-2 mt-4">
                        <CollapsePanel
                            title="Instructions"
                            wrapperStyle="mx-n3 mt-n3">
                            <p className="lh-sm mb-0 small">
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur.
                            </p>
                        </CollapsePanel>
                    </div>
                    <Row className="mt-4">
                        <Col
                            md={5}
                            className="position-relative">
                            <ActionBar
                                title="Enrolled Members"
                                titleClass="fw-bold"
                            />
                            {enrolledMembers === null ? (
                                <Loader />
                            ) : (
                                <DataGrid
                                    columns={enrolledMembersColumns}
                                    data={enrolledMembers}
                                    pagination={false}
                                    enableFilters={false}
                                    gridContainerClass={`grid-with-scroll overflow-auto ${gridStyles.hasFilters}`}
                                />
                            )}
                        </Col>
                        <Col
                            md={1}
                            className="px-0 d-flex justify-content-center align-self-center">
                            <div className="d-flex flex-column gap-2 mt-4">
                                <Button
                                    variant="primary"
                                    type="button"
                                    className="d-flex justify-content-center align-items-center ps-2"
                                    disabled={potentialMemberIds?.length === 0}
                                    onClick={handleMoveToEnrolledMembers}>
                                    <Icon
                                        icon="arrow-down"
                                        size={12}
                                        className="rotate-90 me-1"
                                    />
                                    Move
                                </Button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <ActionBar
                                title="Potential Members"
                                titleClass="fw-bold"
                            />
                            {potentialMembers === null ? (
                                <Loader />
                            ) : (
                                <DataGrid
                                    columns={potentialMembersColumns}
                                    data={potentialMembers}
                                    pagination={false}
                                    gridContainerClass="grid-with-scroll overflow-auto"
                                />
                            )}
                        </Col>
                    </Row>
                    <ConfirmModal
                        show={confirmModal}
                        message={messages.confirm.discardProgramEdit}
                        onConfirm={handleConfirm}
                        onCancel={handleConfirmClose}
                        onHideCallback={handleConfirmClose}
                    />
                </>
            )}
        </>
    );
};

export default withAuth(PanelMaintenance);
