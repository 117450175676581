import api from '../../services/api/reportService';
import keyMirror from '../../utils/keyMirror';
import { POWER_BI_REPORTS } from '../../constants'
import { getReportFromPayload } from '../../utils/utils';

export const actionTypes = keyMirror(
    {
        GET_EMBEDED_URLS: null,
        SET_REPORT_CARD_REPORT: null,
        SET_STAR_EXPLORER_REPORT: null,
        SET_POPULATION_CHARACTERIZATION_REPORT: null,
        SET_RISK_MANAGEMENT_REPORT: null
    },
    'Report'
);

// Action Creators
export const getPowerBiEmbededUrls = () => dispatch => {
    api.getPowerBiEmbededUrls().then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_EMBEDED_URLS,
            payload: data,
        });
    });
}
export const setPowerBiReport = (reportData) => dispatch => {
    if (reportData.reportName === POWER_BI_REPORTS.STAR_REPORT_CARD) {
        dispatch({
            type: actionTypes.SET_REPORT_CARD_REPORT,
            payload: { ...reportData.data },
        });
    }
    else if (reportData.reportName === POWER_BI_REPORTS.STARS_DATA_EXPLORER) {
        dispatch({
            type: actionTypes.SET_STAR_EXPLORER_REPORT,
            payload: { ...reportData.data },
        });
    }
    else if (reportData.reportName === POWER_BI_REPORTS.POPULATION_CHARECTERIZATIONS) {
        dispatch({
            type: actionTypes.SET_POPULATION_CHARACTERIZATION_REPORT,
            payload: reportData.data,
        });
    }
}

// Reducer
export const initialState = {
    reportCardSlicerState: null,
    starExplorerSlicerState: null,
    populationCharacterizationSlicerState: null,
    riskManagementSlicerState: null,
    accessToken: '',
    home: {},
    stateOverview: {},
    nationalDashboard: {},
    stateDetail: {},
    reportCard: {},
    multiFacility: {},
    timely: {},
    patientExp: {},
    safety: {},
    readmission: {},
    mortality: {},
    coreDemographic: {},
    technology: {},
    compareCensus: {},
    geographicDispersion: {},
    healthAccess: {},
    healthAccessDrilldown: {},
    geographicDrilldown: {},
    technologyDrillDown: {},
};

export default function reportReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_EMBEDED_URLS: {
            return {
                ...state,
                accessToken: payload.accessToken,
                home: getReportFromPayload(payload.value, 'home'),
                nationalDashboard: getReportFromPayload(payload.value, 'nationalDashboard'),
                stateOverview: getReportFromPayload(payload.value, 'stateOverview'),
                stateDetail: getReportFromPayload(payload.value, 'stateDetail'),
                reportCard: getReportFromPayload(payload.value, 'reportCard'),
                multiFacility: getReportFromPayload(payload.value, 'multiFacility'),
                timely: getReportFromPayload(payload.value, 'timely'),
                patientExp: getReportFromPayload(payload.value, 'patientExp'),
                safety: getReportFromPayload(payload.value, 'safety'),
                readmission: getReportFromPayload(payload.value, 'readmission'),
                mortality: getReportFromPayload(payload.value, 'mortality'),
                coreDemographic: getReportFromPayload(payload.value, 'coreDemographic'),
                technology: getReportFromPayload(payload.value, 'technology'),
                compareCensus: getReportFromPayload(payload.value, 'compareCensus'),
                geographicDispersion: getReportFromPayload(payload.value, 'geographicDispersion'),
                healthAccess: getReportFromPayload(payload.value, 'healthAccess'),
                healthAccessDrilldown: getReportFromPayload(payload.value, 'healthAccessDrilldown'),
                geographicDrilldown: getReportFromPayload(payload.value, 'geographicDrilldown'),
                technologyDrillDown: getReportFromPayload(payload.value, 'technologyDrillDown'),
                cohortAge: getReportFromPayload(payload.value, 'cohortAge'),
                cohortGender: getReportFromPayload(payload.value, 'cohortGender'),
                cohortLanguage: getReportFromPayload(payload.value, 'cohortLanguage'),
                cohortRaceEthnicity: getReportFromPayload(payload.value, 'cohortRaceEthnicity'),
                cohortSalesChannel: getReportFromPayload(payload.value, 'cohortSalesChannel'),
                cohortCustomerInteractions: getReportFromPayload(payload.value, 'cohortCustomerInteractions'),
                cohortAgeXGender: getReportFromPayload(payload.value, 'cohortAgeXGender'),
                cohortAgeXLanguage: getReportFromPayload(payload.value, 'cohortAgeXLanguage'),
                cohortAgeXRaceEthnicity: getReportFromPayload(payload.value, 'cohortAgeXRaceEthnicity'),
                cohortAgeXSalesChannel: getReportFromPayload(payload.value, 'cohortAgeXSalesChannel'),
                cohortAgeXCustomerInteractions: getReportFromPayload(payload.value, 'cohortAgeXCustomerInteractions'),
                cohortGenderXLanguage: getReportFromPayload(payload.value, 'cohortGenderXLanguage'),
                cohortGenderXRaceEthnicity: getReportFromPayload(payload.value, 'cohortGenderXRaceEthnicity'),
                cohortGenderXSalesChannel: getReportFromPayload(payload.value, 'cohortGenderXSalesChannel'),
                cohortGenderXCustomerInteractions: getReportFromPayload(payload.value, 'cohortGenderXCustomerInteractions'),
                cohortRaceEthnicityXLanguage: getReportFromPayload(payload.value, 'cohortRaceEthnicityXLanguage'),
                cohortRaceEthnicityXSalesChannel: getReportFromPayload(payload.value, 'cohortRaceEthnicityXSalesChannel'),
                cohortRaceEthnicityXCustomerInteractions: getReportFromPayload(payload.value, 'cohortRaceEthnicityXCustomerInteractions'),
                cohortLanguageXSalesChannel: getReportFromPayload(payload.value, 'cohortLanguageXSalesChannel'),
                cohortLanguageXCustomerInteractions: getReportFromPayload(payload.value, 'cohortLanguageXCustomerInteractions'),
                cohortSalesChannelXCustomerInteractions: getReportFromPayload(payload.value, 'cohortSalesChannelXCustomerInteractions'),
                cohortVulnerableMember: getReportFromPayload(payload.value, 'cohortVulnerableMember'),
                companyAge: getReportFromPayload(payload.value, 'companyAge'),
                companyGender: getReportFromPayload(payload.value, 'companyGender'),
                companyLanguage: getReportFromPayload(payload.value, 'companyLanguage'),
                companyRaceEthnicity: getReportFromPayload(payload.value, 'companyRaceEthnicity'),
                companyAgeXGender: getReportFromPayload(payload.value, 'companyAgeXGender'),
                companyAgeXLanguage: getReportFromPayload(payload.value, 'companyAgeXLanguage'),
                companyAgeXRaceEthnicity: getReportFromPayload(payload.value, 'companyAgeXRaceEthnicity'),
                companyGenderXLanguage: getReportFromPayload(payload.value, 'companyGenderXLanguage'),
                companyGenderXRaceEthnicity: getReportFromPayload(payload.value, 'companyGenderXRaceEthnicity'),
                companyRaceEthnicityXLanguage: getReportFromPayload(payload.value, 'companyRaceEthnicityXLanguage'),
                companyVulnerableMember: getReportFromPayload(payload.value, 'companyVulnerableMember'),
                recruitmentCoreDemographic: getReportFromPayload(payload.value, 'recruitmentCoreDemographic'),
                recruitmentTechnology: getReportFromPayload(payload.value, 'recruitmentTechnology'),
                recruitmentCompareCohort: getReportFromPayload(payload.value, 'recruitmentCompareCohort'),
                recruitmentGeographicDispersion: getReportFromPayload(payload.value, 'recruitmentGeographicDispersion'),
                recruitmentHealthAccess: getReportFromPayload(payload.value, 'recruitmentHealthAccess'),
                recruitmentHealthAccessDrilldown: getReportFromPayload(payload.value, 'recruitmentHealthAccessDrilldown'),
                recruitmentGeographicDrilldown: getReportFromPayload(payload.value, 'recruitmentGeographicDrilldown'),
                recruitmentTechnologyDrillDown: getReportFromPayload(payload.value, 'recruitmentTechnologyDrillDown'),
                empaneledDemographic: getReportFromPayload(payload.value, 'empaneledDemographic'),
                empaneledCompareCohort: getReportFromPayload(payload.value, 'empaneledCompareCohort'),
                empaneledTechnology: getReportFromPayload(payload.value, 'empaneledTechnology'),
                empaneledGeographicDispersion: getReportFromPayload(payload.value, 'empaneledGeographicDispersion'),
                empaneledHealthAccess: getReportFromPayload(payload.value, 'empaneledHealthAccess'),
                empaneledAttendanceDemographic: getReportFromPayload(payload.value, 'empaneledAttendanceDemographic'),
                empaneledAttendanceGeographicDispersion: getReportFromPayload(payload.value, 'empaneledAttendanceGeographicDispersion'),
                empaneledAttendanceSummaryStatsByMeeting: getReportFromPayload(payload.value, 'empaneledAttendanceSummaryStatsByMeeting'),
            };
        }

        case actionTypes.SET_REPORT_CARD_REPORT: {
            return {
                ...state,
                reportCardSlicerState: payload
            };
        }

        case actionTypes.SET_STAR_EXPLORER_REPORT: {
            return {
                ...state,
                starExplorerSlicerState: payload
            };
        }

        case actionTypes.SET_POPULATION_CHARACTERIZATION_REPORT: {
            return {
                ...state,
                populationCharacterizationSlicerState: payload
            };
        }

        case actionTypes.SET_RISK_MANAGEMENT_REPORT: {
            return {
                ...state,
                riskManagementSlicerState: payload
            };
        }

        default:
            return state;
    }
};
