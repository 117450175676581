import api from '../../services/api/companyMessageService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_COMPANY_DETAILS: null,
        UPDATE_COMPANY_PARAMS: null,
        RESET_COMPANY: null,
        GET_COMPANY_DETAILS_BY_ID: null,
        GET_COMPANY_USERS_BY_ID: null,
        CREATE_COMPANY: null,
        UPDATE_COMPANY: null,
        COMPANY_LOGO: null,
        DELETE_COMPANY: null
    },
    'Company'
);

// Action Creators

export const getCompany = (companyId = null) => dispatch => {
    if (companyId) {
        api.GetCompanyDataById(companyId).then(response => {
            const { data } = response;
            dispatch({
                type: actionTypes.GET_COMPANY_DETAILS,
                payload: [data],
            });
        });
    }
    else {
        api.GetCompanyData().then(response => {
            const { data } = response;
            dispatch({
                type: actionTypes.GET_COMPANY_DETAILS,
                payload: data,
            });
        });
    }
}
export const getCompanyById = (id) => dispatch => {
    api.GetCompanyDataById(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_COMPANY_DETAILS_BY_ID,
            payload: data,
        });
    });
}
export const getCompanyUsersById = (id) => dispatch => {
    api.GetCompanyUsersById(id).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_COMPANY_USERS_BY_ID,
            payload: data,
        });
    });
}

export const resetCompany = () => dispatch => {
    dispatch({
        type: actionTypes.RESET_COMPANY
    });
}
export const createCompany = (data, successHandler) => dispatch => {
    api.CreateCompanyData(data).then(() => {
        dispatch({
            type: actionTypes.CREATE_COMPANY
        });
        successHandler();
    });
}
export const updateCompany = (data, successHandler) => dispatch => {
    api.UpdateCompanyData(data).then(() => {
        dispatch({
            type: actionTypes.UPDATE_COMPANY,
            payload: data
        });
        successHandler();
    });
}
export const deleteCompany = (data) => dispatch => {
    api.UpdateCompanyData(data).then(() => {
        dispatch({
            type: actionTypes.DELETE_COMPANY,
            payload: data
        });
    });
}
export const getCompanyLogo = (id) => dispatch => {
    api.GetCompanyLogo(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.COMPANY_LOGO,
            payload: { id, data }
        });
    });
}
export const getCompanyLogoById = (id) => dispatch => {
    api.GetCompanyLogo(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.UPDATE_COMPANY_PARAMS,
            payload: { key: 'fileURL', value: data }
        });
        if (data.metaData?.logobgcolor) {
            dispatch({
                type: actionTypes.UPDATE_COMPANY_PARAMS,
                payload: { key: 'logoBgColor', value: data.metaData.logobgcolor }
            });
        }
    });
}


export const updateCompanyParams = (data) => dispatch => {
    dispatch({
        type: actionTypes.UPDATE_COMPANY_PARAMS,
        payload: data,
    });
}

// Reducer

export const initialState = {
    companies: null,
    company: {
        "id": -1,
        "name": '',
        "address": "",
        "city": "",
        "state": "",
        "postalCode": "",
        "contactFirstName": "",
        "contactLastName": "",
        "contactEmail": "",
        "contactPhone": "",
        "logo": '',
        "recordStatus": "O"
    },
    users: null,
};

export default function companyReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_COMPANY_DETAILS:
            return {
                ...state,
                companies: [...payload],
            };
        case actionTypes.GET_COMPANY_DETAILS_BY_ID:
            return {
                ...state,
                company: { ...payload, contactPhone: Number(payload.contactPhone), fileURL: '' },
            };
        case actionTypes.GET_COMPANY_USERS_BY_ID:
            return {
                ...state,
                users: [...payload],
            };
        case actionTypes.RESET_COMPANY:
            return {
                ...state,
                company: { ...initialState.company },
            };
        case actionTypes.UPDATE_COMPANY_PARAMS: {
            let obj = { ...state.company };
            obj[payload.key] = payload.value;
            return {
                ...state,
                company: { ...obj }
            };
        }
        case actionTypes.COMPANY_LOGO: {
            let companiesCopy = [...state.companies];
            const companyIndex = companiesCopy.findIndex(element => element.id == payload.id);
            companiesCopy[companyIndex] = { ...companiesCopy[companyIndex], fileURL: payload.data };
            return {
                ...state,
                companies: [...companiesCopy]
            };
        }
        case actionTypes.DELETE_COMPANY: {
            let companiesCopy = [...state.companies];
            const companyIndex = companiesCopy.findIndex(element => element.id == payload.id);
            companiesCopy.splice(companyIndex, 1);
            return {
                ...state,
                companies: [...companiesCopy]
            };
        }
        case actionTypes.UPDATE_COMPANY: {
            let companiesCopy = [...state.companies];
            const companyIndex = companiesCopy.findIndex(element => element.id == payload.id);
            companiesCopy[companyIndex] = { ...payload };
            return {
                ...state,
                companies: [...companiesCopy]
            };
        }

        default:
            return state;
    }
};
