import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};


const qualityManagementApi = {
    getStarPlannerShinyURL: async () => {
        return apiInstance({
            method: 'get',
            url: `${protectedResources.apiQualityManagement.endpoint}/star-planner/shiny-url`,
            headers,
        })
    }
}

export default qualityManagementApi;
