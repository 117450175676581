import React, { useContext, useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, Button } from "react-bootstrap";
import { useReactToPrint } from 'react-to-print';
import GridCellRating from "../../../components/ui/GridCellRating";
import DataGrid from "../../../components/DataGrid";
import Loader from "../../../components/ui/Loader";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import {
    getAdvisoryGroupRepresentativenessByType,
    resetRepresentativenessData,
} from '../../../ducks/advisoryGroup';
import { toDecimal } from "../../../utils/utils";
import { AG_REPSCORE_COLUMN_HEADER_PREFIX, AG_REPSCORE_TYPE } from '../../../constants';
import styles from '../RepresentativenessDetailsModal.module.scss';

const RepresentativenessDetailsModal = ({ show, id, type, onCancel }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);

    const contentRef = useRef(null);
    const repsDetailData = useSelector((state) => state.advisoryGroup.representativenessData);

    const [title, setTitle] = useState('');
    const [columns, setColumns] = useState([]);

    const handlePrint = useReactToPrint({
        documentTitle: `${title}-${id}`,
        removeAfterPrint: true,
    });

    const hideColumn = {
        category: false,
    };

    const baseColumns = [
        {
            id: 'category',
            header: 'Category',
            accessorKey: 'category',
            enableSorting: false,
        },
        {
            id: 'categoryValue',
            header: 'Category',
            accessorKey: 'categoryValue',
            cell: ({ row }) => row.original.categoryValue,
            aggregatedCell: ({ row }) => (<div className="fw-bold">{row.original.category}</div>),
            enableSorting: false,
        },
        {
            id: 'populationPercent',
            header: 'Baseline Result',
            accessorKey: 'populationPercent',
            cell: ({ row }) => toDecimal(row.original.populationPercent * 100),
            aggregatedCell: () => null,
            enableSorting: false,
        },
    ];

    const scoreColumns = [
        {
            id: 'panelMembershipPercent',
            header: `${AG_REPSCORE_COLUMN_HEADER_PREFIX[type]} Result`,
            accessorKey: 'panelMembershipPercent',
            cell: ({ row }) => toDecimal(row.original.panelMembershipPercent * 100),
            aggregatedCell: () => null,
            enableSorting: false,
        },
        {
            id: 'difference',
            header: 'Difference',
            accessorKey: 'difference',
            cell: ({ row }) => toDecimal((row.original.populationPercent * 100) - (row.original.panelMembershipPercent * 100)),
            enableSorting: false,
        },
        {
            id: 'panelRating',
            header: 'Score',
            accessorKey: 'panelRating',
            cell: ({ row }) => <GridCellRating rating={row.original.panelRating} customClass="my-1" />,
            enableSorting: false,
        },
    ];

    const handleModalClose = () => {
        dispatch(resetRepresentativenessData());
        onCancel();
    }

    const getColumns = () => {
        if (type === AG_REPSCORE_TYPE.Baseline) {
            return baseColumns;
        } else {
            return [
                ...baseColumns,
                ...scoreColumns
            ]
        }
    }

    const getTitle = () => {
        switch (type) {
            case AG_REPSCORE_TYPE.Panel:
                return `Selection-ScoreCard`
            case AG_REPSCORE_TYPE.Attendance:
                return `Attendance-ScoreCard`
            default:
                return `Representativeness-Details`
        }
    }

    useEffect(() => {
        if (type) {
            setTitle(getTitle());
            setColumns(getColumns());
        }
    }, [type]);

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getAdvisoryGroupRepresentativenessByType(id, type));
        }
    }, [isAppLoaded, id]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleModalClose}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{title.replace('-', ' ')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <div className="d-flex justify-content-end mb-3">
                        <Button
                            variant="primary"
                            disabled={!repsDetailData?.length}
                            onClick={() => {
                                handlePrint(null, () => contentRef.current);
                            }}>
                            Print
                        </Button>
                    </div>
                    <div>
                        {repsDetailData === null ? (
                            <Loader />
                        ) : (
                            <div
                                ref={contentRef}
                                className={styles.printableArea}>
                                <DataGrid
                                    data={repsDetailData}
                                    columns={columns}
                                    enableFilters={false}
                                    columnDisplay={hideColumn}
                                    groupBy={['category']}
                                    pagination={false}
                                    gridContainerClass="grid-with-scroll overflow-y-auto"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RepresentativenessDetailsModal;
