import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import api from '../../services/api/contentService';
import Loader from './Loader';
import Style from './ContentFileModal.module.scss';
import PdfViewer from './PdfViewer';

function ContentFileModal({ contentId, title, contentType, show, modalClose, className }) {
    const [file, setFile] = useState(null);

    useEffect(() => {
        api.GetContentFile(contentId).then((response) => {
            const { data } = response;
            setFile(data);
        });
    }, [contentId]);

    const handleClose = () => {
        setFile(null);
        modalClose();
    };

    return (
        <Modal
            size="lg"
            show={show}
            onHide={handleClose}
            dialogClassName={`${className}`}>
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${Style.modalBody}`}>
                <div className="d-flex align-items-center justify-content-center">
                    {file ? (
                        contentType === 'application/pdf' ? <PdfViewer file={file} /> :
                            <object
                                data={file}
                                type={contentType}
                                className="w-100"
                            />
                    ) : (
                        <Loader
                            className="d-flex align-items-center"
                            animation="border"
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default ContentFileModal;
