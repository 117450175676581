import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Row, Tab } from "react-bootstrap";
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import withAuth from '../../../../components/hoc/withAuth';
import RecruitmentStatisticsPowerBiReport from './AgViewPowerBiReport';
import api from "../../../../services/api/advisoryGroupService";
import AgSelector from "../../../../components/ui/Selector";
import NewButton from '../../../../components/ui/NewButton';
import Placeholder from '../../../../components/ui/Placeholder';

const RecruitingAgView = () => {
    const isAppLoaded = useContext(AppLoadContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [selectedAgId, setSelectedAgId] = useState(0);
    const [agList, setAgList] = useState(null);
    const [disableCohortSelection, setDisableCohortSelection] = useState(true);
    const [printReport, setPrintReport] = useState(false);

    const handleSelectChange = (e) => {
        setSelectedAgId(Number(e.target.value));
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            api.getAdvisoryGroupPopChar(currentUser?.companyId).then(({ data }) => {
                setAgList(data);
                if (data.length > 0) {
                    setSelectedAgId(Number(data[0].id));
                }
            });
        }
    }, [isAppLoaded, currentUser]);

    const reportRendered = (val) => {
        setDisableCohortSelection(!val);
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className={`flex-grow-1 tab-container`}>
                <Tab.Container >
                    <Row className="align-items-start gx-3 align-items-lg-end">
                        <div className={`d-flex align-items-center flex-shrink-0 mb-2 py-1 justify-content-between`}>
                            <AgSelector
                                items={agList}
                                param="shortName"
                                value={selectedAgId}
                                placeholder="Select Advisory Group"
                                noRecordMessage="No active advisory groups"
                                onSelectChange={handleSelectChange}
                                wrapperStyle="w-auto mb-3"
                                disabled={disableCohortSelection}
                            />
                            <NewButton onClick={() => setPrintReport(true)} label="Print"
                                disabled={disableCohortSelection} />
                        </div>
                    </Row>
                    {agList && agList.length > 0 ? <Tab.Content className="mb-4">
                        <RecruitmentStatisticsPowerBiReport agId={selectedAgId} reportRendered={reportRendered}
                            printReport={printReport}
                            setPrintReport={(val) =>
                                setPrintReport(val)
                            } />
                    </Tab.Content>
                        : <div className="embed-wrapper h-100">
                            <Placeholder
                                title={`Power BI `}
                            />
                        </div>
                    }
                </Tab.Container>
            </div>
        </div>
    );
};

export default withAuth(RecruitingAgView);
