import blog2Pdf from "../assets/docs/EAC_for_DSNP.pdf";
import blog1Pdf from "../assets/docs/Proposed_Blog_Post_on_Plan-Hospital_Stars.pdf";

const blogData = [
    {
        id: 2,
        title: "Enrollee Advisory Committees for Dual – Special Needs Plans: A Challenge, and a Tremendous Opportunity to Improve Plan and Member Outcomes",
        date: "October 15 2022",
        audience: ["Health Plans"],
        file: blog2Pdf,
    },
    {
        id: 1,
        title: "Quality Works With Quality - The Relationship Between Hospital Star Ratings and Plan Star Rating",
        date: "August 15 2022",
        audience: ["Health Plans", "Hospitals"],
        file: blog1Pdf
    }
];

export default blogData;
