import {useState} from "react";
import {Modal} from "react-bootstrap";
import PdfViewer from "./PdfViewer";

const PdfModal = (props) => {
    const [show, setShow] = useState(props.open);
    const [file, setFile] = useState(props.file);
    const handleClose = () => {
        setFile(null);
        setShow(false);
        props.modalClose();
    };

    return (
        <Modal
            show={show}
            onHide={handleClose}
            dialogClassName={props.modalStyle}
        >
            <Modal.Header closeButton>
                <Modal.Title>{props.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    file ?
                    <PdfViewer file={file}/>
                        : <div className="alert alert-info">No files loaded</div>
                }
            </Modal.Body>
        </Modal>
    );
}

export default PdfModal;
