import { Col, Nav, Navbar, Row } from "react-bootstrap";

import banner from '../assets/images/login-banner.png';
import pyramidGraphic from '../assets/images/pyramid-graphic.svg';
import styles from './Login.module.scss';

const Login = () => {
    return (
        <div className="overflow-hidden">
            <Row className="position-relative bg-secondary d-flex gx-0">
                <Col md={7} lg={8} className={`${styles.banner}`}>
                    <img
                        src={banner}
                        alt="PHARO login banner"
                    />
                </Col>
                <Col md={5} lg={4} className={`py-4 px-3 ps-lg-0 pe-lg-4 pe-xxl-5 text-white d-flex flex-column justify-content-center`}>
                    <div className={styles.content}>
                        <h1 className={`${styles.title} fw-light`}>PHARO</h1>
                        <p>Predict Health’s Analytics, Reporting and Operations (PHARO) solution is a cloud-based consumer intelligence platform which captures, analyzes and converts consumer intelligence into actionable insights to help Medicare payors and other healthcare entities improve business outcomes, performance and operations.</p>
                        <div className="d-flex align-items-center">
                            <p>PHARO provides a scalable means to continuously understand the behavior and motivations for a health plan member or prospective member by establishing and leveraging a “whole person view” of the individual.</p>
                            <img src={pyramidGraphic} alt="PHARO pyramid graphic" className={`${styles.graphic} ms-2`} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Navbar bg="primary" data-bs-theme="dark" className="justify-content-center py-0">
                <Nav>
                    <Nav.Link href="https://predicthealth.ai/about" target="_blank" className="py-0 me-4">About Us</Nav.Link>
                    <Nav.Link href="https://predicthealth.ai/contact" target="_blank" className="py-0 me-4">Contact Us</Nav.Link>
                    <Nav.Link href="https://predicthealth.ai/terms" target="_blank" className="py-0 me-4">Terms of Service</Nav.Link>
                    <Nav.Link href="https://predicthealth.ai/privacy" target="_blank" className="py-0">Privacy Notice</Nav.Link>
                </Nav>
            </Navbar>
        </div>
    );
};

export default Login;
