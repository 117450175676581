import React from 'react';
import withAuth from '../../../../components/hoc/withAuth';
import Placeholder from '../../../../components/ui/Placeholder';

const RecruitingCompanyView = () => {
    return (
        <Placeholder
            title="Application Detail"
            type="graphic"
        />
    );
};

export default withAuth(RecruitingCompanyView);
