import Spinner from "react-bootstrap/Spinner";
import styles from './Loader.module.scss';

const Loader = (props) => {
    let loaderSize = props.size ? props.size : 4;
    const loaderStyle = {
        width: `${loaderSize}rem`,
        height: `${loaderSize}rem`
    }

    return (
        <div className={`position-absolute top-0 bottom-0 start-0 end-0 d-flex align-items-center justify-content-center ${styles.wrapper}`}>
            <Spinner animation="border" variant="secondary" style={loaderStyle}>
                <span className="visually-hidden">Loading...</span>
            </Spinner>
        </div>
    );
}

export default Loader;
