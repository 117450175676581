import React from "react";
import {loginRequest} from "../../msalAuthConfig";
import {InteractionType} from "@azure/msal-browser";
import {MsalAuthenticationTemplate} from "@azure/msal-react";

const withAuth = (Component) => {
    return class AuthenticatedComponent extends React.Component {
        render() {
            const authRequest = {
                ...loginRequest
            };

            return (
                <MsalAuthenticationTemplate
                    interactionType={InteractionType.Redirect}
                    authenticationRequest={authRequest}
                >
                    <Component {...this.props} />
                </MsalAuthenticationTemplate>
            );
        }
    }
}

export default withAuth;
