import React, { useEffect, useContext, useState } from "react";
import { Button, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import withAuth from "../../../components/hoc/withAuth";
import DataGrid from "../../../components/DataGrid";
import ActionBar from "../../../components/ui/ActionBar";
import { toDateTime, optionValues, exportData, isUser } from "../../../utils/utils";
import Icon from "../../../components/ui/Icon";
import GridButton from "../../../components/ui/GridButton";
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import { getCohorts, getNotificationsByApplication, archiveCohort, archiveNotification } from '../../../ducks/cohort';
import styles from '../../../views/member-analytics/member-management/MemberManagementHome.module.scss';
import Loader from "../../../components/ui/Loader";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import messages from "../../../utils/helper/messages";
import ExportModal from './ExportModal';
import RetentionRiskViewModal from './RetentionRiskViewModal';
import {
    EXPORT_RETENTION_RISK_FORMAT, NOIFICATION_TYPE, COHORT_RETENTION_RISK_STATUS,
    COHORT_TRAINING_FLAG, COHORT_FILE_STATUS, AUDIENCE_APPLICATION_STATUS, NOTIFICATION_RECORD_STATUS, ALERT_TYPE
} from "../../../constants";
import { AlertContext } from "../../../context/AlertContext";
import Col from "react-bootstrap/Col";
import NavigationBoxDashboard from "../../../components/ui/NavigationBoxDashboard";

const RetentionRisk = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedItem, setSelectedItem] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmExportModal, setConfirmExportModal] = useState(false);
    const [showNotificationViewModal, setShowNotificationViewModal] = useState(false);
    const [exportCohortOptions, setExportCohortOptions] = useState([]);
    const [currentNotification, setCurrentNotification] = useState(null);
    const [archiveMessage, setArchiveMessage] = useState('');
    const [filteredCohorts, setFilteredCohorts] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const cohort = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);
    const notifications = useSelector((state) => state.cohort.notifications);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
            dispatch(getNotificationsByApplication(currentUser?.companyId, AUDIENCE_APPLICATION_STATUS.MEMBER_ANALYTICS));
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (cohort) {
            let arr = [];
            cohort.map((item) => {
                if (item.retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Scored
                    && COHORT_TRAINING_FLAG[item.training] === COHORT_TRAINING_FLAG.N && item.status === COHORT_FILE_STATUS.ENRICHED) {
                    return arr.push({ id: item.id, value: item.name })
                }
                return null;
            });
            setExportCohortOptions(arr);

            const cohortsFiltered = cohort.filter((item) => item.training === 'N' && item.status === COHORT_FILE_STATUS.ENRICHED);
            setFilteredCohorts(cohortsFiltered);
        }
    }, [cohort]);

    const exportTypeOptions = optionValues(EXPORT_RETENTION_RISK_FORMAT);

    const arr = [
        { key: 'cohort', labelName: 'Cohort Name', type: 'options', optionValues: exportCohortOptions, cols: 9 },
        { key: 'type', labelName: 'File Type', type: 'options', optionValues: exportTypeOptions, cols: 3 },
    ];

    const hideColumn = {
        dateTime: false,
    };

    const cohortColumns = [
        {
            id: 'dateTime',
            header: 'DateTime',
            accessorKey: 'dateTime',
            accessorFn: (row) => (new Date(row.dateTime)),
        },
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
            sortingFn: 'text'
        },
        {
            id: 'retentionRiskStatus',
            header: 'Status',
            accessorKey: 'retentionRiskStatus',
        },
        {
            id: 'retentionRiskStatusDateTime',
            header: 'Status Date',
            accessorKey: 'retentionRiskStatusDateTime',
            accessorFn: row => `${toDateTime(row.retentionRiskStatusDateTime)}`,
            size: 150,
        },
        {
            id: 'retentionRiskMonitoringStatus',
            header: 'Monitoring Status',
            accessorKey: 'retentionRiskMonitoringStatus',
        },
        {
            id: 'retentionRiskMonitoringStatusDateTime',
            header: 'Monitoring Date',
            accessorKey: 'retentionRiskMonitoringStatusDateTime',
            accessorFn: row => `${toDateTime(row.retentionRiskMonitoringStatusDateTime)}`,
            size: 150,
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 170,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                        disabled={isCohortViewDisable(row.original)} onClick={() => { handleCohortView(row.original) }}>
                        <Icon icon="view" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Monitor"
                        onClick={() => handleCohortMonitor()}>
                        <Icon icon="risk-monitor-system" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Analyze"
                        disabled={isAnalyzeDisable(row.original)}
                    >
                        <Icon icon="analyze" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Export"
                        disabled={isExportDisable(row.original)} onClick={() => { handleExportConfirm() }}>
                        <Icon icon="download" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                        onClick={() => handleArchiveConfirm(row.original.id, 'cohort')}>
                        <Icon icon="archive" size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const columnsNotification = [
        {
            id: 'dateTime',
            header: 'Date/Time',
            accessorKey: 'dateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
            sortingFn: 'datetime',
        },
        {
            id: 'cohortName',
            header: 'Cohort Name',
            sortType: 'text',
            accessorKey: 'CohortName'
        },
        {
            id: 'message',
            header: 'Message',
            accessorKey: 'message'
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 90,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                        onClick={() => handleNotificationViewConfirm(row.original.id)}
                    >
                        <Icon icon="view" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                        onClick={() => handleArchiveConfirm(row.original.id, 'notification')}>
                        <Icon icon="archive" size={14} />
                    </GridButton>
                </>
            ),
        },
    ];

    const handleCohortView = ({ retentionRiskStatus, id }) => {
        if (retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Error) {
            navigate(`/member-analytics/retention-risk/cohort-upload-overview/${id}`);
        }
        else {
            navigate(`/member-analytics/retention-risk/risk-report/${id}`);
        }
    }

    const handleCohortMonitor = () => {
        navigate(`/member-analytics/retention-risk/risk-monitor`);
    }

    const isCohortViewDisable = ({ retentionRiskStatus }) => {
        return !(retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Scored || retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Error);
    }

    const isAnalyzeDisable = ({ retentionRiskStatus }) => {
        return !(retentionRiskStatus === null || retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Error || retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Empty);
    }
    const isExportDisable = ({ retentionRiskStatus }) => {
        return retentionRiskStatus !== COHORT_RETENTION_RISK_STATUS.Scored;
    }

    const handleArchiveConfirm = (id, name) => {
        setSelectedItem({ id, name });
        if (name === 'cohort') {
            setArchiveMessage(messages.confirm.deleteCohort);
        }
        else {
            setArchiveMessage(messages.confirm.deleteNotification);
        }
        setConfirmModal(true);
    }
    const handleConfirmClose = () => {
        setSelectedItem(null);
        setConfirmModal(false);
    }

    const handleExportConfirm = () => {
        setConfirmExportModal(true);
    }
    const handleExportConfirmClose = () => {
        setConfirmExportModal(false);
    }

    const handleNotificationViewConfirm = (id) => {
        const currObj = notifications.find((item) => item.id === id)
        setCurrentNotification({ ...currObj, type: NOIFICATION_TYPE[currObj.type] })
        setShowNotificationViewModal(true);
    }
    const handleNotificationViewConfirmClose = () => {
        setShowNotificationViewModal(false);
    }

    const handleExport = (value) => {
        const cohortData = cohort.filter((item) => item.id === Number(value.cohort));
        let columns = [["Id", "CompanyId", "UserId", "FileName", "Name", "Status", "Training", "Market Type", "Market",
            "Size", "DateTime", "RetentionRiskStatus", "RetentionRiskStatusDateTime", "RetentionRiskMonitoringStatus",
            "RetentionRiskMonitoringStatusDateTime"]];
        let fileName = `${toDateTime(new Date())}_${cohortData[0].name}`;

        let rows = [];
        cohortData.map((item) => {
            const { id, companyId, userId, fileName, name, status, training, marketType, market,
                size, dateTime, retentionRiskStatus, retentionRiskStatusDateTime,
                retentionRiskMonitoringStatus, retentionRiskMonitoringStatusDateTime } = item
            rows.push([id, companyId, userId, fileName, name, status, training, marketType, market,
                size, dateTime, retentionRiskStatus, retentionRiskStatusDateTime,
                retentionRiskMonitoringStatus, retentionRiskMonitoringStatusDateTime])
            return rows;
        }, [])

        exportData(cohortData, rows, columns, fileName, value.type);
    }

    const handleArchive = () => {
        if (selectedItem.name === 'cohort') {
            dispatch(archiveCohort(selectedItem.id, () => {
                showAlert(messages.success.deleteCohort, ALERT_TYPE.SUCCESS);
            }));
        }
        else {
            dispatch(archiveNotification({ id: selectedItem.id, status: NOTIFICATION_RECORD_STATUS.ARCHIVED }, () => {
                showAlert(messages.success.deleteNotification, ALERT_TYPE.SUCCESS);
            }));
        }
        setConfirmModal(false);
    }

    const defaultSort = [
        {
            id: 'dateTime',
            desc: true,
        },
    ];

    const NavContent = [
        {
            path: '/member-analytics/retention-risk/risk-report',
            locationName: 'Member Analytics',
            pageName: 'Risk Reports',
            icon: 'risk-reports',
            classname: 'gsApple',
            description:
                'View, sort, filter, and download information from a recent risk report',
        },
        {
            path: '/member-analytics/retention-risk/risk-explorer',
            locationName: 'Member Analytics',
            pageName: 'Risk Explorer',
            icon: 'risk-explorer',
            classname: 'purple',
            description:
                'Utilize a series of data visualizations to better understand the relationship between risk scores and population characteristics',
        },
        {
            path: '/member-analytics/retention-risk/risk-monitor',
            locationName: 'Member Analytics',
            pageName: 'Risk Monitor',
            icon: 'risk-monitoring',
            classname: 'darkSalmon',
            description:
                'Enroll and manage the activity of cohorts or select members in the risk monitoring program',
        },
        {
            path: '/member-analytics/retention-risk/risk-manager',
            locationName: 'Member Analytics',
            pageName: 'Risk Manager',
            icon: 'risk-manager',
            classname: 'blueLight',
            description:
                'Execute and manage structured engagement plans to recent risk reports',
        },
    ];

    return (
        <Row className="h-100 mb-4">
            <Col xs={12}>
                <ActionBar>
                    <Button type="button" className="me-2" variant="outline-primary"
                            onClick={() => navigate(`/member-analytics/retention-risk/archive/cohorts`)}>
                        View Archive
                    </Button>
                </ActionBar>
            </Col>
            <Col xl={4}>
                <NavigationBoxDashboard data={NavContent} />
            </Col>
            <Col xl={8}>
                <div className="h-100">
                    {filteredCohorts === null ? <Loader /> :
                        <DataGrid
                            columns={cohortColumns}
                            data={filteredCohorts}
                            enableFilters={false}
                            gridContainerClass="minH-100"
                            columnDisplay={hideColumn}
                            sort={defaultSort}
                        />
                    }
                </div>
            </Col>
            <div className="mt-4 pt-3">
                <div className={`${styles.tableHeader}`}>
                    <ActionBar>
                        <Button type="button" className="me-2" variant="outline-primary"
                            onClick={() =>
                                navigate(isUser(currentUser) ? `/system-notifications` : `/admin/system-notifications`)
                            }>
                            Notifications Home
                        </Button>
                    </ActionBar>
                    <div
                        className={`d-flex text-center ${styles.tableHeading}`}>
                        Processing Messages
                    </div>
                    {notifications === null ? <Loader /> :
                        <DataGrid
                            columns={columnsNotification}
                            data={notifications}
                            enableFilters={false}
                            sort={defaultSort}
                        />
                    }
                </div>
            </div>

            <ConfirmModal
                show={confirmModal}
                message={archiveMessage}
                onConfirm={handleArchive}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />

            <ExportModal
                show={confirmExportModal}
                onCancel={handleExportConfirmClose}
                onSubmit={handleExport}
                fields={arr}
                title="Retention Risk - Export File"
            />

            <RetentionRiskViewModal
                show={showNotificationViewModal}
                onCancel={handleNotificationViewConfirmClose}
                notification={currentNotification}
            />
        </Row>
    )
}

export default withAuth(RetentionRisk);
