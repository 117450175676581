import { acquireAccessToken } from '../services/api/auth'
import { msalInstance } from "../index";
import { ALERT_TYPE } from "../constants";

const refreshToken = async () => {
    const accessToken = await acquireAccessToken(msalInstance);
    return accessToken;
}

const addInterceptors = httpInstance => {
    httpInstance.interceptors.request.use(request => {
        return request;
    });

    httpInstance.interceptors.response.use(
        response => {
            const { data: { data } } = response;
            return {
                data,
                error: null,
            };
        },
        async error => {
            if (error.response) {
                const showAlert = httpInstance.getContext();
                switch (error.response.status) {
                    case 412:
                        break;
                    case 401:
                        {
                            const originalConfig = error.config;

                            // Access Token was expired
                            if (!originalConfig._retry) {
                                originalConfig._retry = true;

                                try {
                                    const accessToken = await refreshToken();

                                    httpInstance.defaults.headers = {
                                        ...httpInstance.defaults.headers,
                                        Authorization: `Bearer ${accessToken}`,
                                    };

                                    return httpInstance(originalConfig);
                                } catch (_error) {
                                    if (_error.response && _error.response.data) {
                                        return Promise.reject(_error.response.data);
                                    }

                                    return Promise.reject(_error);
                                }
                            }

                            return Promise.reject(error);
                        }
                    case 400:
                        {
                            if (error.response.data.message) {
                                showAlert(error.response.data.message, ALERT_TYPE.ERROR);
                                return {
                                    data: null,
                                    error: error.response.data.message,
                                };
                            }
                            return Promise.reject(error);
                        }
                    default:
                        return Promise.reject(error);
                }
            }

            return Promise.reject(error);
        }
    );

    return httpInstance;
};

export default addInterceptors;
