import { useDispatch, useSelector } from 'react-redux';
import React, { useContext, useState } from 'react';
import { ALERT_TYPE, ALIGN, POSITIONS, RECORD_STATUS_ABBR, VALIDATIONS } from '../../../../constants';
import messages from '../../../../utils/helper/messages';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../../components/ui/Form';
import { archiveMemberComment, getMemberComments, insertMemberComment } from '../../../../ducks/advisoryGroup';
import { AlertContext } from '../../../../context/AlertContext';
import Comment from '../../../../components/ui/Comment';

const SupportStaffCommentsModal = ({ show, meetingId, data, onCancel }) => {
    const { showAlert } = useContext(AlertContext);
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.user?.currentUser);
    const comments = useSelector((state) => state.advisoryGroup?.memberCommentList);

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [commentsData, setCommentsData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = (formData) => {
        const commentData = {
            meetingId: meetingId,
            companyId: currentUser.companyId,
            userId: currentUser.id,
            comment: formData.comment,
        }
        if (formData.comment) {
            setIsLoading(true);
            dispatch(insertMemberComment(commentData, handleSuccess, handleError));
        }
    }

    const handleCancel = () => {
        setShowCommentBox(false);
        onCancel();
        setCommentsData(null);
    };

    const handleSuccess = () => {
        setIsLoading(false);
        onCancel();
        setCommentsData(null);
        setShowCommentBox(false);
        showAlert(messages.success.addSupportStaffComment, ALERT_TYPE.SUCCESS);
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error?.response?.data?.error || error}.`, ALERT_TYPE.ERROR);
    }

    const handleAddComment = () => setShowCommentBox(true);

    const handleDelete = (id) => {
        const deleteCommentData = {
            id: id,
            status: RECORD_STATUS_ABBR.Archived,
        };
        dispatch(archiveMemberComment(deleteCommentData, handleDeleteSuccess, handleDeleteError));
    };

    const handleDeleteSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.deleteOutreachComment, ALERT_TYPE.SUCCESS);
        dispatch(getMemberComments(data.id));
    };

    const handleDeleteError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const arr = [
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        },
    ];

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data?.comment}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        loading={isLoading}
                        disabled={!showCommentBox || commentsData?.comment === ''}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                    <Comment
                        comments={comments}
                        onAddComment={handleAddComment}
                        commentBox={showCommentBox}
                        onDelete={handleDelete}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default SupportStaffCommentsModal;
