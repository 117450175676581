import { useContext, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Row, Col } from 'react-bootstrap';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { BiMerge } from 'react-icons/bi';
import withAuth from '../../components/hoc/withAuth';
import ActionBar from '../../components/ui/ActionBar';
import LoadingButton from "../../components/ui/LoadingButton";
import { getCohorts } from '../../ducks/cohort';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import { ALERT_TYPE, COHORT_FILE_STATUS } from "../../constants";
import { checkWhiteSpace } from "../../utils/utils";
import messages from "../../utils/helper/messages";
import styles from './MergeCohort.module.scss';
import api from '../../services/api/cohortService';
import { AlertContext } from "../../context/AlertContext";

const CohortItem = ({ title, onCohortClick }) => {
    return (
        <div
            className={`${styles.cohortItem} cursor-pointer py-2 px-3 mb-3`}
            onClick={onCohortClick}>
            {title}
        </div>
    );
};

const ListBox = ({ title, items, onItemClick, noRecords }) => {
    return (
        <div className={`${styles.listBoxWrap} cohort-list-box flex-grow-1 d-flex flex-column h-100`}>
            <div className="small text-muted opacity-75 text-center mb-3">
                {title}
            </div>
            <ul className={`${styles.listBox} flex-grow-1 h-100 mb-0 overflow-y-auto`}>
                {
                    items?.map((cohort) => (
                        <li key={cohort.id} data-id={cohort.id}>
                            <CohortItem
                                title={cohort.name}
                                onCohortClick={() => onItemClick(cohort.id)}
                            />
                        </li>
                    ))
                }
                {noRecords &&
                    <li>
                        <div className={`${styles.cohortItem} ${styles.noRecord} text-muted cursor-default user-select-none text-center py-2 px-3 mb-3`}>
                            {messages.info.mergeCohortNoRecord}
                        </div>
                    </li>
                }
            </ul>
        </div>
    );
};

const MergeCohort = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const cohorts = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);
    const inputRef = useRef(null);

    const [mergedCohortName, setMergedCohortName] = useState('');
    const [selectedCohorts, setSelectedCohorts] = useState([]);
    const [cohortList, setCohortList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        const validatedCohorts = cohorts.filter(cohort => cohort.status === COHORT_FILE_STATUS.VALIDATED);
        setCohortList(validatedCohorts);
    }, [cohorts]);

    const handleCohortSelect = (id) => {
        let selectedCohort = cohortList.filter((cohort) => id === cohort.id);
        setSelectedCohorts((prevState) => [...prevState, ...selectedCohort]);

        let selectedEl = document.querySelector(`[data-id='${id}'] > div`);
        selectedEl.classList.add('active');
    };

    const handleCohortDeselect = (id) => {
        setSelectedCohorts((prevState) => [
            ...prevState.filter((cohort) => id !== cohort.id),
        ]);

        let deSelectedEl = document.querySelector(`[data-id='${id}'] > div`);
        deSelectedEl.classList.remove('active');
    };

    const isValid = () => {
        const hasWhiteSpace = checkWhiteSpace(mergedCohortName);

        return !(
            selectedCohorts.length === 0 ||
            mergedCohortName === "" ||
            selectedCohorts.length <= 1 ||
            hasWhiteSpace
        )
    }

    const handleMerge = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        try {
            const res = await api.mergeCohorts(
                {
                    companyId: currentUser?.companyId, uploaderUserId: currentUser?.id,
                    cohortName: mergedCohortName, originalFileNames: selectedCohorts.map(s => s.fileName),
                    cohortIds: selectedCohorts.map(s => s.id),
                    fileSize: selectedCohorts.reduce((a, v) => a = a + v.size, 0)
                });

            setIsLoading(false);

            if (res.error) {
                return;
            }

            showAlert(messages.success.mergeCohortMember, ALERT_TYPE.SUCCESS);

            setSelectedCohorts([]);
            navigate(-1)
        }
        catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }
    };

    return (
        <div className="d-flex flex-column h-100">
            <ActionBar title="Merge Cohorts">
                <Row className="justify-content-end w-75">
                    <Col lg={10} xl={8} xxl={6} className="d-flex">
                        <Button type="button" variant="light" onClick={() => navigate(-1)} className="flex-shrink-0">
                            <IoMdArrowRoundBack className="me-1" />Back
                        </Button>
                        <div className="vr mx-3" />
                        <div className="me-2 w-100">
                            <Form.Control
                                type="text"
                                placeholder="Merged Cohort Name"
                                ref={inputRef}
                                value={mergedCohortName}
                                onChange={(e) => setMergedCohortName(e.target.value)}
                            />
                        </div>
                        <LoadingButton
                            type="submit"
                            className="flex-shrink-0"
                            disabled={!isValid() || isLoading}
                            loading={isLoading}
                            onClick={handleMerge}
                        >
                            <BiMerge size={14} className="me-1" />Merge
                        </LoadingButton>
                    </Col>
                </Row>
            </ActionBar>
            <Row className={`${styles.contentWrap} flex-grow-1 h-100`}>
                <Col md className="d-flex flex-column h-100">
                    <ListBox
                        title="Click on the cohort to move to selection"
                        items={cohortList}
                        onItemClick={handleCohortSelect}
                        noRecords={cohortList?.length === 0}
                    />
                </Col>
                <Col md className="d-flex flex-column h-100">
                    <ListBox
                        title="Click on the cohort to remove from selection"
                        items={selectedCohorts}
                        onItemClick={handleCohortDeselect}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default withAuth(MergeCohort);
