import Loader from "../../../../components/ui/Loader";
import { toDecimal } from "../../../../utils/utils";
import styles from "./PanelScore.module.scss";

const PanelScore = ({label, score, loading}) => (
    <div className={`${styles.score} position-relative d-flex align-items-center`}>
        <span className="me-2">{label}:</span>
        <span className={`fw-bold ${styles.scoreValue}`}>
            {loading ? <Loader size={2} /> : score ? (
                toDecimal(score)
            ) : (
                <span className="text-muted">{'NA'}</span>
            )}
        </span>
    </div>
);

export default PanelScore;
