function ErrorRedirect() {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center p-3 p-md-5">
                <h1 className="fw-bold">There was an issue with your account.</h1>
                <div className="h5">Please contact your administrator to resolve this issue.</div>
            </div>
        </div>
    );
}

export default ErrorRedirect;
