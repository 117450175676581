import React, { useState, useEffect } from 'react';
import { useLocation, useMatches, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { Breadcrumb, Button } from "react-bootstrap";
import { AiFillHome } from 'react-icons/ai';
import { setLocation } from '../../ducks/navigation';
import menuData from './menuData';
import styles from './BreadCrumbs.module.scss';

function BreadCrumbs() {
    let matches = useMatches();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [crumbs, setCrumbs] = useState([]);
    const [showBack, setShowBack] = useState(false);

    useEffect(() => {
        const hasBack = matches.find(match => (match.pathname === location.pathname) && match.handle?.back);
        setShowBack(hasBack);
    }, [location.pathname]);

    useEffect(() => {
        let allCrumb = matches
            .filter((match) => Boolean(match.handle?.crumb))
            .map((match) => {
                const label = match.handle.crumb(match.data);
                return { label, path: match.pathname };
            });
        setCrumbs(allCrumb);
    }, [matches]);

    const handleClick = (e, url) => {
        e.preventDefault();
        const { parentLabel, currentPageLabel } = findPropertiesForRoute(
            url,
            menuData
        );

        dispatch(
            setLocation({
                path: url,
                name: parentLabel,
                page: currentPageLabel,
            })
        );
        navigate(url);
    };

    function findPropertiesForRoute(url, items) {
        let parentLabel = '';
        let currentPageLabel = '';
        const pathArray = url.split('/').filter(Boolean);

        // scan the nested children and match the route
        function findRecursive(items, currentPath = []) {
            const currentElement = pathArray[currentPath.length];
            for (const item of items) {
                if (item.to === '/' + currentElement) {
                    currentPageLabel = item.label;
                    if (item.parentLabel) {
                        parentLabel = item.parentLabel;
                    }
                    if (currentPath.length === pathArray.length - 1) {
                        // Return properties when we reach the end of the path
                        return {
                            currentPageLabel,
                            parentLabel,
                        };
                    } else if (
                        item.children &&
                        currentPath.length < pathArray.length
                    ) {
                        // Continue searching if there are more levels in the path
                        const properties = findRecursive(item.children, [
                            ...currentPath,
                            currentElement,
                        ]);
                        if (properties) {
                            return properties;
                        }
                    }
                }
            }
            return null;
        }

        return findRecursive(items);
    }

    const renderBreadCrumbItems = () => {
        let breadCrumbItems = crumbs.map((crumb, index) => {
            let currentLink = crumb.path;
            if (index === crumbs.length - 1) {
                currentLink = '';
            }
            return (
                <Breadcrumb.Item
                    active={currentLink === '' ? true : false}
                    key={index}
                    href={currentLink}
                    onClick={(e) => {
                        handleClick(e, currentLink);
                    }}>
                    {crumb.label}
                </Breadcrumb.Item>
            );
        });
        return breadCrumbItems;
    };

    return (
        <div
            className={`d-flex justify-content-between pt-3 ${styles.breadcrumbWrap}`}>
            {crumbs && crumbs.length > 0 && (
                <Breadcrumb className={`${styles.breadcrumb}`}>
                    <Breadcrumb.Item href="/">
                        <AiFillHome />
                    </Breadcrumb.Item>
                    {renderBreadCrumbItems()}
                </Breadcrumb>
            )}
            {showBack && (
                <Button
                    size="sm"
                    variant="link"
                    className="py-0 border-0 lh-sm"
                    onClick={() => navigate(-1)}>
                    Back
                </Button>
            )}
        </div>
    );
}

export default BreadCrumbs;
