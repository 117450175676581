import { useContext, Suspense, lazy } from 'react';
import { AlertContext } from '../../context/AlertContext';
import { ALERT_TYPE } from '../../constants';

const PdfLazy = lazy(() => import('./PdfLazy'));

const PdfViewer = (props) => {
    const { showAlert } = useContext(AlertContext);

    if (!props.file) {
        showAlert('File not found', ALERT_TYPE.ERROR);
    }

    return (
        <Suspense fallback={`Loading...`}>
            <PdfLazy {...props} />
        </Suspense>
    );
};

export default PdfViewer;
