import Modal from 'react-bootstrap/Modal';
import { ALERT_TYPE } from "../../constants";
import SuccessIcon from "./../../assets/images/success-icon.png";
import ErrorIcon from "./../../assets/images/error-icon.png";
import styles from './AlertModal.module.scss';

const AlertModal = ({ alert, onHide, onExited }) => {
    const Icon = alert.type === ALERT_TYPE.SUCCESS ? SuccessIcon : ErrorIcon;

    return (
        <Modal
            show={alert.show}
            fullscreen={false}
            onHide={onHide}
            onExited={onExited}
            dialogClassName={styles.alert}
            centered>
            <Modal.Body className="text-center">
                <div className="mb-2">
                    <img src={Icon} alt={alert.type} />
                </div>
                <span className={`${styles.message} fw-bold lh-sm text-break`}>
                    {alert.message}
                </span>
            </Modal.Body>
        </Modal>
    );
}

export default AlertModal;
