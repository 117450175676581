import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Form, Button } from 'react-bootstrap';
import Loader from '../../../../components/ui/Loader';
import DataGrid from '../../../../components/DataGrid';
import LoadingButton from '../../../../components/ui/LoadingButton';
import ConfirmModal from '../../../../components/ui/ConfirmModal';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { getSupportStaffAssignedList, getSupportStaffForAssignment } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';
import api from '../../../../services/api/advisoryGroupService';
import {
    AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS, AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST,
    AG_MEETING_SUPPORT_STAFF_RECORD_STATUS, ALERT_TYPE,
} from '../../../../constants';
import { AlertContext } from '../../../../context/AlertContext';
import styles from './AssignSupportStaffModal.module.scss';


const AssignSupportStaffModal = ({ show, locations, info, onCancel }) => {
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const dispatch = useDispatch();

    const currentUser = useSelector((state) => state.user.currentUser);
    const supportStaff = useSelector((state) => state.advisoryGroup.supportStaffForAssignmentList);

    const [checkedIds, setCheckedIds] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [locationData, setLocationData] = useState([]);

    const rowActions = ({ row }) => {
        const rowId = row.original.id;
        return (
            <label key={rowId} htmlFor={`input-${rowId}`}>
                <input
                    id={`input-${rowId}`}
                    type="checkbox"
                    checked={checkedIds.includes(rowId)}
                    onChange={(e) => handleCheckboxChange(rowId, e)}
                />
            </label>
        );
    };

    const LocationSelect = ({ row }) => {
        const rowId = row.original.id;

        const handleSelectionChange = (id, e) => {
            let inputValue = e.target.value;
            if (inputValue !== "") {
                const location = locationData.some(item => item.id === id);
                if (!location) {
                    const arr = [...locationData, { id: id, value: inputValue }];
                    setLocationData(arr);
                }
            } else {
                const arr = locationData.filter(item => item.id !== id);
                setLocationData(arr);
            }
        }

        return (
            <div
                className={styles.locationDropdown}
                key={`select-${rowId}`}>
                <Form.Select
                    value={locationData.find(item => item.id === rowId)?.value}
                    size="sm"
                    onChange={(e) => handleSelectionChange(rowId, e)}
                    disabled={!checkedIds.includes(rowId)}
                    required={checkedIds.includes(rowId)}
                    name={`locations-${rowId}`}
                >
                    {locationOptions?.map((location) => {
                        return (
                            <option
                                key={location.id}
                                value={location.id}>
                                {location.value}
                            </option>
                        );
                    })}
                </Form.Select>
            </div>
        );
    };

    const columns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            sortingFn: 'text',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            sortingFn: 'text',
        },
        {
            id: 'organization',
            header: 'Organization',
            accessorKey: 'organization',
            sortingFn: 'text',
            filterFn: 'equalsString',
        },
        {
            id: 'role',
            header: 'Role',
            accessorKey: 'role',
        },
        {
            id: 'assign',
            header: 'Assign?',
            cell: rowActions,
            size: 70,
            minSize: 70,
            enableSorting: false,
        },
        {
            id: 'location',
            header: 'Location?',
            cell: LocationSelect,
            size: 250,
        },
    ];

    const handleCheckboxChange = (staffId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(staffId)) {
                setCheckedIds((prevIds) => [...prevIds, staffId]);
            }
        } else if (checkedIds.includes(staffId)) {
            setCheckedIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== staffId)
            );
        }
    }

    const handleSubmit = () => {
        setIsLoading(true);

        const commonData = {
            agId: info.agId,
            meetingId: info.meetingId,
            companyId: currentUser.companyId,
            status: AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS.Assigned,
            statusPost: AG_MEETING_SUPPORT_STAFF_ATTENDANCE_STATUS_POST.NA,
            recordStatus: AG_MEETING_SUPPORT_STAFF_RECORD_STATUS.Operational
        };
        let validData = true;
        const submitData = checkedIds.map(checkedId => {
            const location = locationData.find(staff => staff.id === checkedId);
            if (!location) {
                validData = false;
            }
            return {
                ...commonData,
                supportStaffId: checkedId,
                locationId: location ? location.value : null,
            }
        });
        if (validData === false) {
            setIsLoading(false);
            showAlert(`${messages.error.locationAssign}`, ALERT_TYPE.ERROR);
            return;
        }

        api.CreateSupportStaffAttendanceLog(submitData)
            .then(() => {
                setIsLoading(false);
                showAlert(messages.success.supportStaffAssign, ALERT_TYPE.SUCCESS);
                dispatch(getSupportStaffForAssignment(currentUser.companyId, info.meetingId));
                dispatch(getSupportStaffAssignedList(info.meetingId));
                onCancel();
            })
            .catch(error => {
                setIsLoading(false);
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            })
    }

    const resetAssignData = () => {
        setLocationData([]);
        setCheckedIds([]);
    }

    const handleCancel = () => {
        if (checkedIds.length > 0 || locationData.length > 0) {
            setConfirmModal(true);
        } else {
            onCancel();
        }
    }

    const handleConfirm = () => {
        resetAssignData();
        handleConfirmClose();
        onCancel();
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
    }

    const isDataUpdated = () => {
        let updated = false;

        // check if at least one support staff is assigned with location selected
        // if location is not selected for rest, error message gets displayed on submit action
        if (checkedIds.length > 0 && locationData.length > 0) {
            const staffWithLocation = checkedIds.map((checkedId) => {
                const location = locationData.find(
                    (staff) => staff.id === checkedId
                );
                return !!location;
            });
            updated = staffWithLocation.some(loc => loc === true);
        }

        return updated;
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getSupportStaffForAssignment(currentUser.companyId, info.meetingId));
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (locations && locations.length > 0) {
            let locationData = locations.map(location => ({ id: location.id, value: location.name }));
            setLocationOptions([
                { id: '', value: 'Select Location' },
                ...locationData
            ]);
        } else if (locations?.length === 0) {
            setLocationOptions([{ id: '', value: 'No locations available' }])
        }
    }, [locations]);

    return (
        <>
            <Modal
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onCancel}
                size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Assign Support Staff</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="p-xl-3">
                        <div className="d-flex justify-content-end gap-2 mb-3">
                            <Button variant="outline-primary" onClick={handleCancel}>Cancel</Button>
                            <LoadingButton
                                loading={isLoading}
                                disabled={isLoading || !isDataUpdated()}
                                onClick={handleSubmit}>
                                Save
                            </LoadingButton>
                        </div>
                        {supportStaff === null ? <Loader /> :
                            <DataGrid
                                columns={columns}
                                data={supportStaff}
                                enableFilters={false}
                            />
                        }
                    </div>
                </Modal.Body>
            </Modal>

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.discardSupportStaffAssign}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </>
    );
}

export default AssignSupportStaffModal;
