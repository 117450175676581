import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
import companyReducer from './company'
import userReducer from './user'
import navigationReducer from './navigation'
import reportReducer from './report'
import cohortReducer from './cohort'
import qualityManagementReducer from "./quality-management";
import eacParticipationResponsesReducer from "./eacParticipationResponses";
import contentReducer from "./content";
import advisoryGroupReducer from "./advisoryGroup";
import programManagementReducer from "./programManagement";

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))



const reducers = combineReducers({
    company: companyReducer,
    user: userReducer,
    content: contentReducer,
    location: navigationReducer,
    report: reportReducer,
    cohort: cohortReducer,
    qualityManagement: qualityManagementReducer,
    eacParticipationResponses: eacParticipationResponsesReducer,
    advisoryGroup: advisoryGroupReducer,
    programManagement: programManagementReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'CLEAR_STORE') {
        console.log('clearing data')
        return reducers(undefined, action);
    }

    return reducers(state, action);
};

const store = createStore(rootReducer, composedEnhancer);

export default store;
