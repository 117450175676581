import Report from '../../starExplorer/report';
import Placeholder from '../../../components/ui/Placeholder';
import RiskActionbar from "./RiskActionbar";

const RiskPowerBi = ({ reportConfig, title }) => {
    return (
        <div className="h-100 d-flex flex-column">
            <RiskActionbar />
            <div className="embed-wrapper h-100">
                {reportConfig ? (
                    <Report {...reportConfig} />
                ) : (
                    <Placeholder title={`Power BI - ${title}`} />
                )}
            </div>
        </div>
    );
};

export default RiskPowerBi;
