import {PROGRAM_STATUS} from '../../constants'

export function convertToAdvisoryGroupMasterModelDataObject(programData, id = null) {
    const formData = new FormData();

    formData.append("companyId", programData.companyId);
    formData.append("userId", programData.userId);
    formData.append("name", programData.name);
    formData.append("type", programData.type);
    formData.append("shortName", programData.shortName);
    formData.append("projectManagerId", programData.projectManagerId);
    formData.append("status", id === null ? PROGRAM_STATUS.New : programData.status);
    formData.append("cohortId", programData.cohortId);
    formData.append("targetSize", programData.targetSize);
    formData.append("market", programData.market);
    formData.append("meetingFrequency", programData.meetingFrequency);
    formData.append("meetingType", programData.meetingType);
    formData.append("popCharCore", programData.popCharCore);
    formData.append("popCharLanguage", programData.popCharLanguage);
    formData.append("popCharGeoDispersion", programData.popCharGeoDispersion);
    formData.append("popCharHealthAccess", programData.popCharHealthAccess);
    formData.append("eacPlanName", programData.type === 'E' ? programData.eacPlanName : '');
    formData.append("eacPlanContract", programData.type === 'E' ? programData.eacPlanContract : '');
    formData.append("eacPlanPDBId", programData.type === 'E' ? programData.eacPlanPDBId : '');

    if (id != null) {
        formData.append("id", programData.id);
    }
    return formData;
}
