import withAuth from '../../../components/hoc/withAuth';
import RiskPowerBi from "./RiskPowerBi";

const FinancialIndex = () => {
    return (
        <RiskPowerBi
            title="Financial Index"
        />
    );
};

export default withAuth(FinancialIndex);
