import React, { useContext, useEffect, useState } from "react";
import { useMsal } from '@azure/msal-react';
import { protectedResources } from '../../../msalAuthConfig';
import { acquireAccessToken } from '../../../services/api/auth';
import addInterceptors from '../../../utils/interceptors';
import createInstance from '../../../utils/instance';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers, getCurrentUser } from '../../../ducks/user';
import { ALERT_TYPE, USER_ROLE, USER_STATUS } from "../../../constants";
import DataGrid from '../../../components/DataGrid';
import Loader from '../../../components/ui/Loader';
import withAuth from '../../../components/hoc/withAuth';
import { UserProfileModal } from '../../../components/ui/UserProfileModal';
import { formatPhoneNumber, checkObjectsEqual } from '../../../utils/utils';
import messages from '../../../utils/helper/messages';
import ActionBar from '../../../components/ui/ActionBar';
import ConfirmModal from '../../../components/ui/ConfirmModal';
import OverflowText from '../../../components/ui/OverflowTooltip';
import Icon from '../../../components/ui/Icon';
import AddUserModal from './AddUserModal';
import GridButton from '../../../components/ui/GridButton';
import NewButton from "../../../components/ui/NewButton";
import { AlertContext } from "../../../context/AlertContext";

export const ApiInstance = addInterceptors(createInstance());

export const UsersData = (props) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const { showAlert } = useContext(AlertContext);

    const [showModal, setShowModal] = useState(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userInfoOriginal, setUserInfoOriginal] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmMessage, setConfirmMessage] = useState('');
    const [info, setInfo] = useState(null);
    const [archive, setArchive] = useState(null);
    const [isDirty, setIsDirty] = useState(false);

    const currentUser = useSelector((state) => state.user.currentUser);

    const sendInvite = async (userInfo) => {
        try {
            let accessToken = await acquireAccessToken(instance);
            ApiInstance.setAuth(accessToken);
            ApiInstance.setContext(showAlert);
            await ApiInstance({
                method: 'POST',
                url: protectedResources.apiUserInvite.endpoint,
                data: {
                    inviteUserId: userInfo.id,
                },
            });

            showAlert(messages.success.inviteUser, ALERT_TYPE.SUCCESS);
        } catch (error) {
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setConfirmMessage('');
        // reset row info
        setInfo(null);
        setArchive(null);
    };

    const handleConfirm = async () => {
        await toggleArchiveUser(info, archive);
        handleConfirmClose();
    };

    const handleToggleArchive = (userInfo, isArchive) => {
        setInfo(userInfo);
        setArchive(isArchive);
        setConfirmMessage(
            isArchive
                ? messages.confirm.archiveUser
                : messages.confirm.unarchiveUser
        );
        setConfirmModal(true);
    };

    const toggleArchiveUser = async (userInfo, isArchive) => {
        let endpointURL = isArchive
            ? protectedResources.apiUserArchive.endpoint
            : protectedResources.apiUserUnarchive.endpoint;

        let successMessage = isArchive
            ? messages.success.archiveUser
            : messages.success.unarchiveUser;

        try {
            let accessToken = await acquireAccessToken(instance);

            ApiInstance.setAuth(accessToken);
            ApiInstance.setContext(showAlert);
            await ApiInstance({
                method: 'POST',
                url: endpointURL,
                data: {
                    archiveUserId: userInfo.id,
                },
            });

            // fetch and refresh users
            dispatch(getUsers(instance));
            showAlert(successMessage, ALERT_TYPE.SUCCESS);
        } catch (error) {
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }
    };

    const editUserButtonTapped = (userInfo) => {
        // prompt modal
        setShowModal(true);
        setSelectedUser(userInfo);
        setUserInfoOriginal(userInfo);
    };

    const handleAddUserButtonClick = () => {
        setShowAddUserModal(true);
    };

    const onHideCallback = () => {
        setShowModal(false);
        setSelectedUser(null);
        setUserInfoOriginal(null);
        setIsDirty(false);
    };
    const onAddUserCancel = () => {
        setShowAddUserModal(false);
    };

    const onFormSubmitCallback = (user) => {
        setSelectedUser(user);
    };

    const inputChanged = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        setSelectedUser({
            ...selectedUser,
            [name]: value,
        });

        if (checkObjectsEqual(userInfoOriginal, selectedUser, name) && userInfoOriginal[name] == value) {
            setIsDirty(false);
        }
        else {
            setIsDirty(true);
        }
    };

    const displayUserStatus = (status) => USER_STATUS[status];

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                {row.original.id !== currentUser?.id && (
                    <>
                        <GridButton
                            variant="light"
                            size="sm"
                            className="me-1 my-1"
                            title="Re-send Invite?"
                            onClick={() => sendInvite(row.original)}>
                            <Icon
                                icon="resend"
                                size={16}
                            />
                        </GridButton>
                        {renderArchiveSection(row.original)}
                    </>
                )}
                {currentUser?.roleId === USER_ROLE.SYSTEM_ADMIN ? (
                    <GridButton
                        variant="light"
                        size="sm"
                        className="me-1 my-1"
                        title="Edit"
                        onClick={() => editUserButtonTapped(row.original)}>
                        <Icon
                            icon="edit"
                            size={16}
                        />
                    </GridButton>
                ) : null}
            </div>
        );
    };

    const columns = [
        {
            id: 'companyName',
            header: 'Company',
            accessorKey: 'companyName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.companyName}
                </OverflowText>
            ),
            maxSize: 200,
            sortingFn: 'text'
        },
        {
            id: 'displayName',
            header: 'Display Name',
            accessorKey: 'firstName',
            cell: ({ row, cell }) => {
                let name = `${row.original.firstName} ${row.original.lastName}`;
                return (<OverflowText id={cell.id}>{name}</OverflowText>)
            },
            maxSize: 200,
            sortingFn: 'text'
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.email}</OverflowText>
            ),
            sortingFn: 'text'
        },
        {
            id: 'phone',
            header: 'Phone',
            accessorKey: 'phone',
            cell: ({ row }) => (
                <div className="text-nowrap">
                    {formatPhoneNumber(row.original.phone)}
                </div>
            ),
            size: 120,
        },
        {
            id: 'roleName',
            header: 'Role',
            accessorKey: 'roleName',
            size: 120,
            sortingFn: 'text'
        },
        {
            id: 'recordStatus',
            header: 'Status',
            accessorFn: row => 'recordStatus' in row ? displayUserStatus(row['recordStatus']) : null,
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: rowActions,
            size: 120,
        },
    ];

    const renderArchiveSection = (userData) => {
        if ('recordStatus' in userData && userData['recordStatus'] === 'A') {
            return (
                <>
                    <GridButton
                        variant="light"
                        size="sm"
                        className="me-1 my-1"
                        title="Unarchive user"
                        onClick={() => handleToggleArchive(userData, false)}>
                        <Icon
                            icon="archive"
                            size={16}
                        />
                    </GridButton>
                </>
            );
        } else {
            return (
                <>
                    <GridButton
                        variant="light"
                        size="sm"
                        className="me-1 my-1"
                        title="Archive user"
                        onClick={() => handleToggleArchive(userData, true)}>
                        <Icon
                            icon="archive"
                            size={16}
                        />
                    </GridButton>
                </>
            );
        }
    };

    return (
        <>
            <ActionBar>
                <NewButton
                    label="Register User"
                    onClick={() => handleAddUserButtonClick()}
                />
            </ActionBar>
            <DataGrid
                columns={columns}
                data={props.usersData}
                enableFilters={false}
            />

            {showModal && (
                <UserProfileModal
                    showModal={showModal}
                    selectedUser={selectedUser}
                    isDirty={isDirty}
                    inputChanged={inputChanged}
                    onHideCallback={onHideCallback}
                    onFormSubmitCallback={onFormSubmitCallback}
                />
            )}

            {showAddUserModal && (
                <AddUserModal
                    show={showAddUserModal}
                    onCancel={onAddUserCancel}
                />
            )}

            <ConfirmModal
                show={confirmModal}
                message={confirmMessage}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </>
    );
};

const UserManagement = () => {
    /**
     * useMsal is hook that returns the PublicClientApplication instance,
     * an array of all accounts currently signed in and an inProgress value
     * that tells you what msal is currently doing. For more, visit:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md
     */
    const { instance, inProgress } = useMsal();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const users = useSelector((state) => state.user.users);

    useEffect(() => {
        async function fetchData() {
            if (inProgress === 'none') {
                setIsLoading(true);

                await dispatch(getCurrentUser(instance));

                await dispatch(getUsers(instance));

                setIsLoading(false);
            }
        }

        fetchData();
    }, [dispatch, inProgress, instance]);

    if (isLoading) {
        return <Loader />;
    }

    return <>{users?.length > 0 ? <UsersData usersData={users} /> : null}</>;
};

export default withAuth(UserManagement);
