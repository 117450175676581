import React from 'react';
import withAuth from '../../../components/hoc/withAuth';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../components/ui/Form';
import { ALIGN, POSITIONS } from '../../../constants';

const CohortMemberIdModal = ({ show, data, onCancel }) => {

    const arr = [
        {
            key: 'id',
            labelName: 'Cohort Member ID',
            required: false
        },
    ]

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Cohort Member ID</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SharedForm
                    array={arr}
                    modelObj={data}
                    onCancel={onCancel}
                    cols={12}
                    actionBarPosition={POSITIONS.TOP}
                    actionBarAlign={ALIGN.RIGHT}
                    viewOnly={true}
                />
            </Modal.Body>
        </Modal>
    );

};

export default withAuth(CohortMemberIdModal);