import React from "react";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../components/ui/Form";
import withAuth from "../../../components/hoc/withAuth";
import {
    ALIGN,
    POSITIONS,
} from '../../../constants';

const RetentionRiskViewModal = ({ show, onCancel, notification }) => {

    const arr = [
        {
            key: 'dateTime',
            labelName: 'Date/Time',
            type: 'date'
        },
        {
            key: 'type',
            labelName: 'Type',
            type: 'text'
        },
        {
            key: 'CohortName',
            labelName: 'Cohort Name',
            type: 'text'
        },
        {
            key: 'message',
            labelName: 'Message Text',
            type: 'text'
        }
    ];

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Notification - View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={notification}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        viewOnly={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withAuth(RetentionRiskViewModal);
