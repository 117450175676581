import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        SET_CURRENT_LOCATION: null,
    },
    'Location'
);

// Action Creators

export const setLocation = (data) => dispatch => {
    dispatch({
        type: actionTypes.SET_CURRENT_LOCATION,
        payload: data,
    });
}

// Reducer

export const initialState = {
    currentLocation: {
        path: '/',
        name: localStorage.getItem('mainNavItem') ?? 'Home',
        page: localStorage.getItem('pageName') ?? 'Home',
    },
};

export default function navigationReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.SET_CURRENT_LOCATION:
            {
                localStorage.setItem('mainNavItem', payload.name);
                localStorage.setItem('pageName', payload.page);
                return {
                    ...state,
                    currentLocation: payload,
                }
            }
        default:
            return state;
    }
};
