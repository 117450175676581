import Placeholder from "../../components/ui/Placeholder";
import withAuth from "../../components/hoc/withAuth";

const PlanDesignFitHome = () => {
    return (
        <div className="flex-grow-1">
            <Placeholder
                title="The Plan Design and Fit application will allow business analysts to explore the impact of new benefits on expected plan performance based on predict consumer uptake and preference."
                type="graphic"
            />
        </div>
    );
}

export default withAuth(PlanDesignFitHome);
