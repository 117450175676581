import withAuth from '../../../components/hoc/withAuth';
import RiskPowerBi from "./RiskPowerBi";

const HealthAccess = () => {
    return (
        <RiskPowerBi
            title="Health Access"
        />
    );
};

export default withAuth(HealthAccess);
