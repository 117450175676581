import api from '../../services/api/microsoftFormService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_FORM_RESPONSES: null,
        CREATE_RESPONSE_PARAMS: null,
    },
    'EacParticipationResponses'
);

// Action Creators

export const getEacParticipationResponses = (companyId) => dispatch => {
    api.GetEacParticipationResponses(companyId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_FORM_RESPONSES,
            payload: data,
        });
    });
}

export const createEACResponseParams = (data) => dispatch => {
    api.CreateEACResponseParams(data).then(() => {
        dispatch({
            type: actionTypes.CREATE_RESPONSE_PARAMS
        });
    });
}

// Reducer

export const initialState = {
    responses: null
};

export default function eacParticipationResponsesReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_FORM_RESPONSES:
            return {
                ...state,
                responses: payload,
            };
        default:
            return state;
    }
};
