import keyMirror from '../../utils/keyMirror';
import api from "../../services/api/qualityManagementService";

export const actionTypes = keyMirror(
    {
        GET_STAR_PLANNER_SHINY_URL: null,
    },
    'QualityManagement'
);

export const getStarPlannerShinyURL = () => dispatch => {
    api.getStarPlannerShinyURL().then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_STAR_PLANNER_SHINY_URL,
            payload: data,
        });
    });
}

// Reducer

export const initialState = {
    starPlannerShinyURL: null,
};

export default function qualityManagementReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_STAR_PLANNER_SHINY_URL:
            return {
                ...state,
                starPlannerShinyURL: payload,
            };
        default:
            return state;
    }
};

