import React from "react";
import withAuth from '../../components/hoc/withAuth';
import Placeholder from '../../components/ui/Placeholder';

const DocumentManagement = () => {
    return (
        <Placeholder title='Document Management' />
    );
};

export default withAuth(DocumentManagement);
