import React, { useContext, useEffect, useState } from "react";
import api from "../../services/api/contentService";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import styles from "./Blog.module.scss";
import { CMS_CONTENT_FORMAT, MIME_TYPES, CONTENT_TYPE, AUDIENCE_SEGMENT } from "../../constants";
import { Badge, Card } from "react-bootstrap";
import { sortByDate, toDateWithMonth } from "../../utils/utils";

const Blog = ({ customStyle, openFile }) => {
    const [productUpdatesdata, setProductUpdatesdata] = useState([]);

    const isAppLoaded = useContext(AppLoadContext);

    const handleClick = (blog) => {
        const { id, title, format } = blog;
        const type = MIME_TYPES[CMS_CONTENT_FORMAT[format]];
        const content = { id, title, type };
        openFile(content);
    };

    const BlogItem = (props) => {
        return (
            <div>
                <div className={styles.blogAudience}>
                    <Badge key={props.audience} className={`me-2 ${styles.autoWidth} ${styles.colorLightBlue}`}>{AUDIENCE_SEGMENT[props.audience]}</Badge>
                </div>
                <div>
                    <h4 className={`mb-1 ${styles.blogTitle}`} onClick={() => handleClick(props)}>
                        <span>{props.title}</span>
                    </h4>
                </div>
                <div className={`text-primary ${styles.autoWidth}`}>
                    {toDateWithMonth(props.date)}
                </div>
            </div>
        );
    }

    useEffect(() => {
        if (isAppLoaded) {
            api.GetContentRecordByType(CONTENT_TYPE.Blog).then((res) => {
                const content = res.data;
                setProductUpdatesdata(sortByDate(content, 'updatedDateTime'))
            });
        }
    }, [isAppLoaded]);

    return (
        <>
            <Card className={`${customStyle}`}>
                <Card.Body className="p-0 h-100 d-flex flex-column">
                    <h3 className={`text-center ${styles.blogHeader} flex-shrink-0 mb-0`}><strong>Predict Health Blog</strong></h3>
                    <div className="px-3 py-2 flex-grow-1 overflow-y-auto">
                        {
                            productUpdatesdata && productUpdatesdata.length > 0 && productUpdatesdata?.map(item =>
                                <Card className={`be-1 ps-3 pe-3 ${styles.blogCard}`} key={`blog-${item.id}`}>
                                    <Card.Body className={`${styles.borderBottom} px-0`}>
                                        <BlogItem
                                            title={item.title}
                                            date={item.updatedDateTime}
                                            audience={item.audienceUser}
                                            file={item.fileName}
                                            format={item.format}
                                            id={item.id}
                                        />
                                    </Card.Body>
                                </Card>)
                        }
                    </div>
                </Card.Body>
            </Card>
        </>
    );
}

export default Blog;
