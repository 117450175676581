import React, { useContext, useState } from "react";
import { Button, InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import { useDispatch } from "react-redux";
import CommentItem from './CommentItem';
import Icon from './Icon';
import { archiveMemberComment } from "../../ducks/advisoryGroup";
import { AlertContext } from "../../context/AlertContext";
import { ALERT_TYPE, RECORD_STATUS_ABBR, VALIDATIONS } from "../../constants";
import { checkValid, toDate } from "../../utils/utils";
import messages from "../../utils/helper/messages";
import styles from './CommentList.module.scss';

const CommentList = ({ title, comments, maxLength, customListStyle, onCommentChange, onCommentSave, onCommentCancel, onCommentDelete }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const [commentBox, setCommentBox] = useState(false);
    const [error, setError] = useState(null);
    const [comment, setComment] = useState('');

    const handleCommentChange = (e) => {
        let { name, value } = e.target;
        setComment(value);

        if (onCommentChange) {
            onCommentChange(name, value);
            isCommentValid(value);
        }
    }

    const handleCommentSave = () => {
        setComment('');
        setCommentBox(false);
        if (onCommentSave) {
            onCommentSave();
        }
    }

    const handleCommentCancel = () => {
        setComment('');
        setCommentBox(false);
        if (onCommentCancel) {
            onCommentCancel();
        }
    }

    const isCommentValid = (value) => {
        const validityCheck = value && checkValid(value, VALIDATIONS.String.noSpecialChars);
        if (!validityCheck.valid) {
            setError({
                comment: validityCheck.error
            });
            return false;
        } else {
            setError(null);
            return true
        }
    }

    const handleSuccess = () => {
        showAlert(messages.success.deleteMemberComment, ALERT_TYPE.SUCCESS);
        if (onCommentDelete) {
            onCommentDelete();
        }
    }

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleDelete = (id) => {
        const comment = {
            id: id,
            status: RECORD_STATUS_ABBR.Archived,
        };
        dispatch(archiveMemberComment(comment, handleSuccess, handleError));
    }

    return (
        <>
            <div className="d-flex align-items-center justify-content-between">
                {title && title}
                {!commentBox ? (
                    <Button
                        variant="link"
                        className="text-secondary text-decoration-none d-flex align-items-center px-0"
                        onClick={() => setCommentBox(true)}>
                        <Icon
                            icon="new"
                            size={10}
                            className="me-2"
                        />
                        Add Comment
                    </Button>
                ) : (
                    <div className="d-flex gap-2">
                        <Button
                            variant="link"
                            className="text-secondary text-decoration-none d-flex align-items-center px-0"
                            disabled={!!error}
                            onClick={handleCommentSave}>
                            Save
                        </Button>
                        <Button
                            variant="link"
                            className="text-secondary text-decoration-none d-flex align-items-center px-0"
                            onClick={handleCommentCancel}>
                            Cancel
                        </Button>
                    </div>
                )}
            </div>
            {commentBox && (
                <div className="mb-2">
                    <InputGroup hasValidation>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Add comment"
                            required={true}
                            value={comment}
                            onChange={handleCommentChange}
                            name="comment"
                            isInvalid={error?.comment}
                            maxLength={maxLength || null}
                        />
                        <Form.Control.Feedback type="invalid">
                            {error?.comment}
                        </Form.Control.Feedback>
                    </InputGroup>
                </div>
            )}
            <div
                className={`overflow-y-auto ${styles.commentBox} ${
                    customListStyle ? customListStyle : ''
                } ${commentBox ? styles.open : ''}`}>
                <ul className={`list-unstyled mb-0 ${styles.commentList}`}>
                    {comments?.map((comment) => (
                        <CommentItem
                            key={comment.id}
                            id={comment.id}
                            date={toDate(comment.recordCreated)}
                            userName={`${comment.firstName} ${comment.lastName}`}
                            onDelete={onCommentDelete ? handleDelete : null}>
                            {comment.comment}
                        </CommentItem>
                    ))}
                    {!comments ||
                        (comments.length === 0 && (
                            <li className="text-muted px-3 py-2">
                                No comments
                            </li>
                        ))}
                </ul>
            </div>
        </>
    );
};

export default CommentList;
