import { Card, Col, Row } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import styles from './InfoCard.module.scss';

const InfoCard = ({ title, icon, link, children }) => {
    const Icon = icon;
    let pageLink = '/',
        disabledClasses = '';

    if (link) {
        pageLink = link;
    } else {
        disabledClasses = 'opacity-50 pe-none user-select-none';
    }

    return (
        <Card className={`${styles.infoCard} border-0 mb-4`}>
            <Card.Body>
                <Row>
                    <Col md={4} className="px-md-0">
                        <NavLink to={pageLink} className={`${styles.infoCardLink} d-flex align-items-center px-3 mb-2 ${disabledClasses}`}>
                            <Icon className={`${styles.infoCardLinkIcon} flex-shrink-0`}/>
                            <h4 className={`fw-bold mb-0 ms-3`}>{ title }</h4>
                        </NavLink>
                    </Col>
                    <Col md={8}>
                        { children }
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    )
}

export default InfoCard;
