import React, { useState, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from "react-bootstrap";
import DataGrid from '../../../../components/DataGrid';
import Loader from '../../../../components/ui/Loader';
import NewButton from '../../../../components/ui/NewButton';
import ActionBar from '../../../../components/ui/ActionBar';
import AddCaregiverModal from './AddCaregiverModal';
import OverflowText from '../../../../components/ui/OverflowTooltip';
import GridButton from '../../../../components/ui/GridButton';
import Icon from '../../../../components/ui/Icon';
import withAuth from '../../../../components/hoc/withAuth';
import { sortingFnDateOnly, sortingFnText, toDate } from '../../../../utils/utils';
import messages from '../../../../utils/helper/messages';
import ConfirmModal from '../../../../components/ui/ConfirmModal';
import { ALERT_TYPE, CARE_GIVER_MEMBER_STATUS, FORM_ACTION_TYPE } from '../../../../constants';
import { AlertContext } from '../../../../context/AlertContext';
import api from '../../../../services/api/advisoryGroupService';
import { getCaregiverDetailsByMemberId } from '../../../../ducks/advisoryGroup';



const CaregiverManagementModal = ({ show, onCancel, data }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const caregiverDetails = useSelector(state => state.advisoryGroup?.caregiverDetails);

    const [newCaregiverModal, setNewCaregiverModal] = useState(false);
    const [checkedIds, setCheckedIds] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);
    const [selectedCaregiverDetails, setSelectedCaregiverDetails] = useState(null);
    const [action, setAction] = useState(null);

    const defaultSort = [
        {
            id: 'recordModifiedDate',
            desc: true,
        },
    ];

    const handleAddModalClose = () => {
        setSelectedCaregiverDetails(null);
        setNewCaregiverModal(false);
        onCancel();
        setAction(null)
    };

    const handleArchiveConfirm = () => {
        setConfirmModal(true);
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setCheckedIds([]);
        setSelectedCaregiverDetails(null);
    };

    const handleConfirm = () => {
        let caregiverIds = [];
        if (checkedIds.length > 0) {
            caregiverIds = [...checkedIds]; // multiple select
        } else if (selectedCaregiverDetails?.id) {
            caregiverIds = [selectedCaregiverDetails.id]; // grid row select
        }

        let caregiverUpdateData = [];
        for (let caregiverId of caregiverIds) {
            caregiverUpdateData.push({
                id: caregiverId,
                status: CARE_GIVER_MEMBER_STATUS.Archived,
            });
        }

        api.ArchiveCaregiver(caregiverUpdateData)
            .then(() => {
                showAlert(messages.success.archiveCaregiver, ALERT_TYPE.SUCCESS);
                dispatch(getCaregiverDetailsByMemberId(data.id));
                handleConfirmClose();
            })
            .catch((error) => {
                showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            });
    };

    const handleCheckboxChange = (caregiverId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(caregiverId)) {
                setCheckedIds((prevIds) => [...prevIds, caregiverId]);
            }
        }
        else {
            if (checkedIds.includes(caregiverId)) {
                setCheckedIds((prevIds) => prevIds.filter((prevId) => prevId !== caregiverId));
            }
        }
    };

    const rowAction = ({ row }) => {
        return (
            <label key={row.original.id}>
                <input
                    type="checkbox"
                    checked={checkedIds.includes(row.original.id)}
                    onChange={() => handleCheckboxChange(row.original.id, event)}
                />
            </label>
        );
    };

    const handleEdit = (data) => {
        setNewCaregiverModal(true);
        onCancel();
        setSelectedCaregiverDetails(data);
        setAction(FORM_ACTION_TYPE.Edit);
    };

    const handleGridArchive = (data) => {
        setConfirmModal(true);
        setSelectedCaregiverDetails(data);
    };

    const rowActions = ({ row }) => {
        return (
            <div className="d-flex">
                <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                    onClick={() => handleEdit(row.original)}>
                    <Icon icon="edit" size={14} />
                </GridButton>
                <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                    onClick={() => handleGridArchive(row.original)}>
                    <Icon icon="archive" size={14} />
                </GridButton>
            </div>
        )
    }

    const columns = [
        {
            id: 'actions',
            header: '',
            cell: rowAction,
            size: 32,
            minSize: 32,
        },
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.firstName}
                </OverflowText>
            ),
            sortingFn: sortingFnText
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.lastName}
                </OverflowText>
            ),
            sortingFn: sortingFnText
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
            sortingFn: 'text',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.email}
                </OverflowText>
            )
        },
        {
            id: 'cellPhone',
            header: 'Cellphone',
            accessorKey: 'cellPhone',
        },
        {
            id: 'hipaaFormFlag',
            header: 'HIPAA flag',
            accessorKey: 'hipaaFormFlag',
            sortingFn: 'text'
        },
        {
            id: 'memberFlag',
            header: 'Member Flag',
            accessorKey: 'memberFlag',
            sortingFn: 'text'
        },
        {
            id: 'comment',
            header: 'Comments',
            accessorKey: 'comment',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original?.comment}
                </OverflowText>
            ),
            minSize: 180,
            size: 180,
            sortingFn: sortingFnText
        },
        {
            id: 'docCode',
            header: 'Doc Code',
            accessorKey: 'docCode',
            sortingFn: sortingFnText
        },
        {
            id: 'recordModifiedDate',
            header: 'Last Update',
            accessorKey: 'recordModifiedDate',
            accessorFn: (row) => new Date(row.recordModifiedDate),
            cell: ({ row }) => `${toDate(row.original.recordModifiedDate)}`,
            sortType: 'date',
            sortingFn: sortingFnDateOnly,
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: rowActions,
            size: 90,
        },
    ];

    const handleAddCaregiver = () => {
        onCancel();
        setNewCaregiverModal(true);
        setAction(FORM_ACTION_TYPE.New);
    };

    const fetchCaregiverData = () => dispatch(getCaregiverDetailsByMemberId(data.id));

    return (
        <>
            <Modal
                id="care-giver-management-modal"
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onCancel}
                size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Caregiver Management</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ActionBar>
                        <div className="d-flex gap-2">
                            <NewButton onClick={handleAddCaregiver} />
                            <Button
                                type="button"
                                onClick={handleArchiveConfirm}
                                disabled={checkedIds.length === 0}>
                                Archive
                            </Button>
                        </div>
                    </ActionBar>
                    {caregiverDetails === null ? (
                        <Loader />
                    ) : (
                        <DataGrid
                            columns={columns}
                            data={caregiverDetails}
                            enableFilters={false}
                            sort={defaultSort}
                        />
                    )}
                </Modal.Body>
            </Modal>
            {newCaregiverModal && (
                <AddCaregiverModal
                    show={newCaregiverModal}
                    onCancel={handleAddModalClose}
                    data={selectedCaregiverDetails}
                    action={action}
                    memberId={data.id}
                    aGId={data.aGId}
                    onSubmit={fetchCaregiverData}
                />
            )}
            {confirmModal && (
                <ConfirmModal
                    title="Archive"
                    show={confirmModal}
                    message={messages.confirm.archiveCaregiver}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            )}
        </>
    );

}

export default withAuth(CaregiverManagementModal);