import axios from 'axios';
import qs from 'qs';

axios.defaults.paramsSerializer = params => qs.stringify(params, { arrayFormat: 'repeat' });

const createInstance = () => {
    const instance = axios.create({
        headers: {
            Pragma: 'no-cache',
        },
    });

    instance.setAuth = token => {
        instance.defaults.headers = {
            ...instance.defaults.headers,
            Authorization: `Bearer ${token}`,
        };

        return instance;
    };

    instance.setContext = context => {
        instance.context = context;

        return instance;
    };

    instance.getContext = () => {

        return instance.context;
    };

    /* this solves the circular module loading issue
    when requiring the store within the interceptors */
    instance.setStore = store => {
        instance.store = store;

        return instance;
    };

    return instance;
};

export default createInstance;
