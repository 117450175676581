import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { getPrograms, archiveProgram } from "../../../ducks/programManagement";
import { resetPortalDetails } from "../../../ducks/advisoryGroup";
import { AppLoadContext } from '../../../components/ui/AppLoadContext'
import DataGrid from "../../../components/DataGrid";
import { useNavigate } from "react-router-dom";
import withAuth from "../../../components/hoc/withAuth";
import Loader from "../../../components/ui/Loader";
import { isUserAdmin, toDateTime } from "../../../utils/utils";
import ActionBar from "../../../components/ui/ActionBar";
import ConfirmModal from "../../../components/ui/ConfirmModal";
import messages from "../../../utils/helper/messages";
import Icon from "../../../components/ui/Icon";
import GridButton from "../../../components/ui/GridButton";
import ProgramModal from "./ProgramModal";
import MemberPortalModal from "./MemberPortalModal";
import NewButton from "../../../components/ui/NewButton";
import OverflowText from "../../../components/ui/OverflowTooltip";
import {
    PROGRAM_TYPE,
    US_STATE,
    PROGRAM_MEETING_FREQUENCY,
    PROGRAM_MEETING_TYPE,
    PROGRAM_STATUS,
    FORM_ACTION_TYPE,
    ALERT_TYPE
} from "../../../constants";
import { AlertContext } from "../../../context/AlertContext";


const ProgramManagement = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [programItem, setProgramItem] = useState(null);
    const [confirmModal, setConfirmModal] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showPortalModal, setShowPortalModal] = useState(false);
    const [action, setAction] = useState(false);
    const [portalAction, setPortalAction] = useState('');
    const [currentRowData, setCurrentRowData] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const program = useSelector((state) => state.programManagement.programs);
    const currentUser = useSelector((state) => state.user.currentUser);

    //Archive
    const handleConfirmClose = () => {
        setConfirmModal(false);
        setProgramItem(null);
    }
    const handleConfirm = () => {
        handleArchiveProgram(programItem);
        handleConfirmClose();
    }
    const handleArchiveConfirm = (programData) => {
        setConfirmModal(true);
        setProgramItem(programData);
    }

    //Add Program
    const handleAdd = () => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.New);
        setCurrentRowData(null);
    }
    //Edit Program
    const handleEdit = (data) => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.Edit);
        setCurrentRowData(data);
    }
    //View Program
    const handleView = (data) => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.View);
        setCurrentRowData(data);
    }
    //Copy Program
    const handleCopy = (data) => {
        const copyData = { ...data, name: '', shortName: '' };
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.Copy);
        setCurrentRowData(copyData);
    }
    const handleModalClose = () => {
        setShowModal(false);
        setCurrentRowData(null);
    }

    const handlePortalModalOpen = (data) => {
        setShowPortalModal(true);
        setCurrentRowData(data);
    }

    const handlePortalModalClose = () => {
        setShowPortalModal(false);
        setCurrentRowData(null);
        dispatch(resetPortalDetails());
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            setPortalAction(
                !isUserAdmin(currentUser)
                    ? FORM_ACTION_TYPE.View
                    : FORM_ACTION_TYPE.Edit
            );
            dispatch(getPrograms(currentUser?.companyId));
        }
    }, [isAppLoaded, currentUser]);

    const handleArchiveProgram = (programData) => {
        dispatch(archiveProgram({ id: programData.id, status: PROGRAM_STATUS.Archived }, () => {
            setProgramItem(null);
            showAlert(messages.success.archiveProgram, ALERT_TYPE.SUCCESS);
        }));
    };

    const columns = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
            maxSize: 300,
            sortingFn: 'text'
        },
        {
            id: 'type',
            header: 'Type',
            accessorKey: 'type',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_TYPE[row.original.type]}
                </OverflowText>
            ),
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_STATUS[row.original.status]}
                </OverflowText>
            ),
        },
        {
            id: 'dateTime',
            header: 'Last Update',
            accessorKey: 'modifiedDateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.modifiedDateTime)),
            cell: ({ row }) => `${toDateTime(row.original.modifiedDateTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
        {
            id: 'targetSize',
            header: 'Target Size',
            accessorKey: 'targetSize',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.targetSize}
                </OverflowText>
            ),
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {US_STATE[row.original.market]}
                </OverflowText>
            ),
        },
        {
            id: 'meetingFrequency',
            header: 'Meeting Frequency',
            accessorKey: 'meetingFrequency',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_FREQUENCY[row.original.meetingFrequency]}
                </OverflowText>
            ),
        },
        {
            id: 'meetingType',
            header: 'Meeting Type',
            accessorKey: 'meetingType',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {PROGRAM_MEETING_TYPE[row.original.meetingType]}
                </OverflowText>
            ),
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 120,
            cell: ({ row }) => (
                <>
                    {
                        !isUserAdmin(currentUser) ? (
                            <>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="View"
                                    onClick={() => { handleView(row.original) }}>
                                    <Icon icon="view" size={16} />
                                </GridButton>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Member Portal"
                                    onClick={() => { handlePortalModalOpen(row.original) }}>
                                    <Icon icon="member-portal" size={16} />
                                </GridButton>
                            </>
                        ) : (
                            <>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                                    onClick={() => { handleEdit(row.original) }}>
                                    <Icon icon="edit" size={16} />
                                </GridButton>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Copy"
                                    onClick={() => { handleCopy(row.original) }}>
                                    <Icon icon="duplicate" size={16} />
                                </GridButton>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Member Portal"
                                    onClick={() => { handlePortalModalOpen(row.original) }}>
                                    <Icon icon="member-portal" size={16} />
                                </GridButton>
                                <GridButton variant="light" size="sm" className="me-1 my-1" title="Archive"
                                    onClick={() => handleArchiveConfirm(row.original)}>
                                    <Icon icon="archive" size={16} />
                                </GridButton>
                            </>
                        )
                    }
                </>
            )
        },
    ];

    const defaultSort = [{
        id: 'shortName',
        desc: false
    }];

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" className="me-2" variant="outline-primary"
                    onClick={() => navigate(`/advisory-group-management/program-management/archive`)}>
                    View Archive
                </Button>
                {
                    isUserAdmin(currentUser) && <NewButton onClick={handleAdd} />
                }
            </ActionBar>

            <div className="flex-grow-1">
                {program === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        columns={columns}
                        data={program}
                        sort={defaultSort}
                        enableFilters={false}
                    />
                )}
            </div>

            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.archiveProgram}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
            <ProgramModal
                show={showModal}
                onCancel={handleModalClose}
                action={action}
                data={currentRowData}
            />
            {showPortalModal &&
                (<MemberPortalModal
                    show={showPortalModal}
                    action={portalAction}
                    onCancel={handlePortalModalClose}
                    data={{
                        id: currentRowData?.id,
                        companyId: currentRowData?.companyId,
                        shortName: currentRowData?.shortName,
                    }}
                />)
            }
        </div>
    );
};

export default withAuth(ProgramManagement);
