import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import Loader from "../../components/ui/Loader";
import DataGrid from "../../components/DataGrid";
import withAuth from "../../components/hoc/withAuth";
import ActionBar from "../../components/ui/ActionBar";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import OverflowText from '../../components/ui/OverflowTooltip';
import { getCohortsByStatus } from "../../ducks/cohort";
import { toDateTime } from "../../utils/utils";
import { COHORT_FILE_STATUS, COHORT_TRAINING_FLAG } from "../../constants";

const ArchivedCohorts = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const cohorts = useSelector((state) => state.cohort.archiveCohorts);
    const currentUser = useSelector((state) => state.user.currentUser);

    const columns = [
        {
            id: 'name',
            header: 'Cohort Name',
            accessorKey: 'name',
            maxSize: 400,
            sortingFn: 'text'
        },
        {
            id: 'status',
            header: 'Cohort status',
            accessorKey: 'status',
            cell: ({ row }) => (
                <div className="text-decoration-underline text-secondary cursor-pointer" onClick={() => {
                    navigate(`/admin/cohort-management/cohort-upload-overview/${row.original.id}`);
                }}>{row.original.status}</div>
            ),
            sortingFn: 'text'
        },
        {
            id: 'training',
            header: 'Training Cohort',
            accessorKey: 'training',
            size: 120,
            cell: ({ row }) => (
                <div>{COHORT_TRAINING_FLAG[row.original.training]}</div>
            ),
            sortingFn: 'text'
        },
        {
            id: 'fileName',
            header: 'Member List',
            accessorKey: 'fileName',
            sortingFn: 'text',
            cell: ({ row, cell }) => (<OverflowText id={cell.id}>{row.original.fileName}</OverflowText>),
        },
        {
            id: 'memberCount',
            header: 'Size',
            accessorKey: 'memberCount',
            size: 80,
        },
        {
            id: 'marketType',
            header: 'Market Type',
            accessorKey: 'marketType',
            size: 80,
        },
        {
            id: 'market',
            header: 'Market',
            accessorKey: 'market',
            size: 80,
        },
        {
            id: 'dateTime',
            header: 'DateTime',
            accessorKey: 'dateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
            sortingFn: 'datetime',
            size: 150,
        },
    ];

    const defaultSort = [{
        id: 'dateTime',
        desc: true
    }];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohortsByStatus(currentUser?.companyId, COHORT_FILE_STATUS.ARCHIVED));
        }
    }, [isAppLoaded, currentUser]);

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Button type="button" variant="outline-primary"
                    onClick={() => navigate(`/admin/cohort-management`)}>
                    View Active
                </Button>
            </ActionBar>
            <div className="flex-grow-1">
                {cohorts === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        columns={columns}
                        data={cohorts}
                        sort={defaultSort}
                        enableFilters={false}
                    />
                )}
            </div>
        </div>
    );
}

export default withAuth(ArchivedCohorts);
