import { useEffect, useState } from "react";
import { FaUserAlt } from "react-icons/fa";

const UserInitial = ({ user }) => {
    const [currentUser, setCurrentUser] = useState(user);

    useEffect(() => {
        setCurrentUser(user);
    }, [user]);

    if (currentUser) {
        let initials = '';
        let firstName = currentUser.firstName ? currentUser.firstName.trim() : '';
        let lastName = currentUser.lastName ? currentUser.lastName.trim() : '';
        if (firstName && lastName) {
            initials = firstName.charAt(0) + lastName.charAt(0);
        } else if (firstName && !lastName) {
            initials = firstName.slice(0, 2);
        } else if (!firstName && lastName) {
            initials = lastName.slice(0, 2);
        }
        return initials.toUpperCase();
    } else {
        return <FaUserAlt size={16} />
    }
}

export default UserInitial;
