import withAuth from '../../../components/hoc/withAuth';
import RiskPowerBi from "./RiskPowerBi";

const CoreDemographics = () => {
    return (
        <RiskPowerBi
            title="Core Demographics"
        />
    );
};

export default withAuth(CoreDemographics);
