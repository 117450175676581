import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from "react-router-dom";
import { getAdvisoryGroupData } from '../../ducks/advisoryGroup';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import DataGrid from '../../components/DataGrid';
import withAuth from '../../components/hoc/withAuth';
import Loader from '../../components/ui/Loader';
import { toDate, isUserAdmin } from '../../utils/utils';
import styles from '../../views/member-analytics/member-management/MemberManagementHome.module.scss';
import ActionBar from "../../components/ui/ActionBar";
import Icon from "../../components/ui/Icon";
import NavigationBoxDashboard from '../../components/ui/NavigationBoxDashboard';
import ContentDashboard from '../../components/ui/ContentDashboard';
import GridButton from "../../components/ui/GridButton";
import NewButton from "../../components/ui/NewButton";
import { FORM_ACTION_TYPE } from "../../constants";
import ProgramModal from "./program-management/ProgramModal";

export const Home = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAppLoaded = useContext(AppLoadContext);
    const advisoryGroupMaster = useSelector((state) => state.advisoryGroup.masterList);
    const currentUser = useSelector((state) => state.user.currentUser);
    const [showModal, setShowModal] = useState(false);
    const [action, setAction] = useState(false);
    const [currentRowData, setCurrentRowData] = useState(null);

    const handleAdd = () => {
        setShowModal(true);
        setAction(FORM_ACTION_TYPE.New);
        setCurrentRowData(null);
    }
    const handleModalClose = () => {
        setShowModal(false);
        setCurrentRowData(null);
    }


    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    const NavContent = [
        {
            path: "/advisory-group-management/analytics",
            locationName: "Advisory Group Management",
            pageName: 'Analytics',
            icon: 'analytics',
            classname: "purple",
            description: "Provides insight into the AG structure as it relates to population representativeness, health equity indicators, and responses to AG surveys including sample CAHPS surveys and health equity"
        },
        {
            path: "/advisory-group-management/document-management",
            locationName: "Advisory Group Management",
            pageName: 'Document Management',
            icon: 'document-management',
            classname: "yellow",
            description: "Upload, view, and manage documents related to an AG or AG activities"
        },
        {
            path: "/advisory-group-management/operations",
            locationName: "Advisory Group Management",
            pageName: 'Operations',
            icon: 'operations',
            classname: "darkSalmon",
            description: "View/update the operational status of one or more AG's including the ability to view the Master Schedule. Update the attendee status and logistical details for a meeting"
        },
        {
            path: "/advisory-group-management/program-management",
            locationName: "Advisory Group Management",
            pageName: 'Program Management',
            icon: 'program-management',
            classname: "gsApple",
            description: "Create new AG's or edit the characteristics of current AG's"
        },
        {
            path: "/advisory-group-management/recruitment",
            locationName: "Advisory Group Management",
            pageName: 'Recruitment',
            icon: 'recruitment',
            classname: "blueLight",
            description: "View or create applications for participation in the AG. Evaluate applications and select a panel of members to be invited to a specific AG"
        }
    ]

    const columns = [
        {
            id: 'name',
            header: 'Advisory Group Name',
            accessorKey: 'name',
            sortType: 'string',
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            sortingFn: 'text'
        },
        {
            id: 'noOfMembers',
            header: '# of Members',
            accessorKey: 'memberCount',
        },
        {
            id: 'noOfOpenApps',
            header: '# of Open Apps',
            accessorKey: 'appCount',
        },
        {
            id: 'NextMeetingDate',
            header: 'Next Meeting Date',
            accessorKey: 'nextMeetingTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.nextMeetingTime)),
            cell: ({ row }) => `${toDate(row.original.nextMeetingTime)}`,
            sortingFn: 'datetime'
        },
        {
            id: 'LastMeetingDate',
            header: 'Last Meeting Date',
            accessorKey: 'lastMeetingTime',
            accessorFn: (row) => (new Date(row.lastMeetingTime)),
            cell: ({ row }) => `${toDate(row.original.lastMeetingTime)}`,
            sortingFn: 'datetime'
        },
        {
            id: 'userId',
            header: 'Project Mgr Name',
            accessorKey: 'firstName',
            accessorFn: (row) => `${row.firstName} ${row.lastName}`,
            sortingFn: 'text'
        },
        {
            name: 'actions',
            header: 'Actions',
            size: 170,
            cell: () => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title={isUserAdmin(currentUser) ? "Edit" : "View"}
                    >
                        <Icon icon={isUserAdmin(currentUser) ? "edit" : "view"} size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Analyze"
                        onClick={() => navigate(`/advisory-group-management/analytics`)}>
                        <Icon icon="analyze" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Recruit"
                        onClick={() => navigate(`/advisory-group-management/recruitment`)}>
                        <Icon icon="recruit" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Operations"
                        onClick={() => navigate(`/advisory-group-management/operations`)}>
                        <Icon icon="operations_1" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Reporting"
                    >
                        <Icon icon="reporting" size={16} />
                    </GridButton>
                </>
            )
        },
    ];

    return (
        <Row className="h-100 mb-4">
            <Row className="w-100 mb-4">
                <Col
                    className={`d-flex px-4`}
                    md={6}>
                    <NavigationBoxDashboard data={NavContent} />
                </Col>
                <Col
                    className="d-flex flex-column px-4"
                    md={6}>
                    <div className={`${styles.tableHeading}`}>
                        Advisory Group Blog
                    </div>
                    <ContentDashboard />
                </Col>
            </Row>
            <Row className={` ${styles.secondRow} w-100`}>
                {isUserAdmin(currentUser) && <ActionBar title="Advisory Groups">
                    <NewButton onClick={handleAdd} />
                </ActionBar>}

                {advisoryGroupMaster === null ? <Loader /> : (
                    <div>
                        <DataGrid
                            columns={columns}
                            data={advisoryGroupMaster}
                            enableFilters={false}
                        />
                    </div>
                )}

                <ProgramModal
                    show={showModal}
                    onCancel={handleModalClose}
                    action={action}
                    data={currentRowData}
                />
            </Row>
        </Row>
    );
};

export default withAuth(Home);
