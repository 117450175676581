import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { getMembersByCohortId } from '../../../ducks/cohort'
import { AppLoadContext } from '../../../components/ui/AppLoadContext'
import DataGrid from "../../../components/DataGrid";
import withAuth from "../../../components/hoc/withAuth";
import Loader from "../../../components/ui/Loader";

export const MemberData = ({ cohortId, columns }) => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const member = useSelector((state) => state.cohort.members);

    useEffect(() => {
        if (isAppLoaded) {
            dispatch(getMembersByCohortId(cohortId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, cohortId]);


    return (
        <div className="flex-grow-1">
            {member === null ? <Loader /> :
                <DataGrid
                    columns={columns}
                    data={member}
                    enableFilters={false}
                />}
        </div>
    );
}

export default withAuth(MemberData);
