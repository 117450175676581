import React, { useState, useContext, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { ALERT_TYPE } from '../../../../constants';
import DataGrid from '../../../../components/DataGrid';
import { compareArray, toDate } from '../../../../utils/utils';
import OverflowText from '../../../../components/ui/OverflowTooltip';
import Loader from '../../../../components/ui/Loader';
import messages from '../../../../utils/helper/messages';
import { updateCaregiverAttendanceLogData, getCaregiverDetailsByMemberId } from '../../../../ducks/advisoryGroup';
import { AlertContext } from '../../../../context/AlertContext';
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import ConfirmModal from '../../../../components/ui/ConfirmModal';

const CaregiverAttendanceModal = ({ data, show, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);
    const isAppLoaded = useContext(AppLoadContext);

    const caregiverDetails = useSelector(state => state.advisoryGroup?.caregiverDetails);
    const currentUser = useSelector(state => state.user.currentUser);

    const [selectedCaregiverId, setSelectedCaregiverId] = useState([]);
    const [enableSave, setEnableSave] = useState(false);
    const [initialSelectedRows, setInitialSelectedRows] = useState([]);
    const [confirmModal, setConfirmModal] = useState(false);

    useEffect(() => {
        const initialSelection = initCheckedItems();
        setSelectedCaregiverId(initialSelection);
        setInitialSelectedRows(initialSelection);
        setEnableSave(false);
    }, [dispatch, isAppLoaded, currentUser, show, caregiverDetails]);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCaregiverDetailsByMemberId(data?.agMemberId));
        }
    }, [dispatch, isAppLoaded, currentUser]);

    const initCheckedItems = () => {
        const caretakerIds = [data.careTaker1, data.careTaker2];
        return caregiverDetails?.length > 0 && caregiverDetails
            .filter(item => caretakerIds.includes(item.id))
            .map(item => item.id) || [];
    };

    const selectedMembersRowActions = ({ row }) => {
        return (
            <div className="text-center">
                <label key={row.original.id}>
                    <input
                        type="checkbox"
                        checked={selectedCaregiverId.includes(row.original.id)}
                        disabled={selectedCaregiverId.length >= 2 && !isChecked(row.original.id)}
                        onChange={() => handleCheckboxChange(row.original.id)}
                    />
                </label>
            </div>
        );
    };

    const isChecked = (rowId) => selectedCaregiverId.includes(rowId);

    const handleCheckboxChange = (rowId) => {
        const updatedSelectedRows = selectedCaregiverId.includes(rowId) ? selectedCaregiverId.filter((id) => id !== rowId) : [...selectedCaregiverId, rowId];
        setSelectedCaregiverId(updatedSelectedRows);

        const sortedUpdatedArrayData = [...updatedSelectedRows].sort();
        const sortedInitialArrayData = [...initialSelectedRows].sort();

        // Check if there is a change compared to the initial selected care givers
        setEnableSave(!compareArray(sortedUpdatedArrayData, sortedInitialArrayData));
    };

    const selectedMembersColumns = [
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.firstName}
                </OverflowText>
            ),
            size: 150,
            enableSorting: false
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.lastName}
                </OverflowText>
            ),
            size: 150,
            enableSorting: false
        },
        {
            id: 'hipaaFormFlag',
            header: 'HIPAA Flag',
            accessorKey: 'hipaaFormFlag',
            size: 150,
            enableSorting: false
        },
        {
            id: 'memberFlag',
            header: 'Member Flag',
            accessorKey: 'memberFlag',
            size: 150,
            enableSorting: false
        },
        {
            id: 'comment',
            header: 'Comments',
            accessorKey: 'comment',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.comment}
                </OverflowText>
            ),
            enableSorting: false,
            size: 150
        },
        {
            id: 'recordModifiedDate',
            header: 'Last Update',
            accessorKey: 'recordModifiedDate',
            accessorFn: (row) => new Date(row.recordModifiedDate),
            cell: ({ row }) => `${toDate(row.original.recordModifiedDate)}`,
            enableSorting: false,
            sortType: 'date'
        },
        {
            id: 'actions',
            header: 'Attending',
            cell: selectedMembersRowActions,
            size: 90
        },
    ];

    const handleSuccess = () => {
        showAlert(messages.success.caregiverStatus, ALERT_TYPE.SUCCESS);
        onCancel();
    };

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = () => {
        const selectedCaregivers = {
            id: data.id,
            careTaker1: selectedCaregiverId.length > 0 ? selectedCaregiverId[0] : null,
            careTaker2: selectedCaregiverId.length > 0 ? selectedCaregiverId[1] : null
        };
        dispatch(updateCaregiverAttendanceLogData(selectedCaregivers, handleSuccess, handleError));
    };

    const handleCancel = () => {
        if (enableSave) {
            setConfirmModal(true);
        } else {
            onHideCallback();
        }
    };

    const onHideCallback = () => {
        setSelectedCaregiverId([]);
        setInitialSelectedRows([]);
        setEnableSave(false);
        onCancel();
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
    };

    return (
        <>
            <Modal
                show={show}
                fullscreen={false}
                backdrop="static"
                onHide={onHideCallback}
                size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>Caregiver Attendance</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-end mb-4">
                        <Button
                            variant="outline-primary"
                            type="button"
                            className="me-2"
                            onClick={handleCancel}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            type="button"
                            className="me-2"
                            onClick={handleSubmit}
                            disabled={!enableSave}>
                            Save
                        </Button>
                    </div>
                    {caregiverDetails === null ? (
                        <Loader />
                    ) : (
                        <DataGrid
                            columns={selectedMembersColumns}
                            data={caregiverDetails}
                            enableFilters={false}
                            pagination={false}
                            gridContainerClass={`grid-with-scroll overflow-auto`}
                        />
                    )}
                    <div className="ps-3 pt-4">
                        <ul>
                            <li>Only 2 caretaker's can be selected.</li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
            <ConfirmModal
                show={confirmModal}
                message={messages.confirm.discardProgramEdit}
                onConfirm={onHideCallback}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </>
    );
}

export default CaregiverAttendanceModal;
