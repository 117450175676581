import { ProgressBar } from "react-bootstrap";
import styles from "./FileProgress.module.scss";

const FileProgress = ({ file, progress, wrapperStyle }) => {
    return (
        <div className={wrapperStyle}>
            <span className={styles.fileName}>{file.name}</span>-
            <span className={styles.fileSize}>
                {(file.size / 1000).toFixed(1)} KB
            </span>
            <div className={styles.fileProgress}>
                <ProgressBar now={progress} />
            </div>
        </div>
    );
}

export default FileProgress;
