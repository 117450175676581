import api from '../../services/api/userService';
import companyApi from '../../services/api/companyMessageService';
import keyMirror from '../../utils/keyMirror';

export const actionTypes = keyMirror(
    {
        GET_USERS: null,
        GET_CURRENT_USER: null,
        UPDATE_CURRENT_COMPANY_LOGO: null,
    },
    'User'
);

// Action Creators

export const getCurrentUser = (msalInstance, failureHandler) => async dispatch => {
    try {
        let response = await api.GetCurrentUser(msalInstance);
        const { data } = response;

        dispatch({
            type: actionTypes.GET_CURRENT_USER,
            payload: data,
        });
    } catch {
        if (failureHandler) {
            failureHandler();
        }
    }
}

export const getUsers = (msalInstance) => async dispatch => {
    let response = await api.GetUsers(msalInstance);
    const { data } = response;
    dispatch({
        type: actionTypes.GET_USERS,
        payload: data,
    });
}
export const getCompanyLogoById = (id) => dispatch => {
    companyApi.GetCompanyLogo(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.UPDATE_CURRENT_COMPANY_LOGO,
            payload: data
        });
    });
}


// Reducer

const initialState = {
    users: [],
};

const userReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case actionTypes.GET_USERS:
            return {
                ...state,
                users: payload,
            };
        case actionTypes.GET_CURRENT_USER:
            return {
                ...state,
                currentUser: { ...state.currentUser, ...payload },
            };
        case actionTypes.UPDATE_CURRENT_COMPANY_LOGO: {
            let user = { ...state.currentUser };
            user.fileURL = payload;
            return {
                ...state,
                currentUser: { ...user },
            };
        }

        default:
            return state;
    }
};


export default userReducer;
