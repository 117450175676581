import { useState } from 'react';
import { Button, Collapse } from 'react-bootstrap';
import Icon from './Icon';
import styles from './CollapsePanel.module.scss';

const CollapsePanel = ({ title, wrapperStyle, children }) => {
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen(!open);
    };

    return (
        <div className={`bg-white px-4 ${wrapperStyle || ''} ${open ? styles.panelOpen : ''}`}>
            <div className="d-flex justify-content-between align-items-center mb-1">
                <h4 className="h6 fw-bold mb-0 py-2">{title}</h4>
                <Button
                    variant="light"
                    size="sm"
                    onClick={handleToggle}
                    className={`bg-light border-0 rounded-0 py-2 me-n4 ${styles.toggleButton} ${open ? styles.collapsed : ''}`}>
                    <Icon
                        icon="arrow-down"
                        size={14}
                        className="text-primary"
                    />
                </Button>
            </div>
            <Collapse in={open} className={styles.collapsePanel}>{children}</Collapse>
        </div>
    );
};

export default CollapsePanel;
