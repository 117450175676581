import withAuth from '../../../components/hoc/withAuth';
import RiskPowerBi from "./RiskPowerBi";

const OtherInsights = () => {
    return (
        <RiskPowerBi
            title="Other Insights"
        />
    );
};

export default withAuth(OtherInsights);
