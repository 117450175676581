import { Form } from "react-bootstrap";

const CohortSelector = () => {
    return (
        <Form.Select aria-label="Default select example" className="w-25">
            <option>Select Cohort</option>
            <option value="1">Cohort One</option>
            <option value="2">Cohort Two</option>
            <option value="3">Cohort Three</option>
        </Form.Select>
    )
}

export default CohortSelector;
