import { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux'
import EmbedReport from './EmbedReport';
import Placeholder from '../ui/Placeholder';
import { AppLoadContext } from "../ui/AppLoadContext";

const PowerBiReport = ({ title, reportName, filterState, reportRendered, printReport, setPrintReport }) => {
    const [reportConfig, setReportConfig] = useState(null);

    const isAppLoaded = useContext(AppLoadContext);
    const report = useSelector((state) => state.report);

    useEffect(() => {
        if (isAppLoaded && reportName && report[reportName]?.embedId) {
            setReportConfig({
                ...report[reportName],
                accessToken: report.accessToken,
            });
        }
    }, [report, reportName, isAppLoaded]);

    return (
        <div className="embed-wrapper h-100">
            {reportConfig ? (
                <EmbedReport
                    filterState={filterState}
                    reportRendered={reportRendered}
                    setPrintReport={setPrintReport}
                    printReport={printReport}
                    {...reportConfig}
                />
            ) : (
                <Placeholder
                    title={`Power BI - ${title}`}
                />
            )}
        </div>
    );
};

export default PowerBiReport;
