import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Modal from 'react-bootstrap/Modal';
import withAuth from '../../../components/hoc/withAuth';
import DataGrid from '../../../components/DataGrid';
import Loader from "../../../components/ui/Loader";
import styles from './ViewMemberPagesModal.module.scss';
import OverflowText from "../../../components/ui/OverflowTooltip";

const ViewMemberPagesModal = ({ show, onCancel }) => {
    const memberPagesData = useSelector((state) => state?.advisoryGroup?.viewMemberPagesData);

    const columns = [
        {
            id: 'shortName',
            header: 'Advisory Group Name',
            accessorKey: 'shortName',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.shortName}
                </OverflowText>
            ),
        },
        {
            id: 'portalDescription',
            header: 'Member Site Title',
            accessorKey: 'portalDescription',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {row.original.portalDescription}
                </OverflowText>
            ),
        },
        {
            id: 'portalURL',
            header: 'URL',
            accessorKey: 'portalURL',
            cell: ({ row }) => (
                <Link
                    to={row.original.portalURL}
                    target={'_blank'}>
                    <OverflowText>{row.original.portalURL}</OverflowText>
                </Link>
            ),
        },
    ];

    return (
        <Modal show={show}
               fullscreen={false}
               backdrop="static"
               onHide={onCancel}
               dialogClassName={styles.modalContainer}>
            <Modal.Header closeButton>
                <Modal.Title>View Member Pages</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    {memberPagesData === null ?
                        <Loader />
                        :
                        <DataGrid
                            columns={columns}
                            data={memberPagesData}
                            enableFilters={false}
                        />
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
};

export default withAuth(ViewMemberPagesModal);
