import Placeholder from "../../../components/ui/Placeholder";
import withAuth from "../../../components/hoc/withAuth";

const RecruitmentStatus = () => {
    return (
        <Placeholder
            title="Advisory Group functionality is currently under development"
            type="graphic"
        />
    );
}

export default withAuth(RecruitmentStatus);
