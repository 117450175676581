import React from "react";

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

export default function SessionTimeoutDialog({open, countdown, onLogout, onContinue}) {

    return (
        <Modal show={open}>
            <Modal.Header>
                <Modal.Title>Session Timeout</Modal.Title>
            </Modal.Header>
            <Modal.Body>{`The current session is about to expire in ${countdown} seconds. Would you like to continue the session?`}</Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onLogout}>
                    Logout
                </Button>
                <Button variant="primary" onClick={onContinue}>
                   Continue Session
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
