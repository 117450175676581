import createInstance from '../../utils/instance.js';
import addInterceptors from '../../utils/interceptors.js';
import {protectedResources} from "../../msalAuthConfig";
import {acquireAccessToken, getAccountId} from "./auth";


export const instance = addInterceptors(createInstance());

const headers = {
    'Content-Type': 'application/json',
};


const userApi = {
    GetCurrentUser: async (msalInstance) => {
        let accessToken = await acquireAccessToken(msalInstance);

        let userId = getAccountId(msalInstance);

        if (!userId) {
            return {
                data: {},
                error: null,
            };
        }

        instance.setAuth(accessToken);
        return await instance({
            method: 'POST',
            url: `${protectedResources.apiUsers.endpoint}/get`,
            data: {
                id: userId
            },
            headers,
        })
    },

    GetUsers: async (msalInstance) => {
        let accessToken = await acquireAccessToken(msalInstance);

        instance.setAuth(accessToken);
        return await instance({
            method: 'GET',
            url: protectedResources.apiUsers.endpoint,
            headers,
        })
    }
};

export default userApi;
