import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const cohortApi = {
    getCohortData: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/cohorts`,
            headers,
        })
    },

    getCohortDataByStatus: (id, status) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/cohorts/${status}`,
            headers,
        })
    },

    getCohortNotifications: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/notifications`,
            headers,
        })
    },

    archiveCohortNotifications: (idList) => {
        return apiInstance({
            method: 'POST',
            url: `${protectedResources.apiCohortManagement.endpoint}/notifications/archive`,
            headers,
            data: idList,
        })
    },

    getNotificationsByApplication: (id, application) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCompany.endpoint}/${id}/notifications/${application}`,
            headers,
        })
    },

    updateNotificationsStatus: (id, status) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCompany.endpoint}/${id}/notifications/${status}`,
            headers,
        })
    },

    mergeCohorts: async (cohorts) => {
        return await apiInstance({
            method: 'post',
            url: `${protectedResources.apiCohortManagement.endpoint}/merge`,
            headers,
            data: cohorts,
        })
    },

    getCohortUploadItem: (cohortId) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/cohortUploadItem`,
            headers,
        })
    },

    archiveCohort: (cohortId) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/archive`,
            headers,
        })
    },

    scheduleCohort: (cohortId) => {
        return apiInstance({
            method: 'PUT',
            url: `${protectedResources.apiCohortManagement.endpoint}/${cohortId}/schedule`,
            headers,
        })
    },

    getCohortRiskScores: (id) => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${id}/riskScores`,
            headers,
        })
    },

    validateCohortMemberId: async (data) => {
        return await apiInstance({
            method: 'GET',
            url: `${protectedResources.apiCohortManagement.endpoint}/${data.id}/member/${data.memberId}`,
            headers,
        });
    },
};

export default cohortApi;
