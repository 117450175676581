import Placeholder from "../../../components/ui/Placeholder";
import withAuth from "../../../components/hoc/withAuth";

const RiskManager = () => {
    return (
        <Placeholder
            title="Risk Manager"
            type="graphic"
        />
    );
}

export default withAuth(RiskManager);
