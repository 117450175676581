import React, { useState, useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import { POSITIONS, ALIGN, YES_NO_INVERSE, FORM_ACTION_TYPE, ALERT_TYPE, CARE_GIVER_MEMBER_STATUS, VALIDATIONS } from "../../../../constants";
import { isObjectEmpty, pickProps, radioOptionValues } from "../../../../utils/utils";
import messages from '../../../../utils/helper/messages';
import api from '../../../../services/api/advisoryGroupService';
import { AlertContext } from '../../../../context/AlertContext';
import withAuth from '../../../../components/hoc/withAuth';

const AddCaregiverModal = ({ show, onCancel, data, action, memberId, aGId, onSubmit }) => {
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [caregiverDetails, setCaregiverDetails] = useState(null);

    useEffect(() => {
        if (data) {
            const editData = pickProps(data, [
                'firstName', 'lastName', 'hipaaFormFlag', 'email', 'cellPhone', 'memberFlag', 'hostFlag', 'comment', 'docCode'
            ]);
            setCaregiverDetails({ ...editData, docCode: editData.docCode ? editData.docCode.trim() : null });
        }
    }, [data]);

    const arr = [
        {
            key: 'firstName',
            labelName: 'First Name',
            placeholderName: 'First Name',
            type: 'text',
            valid: VALIDATIONS.String.isName,
            maxLength: 35
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            placeholderName: 'Last Name',
            type: 'text',
            valid: VALIDATIONS.String.isName,
            maxLength: 35
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            type: 'email',
            required: false,
            maxLength: 255
        },
        {
            key: 'cellPhone',
            labelName: 'Cellphone',
            placeholderName: 'Cell Phone',
            type: 'tel',
            maxLength: 10,
            required: false,
            valid: VALIDATIONS.Number.isPhone

        },
        {
            key: 'hipaaFormFlag',
            labelName: 'HIPAA Form Complete',
            type: 'radio',
            required: false,
            optionValues: radioOptionValues(YES_NO_INVERSE, true),
            maxLength: 12,
        },
        {
            key: 'memberFlag',
            labelName: 'Company Plan Member',
            type: 'radio',
            required: false,
            optionValues: radioOptionValues(YES_NO_INVERSE, true),
            maxLength: 12,
        },
        {
            key: 'docCode',
            labelName: 'Doc Code',
            placeholderName: 'Doc Code',
            type: 'text',
            required: false,
            maxLength: 4
        },
        {
            key: 'comment',
            labelName: 'Comments',
            placeholderName: 'Comments',
            type: 'textarea',
            required: false,
            maxLength: 255
        },
    ];

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(action === FORM_ACTION_TYPE.New ? messages.success.addCaregiver : messages.success.updateCaregiver, ALERT_TYPE.SUCCESS);
        onSubmit();
        onCancel();
    };

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = (formData) => {
        setIsLoading(true);
        if (action === FORM_ACTION_TYPE.New) {
            const addData = {
                ...formData,
                companyId: currentUser?.companyId,
                agId: aGId,
                memberId: memberId,
                recordStatus: CARE_GIVER_MEMBER_STATUS.Operational,
                lastUserToUpdate: currentUser?.id
            };
            api.AddCaregiver(addData)
                .then(handleSuccess)
                .catch(handleError);
        } else if (action === FORM_ACTION_TYPE.Edit) {
            const updateData = {
                ...formData,
                hipaaFormFlag: formData.hipaaFormFlag ? formData.hipaaFormFlag : null,
                memberFlag: formData.memberFlag ? formData.memberFlag : null,
                id: data.id,
                lastUserToUpdate: currentUser?.id
            }
            api.UpdateCaregiver(updateData)
                .then(handleSuccess)
                .catch(handleError);
        }
    };

    const isValidCaregiverInfo = () => {
        if (action === FORM_ACTION_TYPE.Edit) {
            return !isObjectEmpty(caregiverDetails);
        }
        return true;
    };

    return (
        <Modal
            id="add-caregiver-modal"
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>
                    {action === FORM_ACTION_TYPE.New ? 'New' : 'Edit'} Caregiver
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2 position-relative">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={caregiverDetails}
                        onSubmit={handleSubmit}
                        onCancel={onCancel}
                        loading={isLoading}
                        disabled={!isValidCaregiverInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withAuth(AddCaregiverModal);