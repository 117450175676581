import React, { useContext, useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCohorts } from "../../../../ducks/cohort";
import { Tab, Form, Nav, Row, Col } from "react-bootstrap";
import withAuth from '../../../../components/hoc/withAuth';
import PowerBiReport from "../../../../components/powerBi/PowerBiReport";
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { COHORT_RETENTION_RISK_STATUS } from '../../../../constants';
import Placeholder from '../../../../components/ui/Placeholder';
import styles from "../RiskScore.module.scss";
import ActionBar from "../../../../components/ui/ActionBar";

const tabsContent = [
    {
        eventKey: 'cohortAge',
        title: 'Age',
        reportName: 'cohortAge',
        comparisons: [
            {
                parentTitle: 'Age',
                eventKey: 'cohortAgeXGender',
                title: 'Age X Gender',
                reportName: 'cohortAgeXGender',
            },
            {
                parentTitle: 'Age',
                eventKey: 'cohortAgeXLanguage',
                title: 'Age X Language',
                reportName: 'cohortAgeXLanguage',
            },
            {
                parentTitle: 'Age',
                eventKey: 'cohortAgeXRaceEthnicity',
                title: 'Age X Race/Ethnicity',
                reportName: 'cohortAgeXRaceEthnicity'
            },
            {
                parentTitle: 'Age',
                eventKey: 'cohortAgeXSalesChannel',
                title: 'Age X Sales Channel',
                reportName: 'cohortAgeXSalesChannel'
            },
            {
                parentTitle: 'Age',
                eventKey: 'cohortAgeXCustomerInteractions',
                title: 'Age X Customer Interactions',
                reportName: 'cohortAgeXCustomerInteractions'
            },
        ],
    },
    {
        eventKey: 'cohortGender',
        title: 'Gender',
        reportName: 'cohortGender',
        comparisons: [
            {
                parentTitle: 'Gender',
                eventKey: 'cohortGenderXLanguage',
                title: 'Gender X Language',
                reportName: 'cohortGenderXLanguage',
            },
            {
                parentTitle: 'Gender',
                eventKey: 'cohortGenderXRaceEthnicity',
                title: 'Gender X Race/Ethnicity',
                reportName: 'cohortGenderXRaceEthnicity',
            },
            {
                parentTitle: 'Gender',
                eventKey: 'cohortGenderXSalesChannel',
                title: 'Gender X Sales Channel',
                reportName: 'cohortGenderXSalesChannel'
            },
            {
                parentTitle: 'Gender',
                eventKey: 'cohortGenderXCustomerInteractions',
                title: 'Gender X Customer Interactions',
                reportName: 'cohortGenderXCustomerInteractions'
            },
        ],
    },
    {
        eventKey: 'cohortLanguage',
        title: 'Language',
        reportName: 'cohortLanguage',
        comparisons: [
            {
                parentTitle: 'Language',
                eventKey: 'cohortLanguageXSalesChannel',
                title: 'Language X Sales Channel',
                reportName: 'cohortLanguageXSalesChannel'
            },
            {
                parentTitle: 'Language',
                eventKey: 'cohortLanguageXCustomerInteractions',
                title: 'Language X Customer Interactions',
                reportName: 'cohortLanguageXCustomerInteractions'
            },
        ]
    },
    {
        eventKey: 'cohortRaceEthnicity',
        title: 'Race/Ethnicity',
        reportName: 'cohortRaceEthnicity',
        comparisons: [
            {
                parentTitle: 'Race/Ethnicity',
                eventKey: 'cohortRaceEthnicityXLanguage',
                title: 'Race/Ethnicity X Language',
                reportName: 'cohortRaceEthnicityXLanguage',
            },
            {
                parentTitle: 'Race/Ethnicity',
                eventKey: 'cohortRaceEthnicityXSalesChannel',
                title: 'Race/Ethnicity X Sales Channel',
                reportName: 'cohortRaceEthnicityXSalesChannel'
            },
            {
                parentTitle: 'Race/Ethnicity',
                eventKey: 'cohortRaceEthnicityXCustomerInteractions',
                title: 'Race/Ethnicity X Customer Interactions',
                reportName: 'cohortRaceEthnicityXCustomerInteractions'
            },
        ]
    },
    {
        eventKey: 'cohortSalesChannel',
        title: 'Sales Channel',
        reportName: 'cohortSalesChannel',
        comparisons: [
            {
                parentTitle: 'Sales Channel',
                eventKey: 'cohortSalesChannelXCustomerInteractions',
                title: 'Sales Channel X Customer Interactions',
                reportName: 'cohortSalesChannelXCustomerInteractions',
            },
        ]
    },
    {
        eventKey: 'cohortCustomerInteractions',
        title: 'Customer Interactions',
        reportName: 'cohortCustomerInteractions'
    },
];

const CohortView = () => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const subNavRef = useRef(null);
    const filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "RetentionManagement MemberOrdered",
            column: "cohortId"
        },
        operator: "In",
        values: [1]
    };

    const [filterState, setFilterState] = useState(filter);
    const [disableCohortSelection, setDisableCohortSelection] = useState(true);
    const [cohortIdOptions, setCohortIdOptions] = useState(null);
    const [activeKey, setActiveKey] = useState('Age');
    const [activeSubKey, setActiveSubKey] = useState('');
    const [showMainReport, setShowMainReport] = useState(true);

    const cohortsData = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            try {
                dispatch(getCohorts(currentUser?.companyId));

            } catch (error) {
                console.error("Error fetching cohorts:", error);
            }
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (cohortsData && cohortsData.length > 0) {
            setCohortIdOptions(cohortsData?.filter(item => item.retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Scored).map(item => ({ id: item.id, value: item.name })));
        }
    }, [cohortsData]);

    useEffect(() => {
        if (cohortIdOptions && cohortIdOptions.length > 0) {
            setFilterState({ ...filter, values: [Number(cohortIdOptions[0]?.id)] });
        }
    }, [cohortIdOptions]);

    useEffect(() => {
        if (showMainReport) {
            let subNav = subNavRef.current;
            subNav?.childNodes && subNav.childNodes.forEach(child => {
                const navLink = child.children[0];
                navLink.classList.remove('active');
                navLink.setAttribute('aria-selected', false);
                navLink.setAttribute('tabindex', -1);
            });
        }
    }, [showMainReport]);

    const handleInput = (e) => {
        setFilterState({ ...filter, values: [Number(e.target.value)] });
    }

    const reportRendered = (val) => {
        setDisableCohortSelection(!val);
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className={`flex-grow-1 tab-container`}>
                {cohortIdOptions && cohortIdOptions.length > 0 ? <Tab.Container defaultActiveKey={tabsContent[0].eventKey}>
                    <Row className="align-items-start gx-3 align-items-lg-end">
                        <Col
                            lg={3}
                            xxl={2}>
                            <Form.Select
                                defaultValue={
                                    cohortIdOptions ? cohortIdOptions[0]?.id : 1
                                }
                                onChange={handleInput}
                                disabled={disableCohortSelection}>
                                {cohortIdOptions?.map((optionInfo) => (
                                    <option
                                        key={optionInfo.id}
                                        value={optionInfo.id}>
                                        {optionInfo.value}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col
                            lg={9}
                            xxl={10}>
                            <Nav
                                variant="underline"
                                className="flex-grow-1">
                                {tabsContent.map(({ eventKey, title }) => (
                                    <Nav.Item key={eventKey}>
                                        <Nav.Link
                                            eventKey={eventKey}
                                            onClick={(e) => {
                                                setActiveKey(e.target.text);
                                                setActiveSubKey('');
                                                setShowMainReport(true);
                                            }}>
                                            {title}
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                    </Row>
                    <Tab.Content>
                        {tabsContent.map((tab) => (
                            tab.title === activeKey &&
                            <Tab.Pane
                                eventKey={tab.eventKey}
                                key={`${tab.eventKey}_content`}
                                className="flex-grow-1">

                                {tab.comparisons?.length > 0 &&
                                    <Tab.Container defaultActiveKey={activeSubKey}>
                                        <Row className="mt-1 mb-2 flex-shrink-0 gx-3">
                                            <Col lg={3}
                                                xxl={2} className="text-end">Comparisons:</Col>
                                            <Col lg={9}
                                                xxl={10} className="pt-1">
                                                <Nav
                                                    className="sub-nav flex-grow-1"
                                                    ref={subNavRef}>
                                                    {tab.comparisons && tab.comparisons.length > 0 ?
                                                        <>
                                                            {tab.comparisons.map(({ eventKey, title }) => {
                                                                return (
                                                                    <Nav.Item key={eventKey}>
                                                                        <Nav.Link
                                                                            eventKey={eventKey}
                                                                            onClick={(e) => {
                                                                                setActiveSubKey(e.target.text);
                                                                                setShowMainReport(false);
                                                                            }}>
                                                                            {title}
                                                                        </Nav.Link>
                                                                    </Nav.Item>
                                                                )
                                                            }
                                                            )}
                                                        </>
                                                        : null}
                                                </Nav>
                                            </Col>
                                        </Row>
                                        <Tab.Content>
                                            {tab.comparisons && tab.comparisons.map((tab) => (
                                                tab.title === activeSubKey &&
                                                <Tab.Pane
                                                    eventKey={tab.eventKey}
                                                    key={`${tab.eventKey}_content`}
                                                    className="flex-grow-1">
                                                    <PowerBiReport
                                                        title={tab.title}
                                                        reportName={tab.reportName}
                                                        filterState={filterState}
                                                        reportRendered={(val) =>
                                                            reportRendered(val)
                                                        }
                                                    />
                                                </Tab.Pane>)
                                            )}
                                        </Tab.Content>
                                    </Tab.Container>
                                }

                                <div className={!showMainReport ? 'd-none' : 'h-100'}>
                                    <PowerBiReport
                                        title={tab.title}
                                        reportName={tab.reportName}
                                        filterState={filterState}
                                        reportRendered={(val) =>
                                            reportRendered(val)
                                        }
                                    />
                                </div>
                            </Tab.Pane>
                        ))}
                    </Tab.Content>
                </Tab.Container>
                    : (
                        <>
                            <ActionBar back={true}>
                                <div className={`${styles.cohortSelector} w-auto`}>
                                    <Form.Select
                                        defaultValue={0}
                                        disabled={disableCohortSelection}>
                                        <option
                                            value={0}>
                                            No Scored Cohort
                                        </option>
                                    </Form.Select>
                                </div>
                            </ActionBar>
                            <div className="embed-wrapper h-100">
                                <Placeholder
                                    title={`Power BI `}
                                />
                            </div>
                        </>
                    )}
                <div className="bg-light p-3 mb-0">
                    Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore.
                </div>
            </div>
        </div>
    );
};

export default withAuth(CohortView);
