import React from 'react';
import Report from './report';
import { POWER_BI_REPORTS } from '../../constants';
import { useSelector } from 'react-redux';

function PowerBiStarExplorer({ reportConfig, title, content }) {
    const reportSlicerState = useSelector((state) => reportConfig?.reportName === POWER_BI_REPORTS.STAR_REPORT_CARD ? state.report.reportCardSlicerState : state.report.starExplorerSlicerState);
    return (
        <div className="h-100 d-flex flex-column">
            <div className="embed-wrapper h-75">
                {reportConfig && <Report {...reportConfig} reportSlicerState={reportSlicerState} />}
            </div>

            {title && (
                <div className="d-flex justify-content-center align-items-center bg-light p-3 h4 mb-0 h-25">
                    {title}
                </div>
            )}

            {content && (
                <div className="bg-light p-3 mb-0 h-25 overflow-auto">
                    {content}
                </div>
            )}
        </div>
    );
}



export default PowerBiStarExplorer;
