import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { setLocation } from '../../../ducks/navigation';
import CohortSelector from './CohortSelector';
import ActionBar from '../../../components/ui/ActionBar';

const PopulationActionbar = () => {
    const homePath = '/member-analytics/retention-risk/risk-explorer';
    const navigate = useNavigate();
    const dispatch = useDispatch();

    return (
        <ActionBar back={true}>
            <Button
                type="button"
                variant="light"
                className="me-2"
                onClick={() => {
                    dispatch(
                        setLocation({
                            path: homePath,
                            name: 'Member Analytics',
                            page: 'Risk Exploration',
                        })
                    );
                    navigate(homePath);
                }}>
                <IoMdArrowRoundBack className="me-1" />
                Back
            </Button>
            <CohortSelector />
        </ActionBar>
    );
};

export default PopulationActionbar;
