import React from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Modal } from "react-bootstrap";
import DataGrid from "../../../../components/DataGrid";
import CommentPopOver from "../../../../components/ui/CommentPopOver";
import { AG_MEETING_ATTENDANCE_STATUS_POST, AG_MEETING_ATTENDANCE_STATUS_PRE, AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE } from "../../../../constants";
import { toDate } from "../../../../utils/utils";
import Icon from "../../../../components/ui/Icon";
import Loader from "../../../../components/ui/Loader";
import { resetMeetingMemberComments } from "../../../../ducks/advisoryGroup";

const AttendanceLogDetailsModal = ({ show, onCancel }) => {
    const dispatch = useDispatch();

    const meetingMemberData = useSelector((state) => state.advisoryGroup?.memberMeetingDetails ? state.advisoryGroup.memberMeetingDetails : null);
    const defaultSort = [
        {
            id: 'locationName',
            desc: true,
        },
    ];

    const memberStatusColumns = [
        {
            id: 'recordModified',
            header: 'Date',
            accessorKey: 'Last Update',
            accessorFn: (row) => new Date(row.recordModified),
            cell: ({ row }) => `${toDate(row.original.recordModified)}`,
            sortType: 'date',
            sortingFn: 'datetime',
        },
        {
            id: 'locationName',
            header: 'Location',
            accessorKey: 'locationName',
            sortingFn: 'text'
        },
        {
            id: 'status',
            header: 'Pre-Meeting Response',
            accessorKey: 'status',
            cell: ({ row }) =>
                AG_MEETING_ATTENDANCE_STATUS_PRE[row.original.status?.trim()],
        },
        {
            id: 'statusPost',
            header: 'Meeting Participation',
            accessorKey: 'statusPost',
            cell: ({ row }) => row.original.statusPost === AG_MEETING_ATTENDANCE_STATUS_POST_INVERSE.FutureMeeting ? null : AG_MEETING_ATTENDANCE_STATUS_POST[row.original.statusPost?.trim()],
        },
        {
            name: 'actions',
            header: 'Comments',
            size: 90,
            cell: ({ row }) => (
                <CommentPopOver variant="light" size="sm" className="me-1 my-1" title={'Click here to view the comments'} data={row.original}>
                    <Icon icon="comments" size={14} />
                </CommentPopOver>
            ),
        },
    ];

    const onHideCallback = () => {
        onCancel();
        dispatch(resetMeetingMemberComments());
    }

    return (
        <Modal
            id='attendance-details-modal'
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="lg">
            <Modal.Header closeButton>
                <Modal.Title>Attendance Log</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {meetingMemberData === null ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={meetingMemberData}
                        columns={memberStatusColumns}
                        enableFilters={false}
                        sort={defaultSort}
                    />
                )}
            </Modal.Body>
        </Modal>
    );
}

export default AttendanceLogDetailsModal;
