import { Nav, Row, Col } from 'react-bootstrap';
import NavigationBox from './NavigationBox';

export const NavigationBoxDashboard = ({ data, cols = 1 }) => {

    return (
        <Nav className={`flex-column flex-grow-1`}>
            <Row className="g-3">
                {data?.map((navBoxItem) => (
                    <Col key={navBoxItem.path} md={12 / cols}>
                        <NavigationBox
                            type={navBoxItem.type}
                            onModalOpen={navBoxItem.onModalOpen}
                            path={navBoxItem.path}
                            locationName={navBoxItem.locationName}
                            pageName={navBoxItem.pageName}
                            icon={navBoxItem.icon}
                            classname={navBoxItem.classname}>
                            {navBoxItem.description}
                        </NavigationBox>
                    </Col>
                ))}
            </Row>
        </Nav>
    );
};

export default NavigationBoxDashboard;
