import { apiInstance } from "../apiInstance";
import { protectedResources } from "../../msalAuthConfig";

const headers = {
    'Content-Type': 'application/json',
};

const reportApi = {
    GetStarExplorerNationalDashboardReport: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/national-dashboard`,
            headers,
        })
    },
    GetStarExplorerHomeReport: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/home`,
            headers,
        })
    },
    GetStarExplorerStateOverviewReport: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/state-overview`,
            headers,
        })
    },
    GetStarExplorerStateDetailReport: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/state-detail`,
            headers,
        })
    },
    getStarExplorerReportCardReport: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/report-card`,
            headers,
        })
    },
    getPowerBiEmbededUrls: () => {
        return apiInstance({
            method: 'GET',
            url: `${protectedResources.apiReport.endpoint}/star-explorer/all`,
            headers,
        })
    }
};

export default reportApi;
