import DataGrid from "../../components/DataGrid";
// import { useEffect } from "react";

const NestedReactTable = () => {

    // useEffect(() => { toggleAllRowsExpanded(); }, []);

    const data = [
        {
            "firstName": "Madelynn",
            "lastName": "Beatty",
            "age": 20,
            "visits": 261,
            "status": "relationship",
            "progress": 56,
            "subRows": [
                {
                    "firstName": "Germaine 2",
                    "lastName": "Mertz",
                    "age": 25,
                    "visits": 524,
                    "status": "relationship",
                    "progress": 18
                },
                {
                    "firstName": "Theresia 2",
                    "lastName": "Hoppe",
                    "age": 10,
                    "visits": 142,
                    "status": "complicated",
                    "progress": 86
                },
            ]
        },
        {
            "firstName": "Germaine",
            "lastName": "Mertz",
            "age": 25,
            "visits": 524,
            "status": "relationship",
            "progress": 18,
            "subRows": [
                {
                    "firstName": "Kenyatta",
                    "lastName": "Schneider",
                    "age": 19,
                    "visits": 163,
                    "status": "single",
                    "progress": 18
                },
                {
                    "firstName": "Teagan",
                    "lastName": "Price",
                    "age": 30,
                    "visits": 170,
                    "status": "complicated",
                    "progress": 8
                },
            ]
        },
        {
            "firstName": "Theresia",
            "lastName": "Hoppe",
            "age": 10,
            "visits": 142,
            "status": "complicated",
            "progress": 86
        },
        {
            "firstName": "Kenyatta",
            "lastName": "Schneider",
            "age": 19,
            "visits": 163,
            "status": "single",
            "progress": 18
        },
        {
            "firstName": "Teagan",
            "lastName": "Price",
            "age": 30,
            "visits": 170,
            "status": "complicated",
            "progress": 8
        },
        {
            "firstName": "Herminia",
            "lastName": "Williamson",
            "age": 6,
            "visits": 601,
            "status": "complicated",
            "progress": 95
        },
        {
            "firstName": "Allene",
            "lastName": "Nienow",
            "age": 5,
            "visits": 987,
            "status": "relationship",
            "progress": 3
        },
        {
            "firstName": "Cleveland",
            "lastName": "Frami",
            "age": 10,
            "visits": 773,
            "status": "relationship",
            "progress": 20
        },
        {
            "firstName": "Dexter",
            "lastName": "Maggio",
            "age": 25,
            "visits": 972,
            "status": "relationship",
            "progress": 19
        },
        {
            "firstName": "Eulalia",
            "lastName": "Deckow",
            "age": 26,
            "visits": 323,
            "status": "complicated",
            "progress": 27
        },
        {
            "firstName": "Bria",
            "lastName": "Dickens",
            "age": 11,
            "visits": 625,
            "status": "single",
            "progress": 35
        },
        {
            "firstName": "Gladyce",
            "lastName": "Kshlerin",
            "age": 30,
            "visits": 167,
            "status": "single",
            "progress": 75
        },
        {
            "firstName": "Trace",
            "lastName": "Daniel",
            "age": 23,
            "visits": 245,
            "status": "relationship",
            "progress": 15
        },
        {
            "firstName": "Icie",
            "lastName": "Kuvalis",
            "age": 19,
            "visits": 474,
            "status": "relationship",
            "progress": 20
        },
        {
            "firstName": "Helen",
            "lastName": "Jakubowski",
            "age": 35,
            "visits": 854,
            "status": "complicated",
            "progress": 42
        },
        {
            "firstName": "Rosalia",
            "lastName": "Upton",
            "age": 7,
            "visits": 90,
            "status": "relationship",
            "progress": 83
        },
        {
            "firstName": "Jovan",
            "lastName": "Jacobi",
            "age": 37,
            "visits": 581,
            "status": "relationship",
            "progress": 94
        },
        {
            "firstName": "Madonna",
            "lastName": "Raynor",
            "age": 2,
            "visits": 895,
            "status": "relationship",
            "progress": 6
        },
        {
            "firstName": "Aliya",
            "lastName": "Koch",
            "age": 15,
            "visits": 151,
            "status": "single",
            "progress": 67
        },
        {
            "firstName": "Evans",
            "lastName": "Kling",
            "age": 3,
            "visits": 999,
            "status": "complicated",
            "progress": 5
        }
    ];
    const columns = [
        {
            header: 'Name',
            footer: props => props.column.id,
            columns: [
                {
                    accessorKey: 'firstName',
                    // cell: info => info.getValue(),
                    footer: props => props.column.id,
                    cell: ({ row, getValue }) => (
                        <div style={{paddingLeft: `${row.depth * 2}rem`}}>
                            <>
                                {row.getCanExpand() ? (
                                    <button
                                        {...{
                                            onClick: row.getToggleExpandedHandler(),
                                            style: { cursor: 'pointer' },
                                        }}
                                    >
                                        {row.getIsExpanded() ? '👇' : '👉'}
                                    </button>
                                ) : (
                                    '🔵'
                                )}{' '}
                                {getValue()}
                            </>
                        </div>
                    ),
                },
                {
                    accessorFn: row => row.lastName,
                    id: 'lastName',
                    cell: info => info.getValue(),
                    header: () => <span>Last Name</span>,
                    footer: props => props.column.id,
                },
            ],
        },
        {
            header: 'Info',
            footer: props => props.column.id,
            columns: [
                {
                    accessorKey: 'age',
                    header: () => 'Age',
                    footer: props => props.column.id,
                },
                {
                    header: 'More Info',
                    columns: [
                        {
                            accessorKey: 'visits',
                            header: () => <span>Visits</span>,
                            footer: props => props.column.id,
                        },
                        {
                            accessorKey: 'status',
                            header: 'Status',
                            footer: props => props.column.id,
                        },
                        {
                            accessorKey: 'progress',
                            header: 'Profile Progress',
                            footer: props => props.column.id,
                        },
                    ],
                },
            ],
        },
];

    return (
        <DataGrid
            columns={columns}
            data={data}
            enableFilters={false}
        />
    );
}

export default NestedReactTable
