import React, { useState, useEffect } from "react";
import { Tabs, Tab } from 'react-bootstrap';
import withAuth from '../../../../components/hoc/withAuth';
import PowerBiReport from "../../../../components/powerBi/PowerBiReport";

const tabsContent = [
    {
        eventKey: 'recruitmentCoreDemographic',
        title: 'Core Demographics',
        reportName: 'recruitmentCoreDemographic'
    },
    {
        eventKey: 'recruitmentTechnology',
        title: 'Technology',
        reportName: 'recruitmentTechnology'
    },
    {
        eventKey: 'recruitmentCompareCohort',
        title: 'Cohort Comparision',
        reportName: 'recruitmentCompareCohort',
    },
    {
        eventKey: 'recruitmentGeographicDispersion',
        title: 'Geography',
        reportName: 'recruitmentGeographicDispersion',
    },
    {
        eventKey: 'recruitmentHealthAccess',
        title: 'Health Access',
        reportName: 'recruitmentHealthAccess',
    }
];

const RecruitmentStatisticsPowerBiReport = ({ agId, reportRendered, printReport, setPrintReport }) => {
    const [activeKey, setActiveKey] = useState('recruitmentCoreDemographic');
    const filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "AdvisoryGroup PopCharMember_Recruitment",
            column: "masterAGId"
        },
        operator: "In",
        values: [1]
    };

    const [filterState, setFilterState] = useState(filter);

    useEffect(() => {
        if (agId) {
            setFilterState({ ...filter, values: [agId] });
        }
    }, [agId]);

    return (<Tabs
        defaultActiveKey="core-demographics"
        activeKey={activeKey}
        onSelect={(k) => setActiveKey(k)}
        variant="underline"
    >
        {
            tabsContent.map((content) => (
                <Tab
                    key={content.eventKey}
                    eventKey={content.eventKey}
                    title={content.title}
                    className="flex-grow-1 mt-1"
                >
                    {content.eventKey === activeKey && <PowerBiReport
                        title={content.title}
                        reportName={content.reportName}
                        filterState={filterState}
                        printReport={printReport}
                        setPrintReport={(val) =>
                            setPrintReport(val)
                        }
                        reportRendered={(val) =>
                            reportRendered(val)
                        }
                    />}
                </Tab>
            ))
        }
    </Tabs>
    );
};

export default withAuth(RecruitmentStatisticsPowerBiReport);
