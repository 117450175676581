import React, { useEffect, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import styles from './Admin.module.scss';
import withAuth from '../../components/hoc/withAuth';
import { getCohortNotifications } from '../../ducks/cohort';
import { AppLoadContext } from '../../components/ui/AppLoadContext';
import DataGrid from '../../components/DataGrid';
import Loader from '../../components/ui/Loader';
import { toDateTime, isUserSysAdmin } from '../../utils/utils';
import NavigationBoxDashboard from '../../components/ui/NavigationBoxDashboard';

const Admin = () => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const notifications = useSelector((state) => state.cohort.notifications);
    const currentUser = useSelector((state) => state.user.currentUser);

    const columnsNotification = [
        {
            id: 'dateTime',
            header: 'DateTime',
            accessorKey: 'dateTime',
            sortType: 'date',
            accessorFn: (row) => (new Date(row.dateTime)),
            cell: ({ row }) => `${toDateTime(row.original.dateTime)}`,
            sortingFn: 'datetime'
        },
        {
            id: 'type',
            header: 'Type',
            accessorKey: 'type',
            sortType: 'text',
        },
        {
            id: 'application',
            header: 'Application',
            accessorKey: 'application',
            sortType: 'text',
        },
        {
            id: 'message',
            header: 'Message Text',
            accessorKey: 'message',
            sortingFn: 'text'
        },
    ];
    const defaultSort = [
        {
            id: 'dateTime',
            desc: true,
        },
    ];


    const contentManagement = {
        path: '/admin/content-management',
        locationName: 'Admin Center',
        pageName: 'Content Management',
        icon: 'content-management',
        classname: 'darkSalmon',
        description: 'Create, update, or archive content that is available to the users and administrators on the PHARO platform. This content includes best practices, industry news, thought leadership, PHARO announcements and product tips.',
    };

    let NavContent = [
        {
            path: '/admin/cohort-management',
            locationName: 'Admin Center',
            pageName: 'Cohort Management',
            icon: 'cohort-management',
            classname: 'purple',
            description:
                'Define member groups (cohorts) that can be utilized in the different PHARO applications. Upload, validated, and enrich your member groups using these tools.',
        },
        {
            path: '/admin/company-management',
            locationName: 'Admin Center',
            pageName: 'Company Management',
            icon: 'company-management',
            classname: 'yellow',
            description:
                'Define new PHARO clients, identify primary client contacts, and establish the default access rights for users associated with each company.',
        },
        {
            path: '/admin/system-notifications',
            locationName: 'Admin Center',
            pageName: 'System Notifications',
            icon: 'system-notifications',
            classname: 'gsApple',
            description: 'Administrators can view and manage an aggregated set of system notification messages that have been generated by the PHARO platform and applications.',
        },
        {
            path: '/admin/user-management',
            locationName: 'Admin Center',
            pageName: 'User Management',
            icon: 'user-management',
            classname: 'blueLight',
            description: 'Administrators can view and edit a limited set of information about the PHARO users in their company. This utility also allows the administrator to invite others in their organization to enroll as PHARO users.',
        },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohortNotifications(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    if (isUserSysAdmin) {
        NavContent.splice(2, 0, contentManagement);
    };

    return (
        <Row className="h-100 gx-5">
            <Col className='px-4'>
                <NavigationBoxDashboard data={NavContent} />
            </Col>
            <Col
                md={6}
                className={`${styles.pageCol} d-flex justify-content-center`}>
                {notifications === null ? (
                    <Loader />
                ) : (
                    <div className={`d-flex flex-column ${styles.notiTable}`}>
                        <div
                            className={`d-flex align-items-center justify-content-center bg-primary text-white ${styles.tableAlertHeadertxt}`}>
                            Alerts
                        </div>
                        <DataGrid
                            columns={columnsNotification}
                            data={notifications}
                            sort={defaultSort}
                            enableFilters={false}
                        />
                        <p className={`mt-5 text-right ${styles.tableInfo}`}>
                            <b>Key:</b> A= Alert, E=Error, P=Process,
                            M=Informational Message, S=System
                        </p>
                    </div>
                )}
            </Col>
        </Row>
    );
};

export default withAuth(Admin);
