import React from 'react';
import { Form } from 'react-bootstrap';
import styles from './Selector.module.scss';

const Selector = ({
    items,
    value,
    param,
    onSelectChange,
    placeholder = 'Select',
    noRecordMessage = 'No records',
    wrapperStyle = '',
    disabled = false
}) => {
    return (
        <div className={`${styles.selector} ${wrapperStyle}`}>
            {items?.length > 0 ? (
                <Form.Select
                    name="selector"
                    value={value}
                    disabled={disabled}
                    onChange={onSelectChange}>
                    <option
                        disabled
                        value="0">
                        {placeholder}
                    </option>
                    {items.map((item) => (
                        <option
                            key={item.id}
                            value={item.id}>
                            {item[param]}
                        </option>
                    ))}
                </Form.Select>
            ) : (
                <Form.Select disabled>
                    <option value="0">{noRecordMessage}</option>
                </Form.Select>
            )}
        </div>
    );
};

export default Selector;
