import React, { useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Tabs, Tab, Form } from 'react-bootstrap';
import withAuth from '../../../../components/hoc/withAuth';
import PowerBiReport from "../../../../components/powerBi/PowerBiReport";
import { getCohorts } from "../../../../ducks/cohort";
import { AppLoadContext } from '../../../../components/ui/AppLoadContext';
import { COHORT_RETENTION_RISK_STATUS } from '../../../../constants';
import Placeholder from '../../../../components/ui/Placeholder';
import styles from "../RiskScore.module.scss";
import ActionBar from "../../../../components/ui/ActionBar";

const CompanyView = () => {
    const [key, setKey] = useState('companyAge');
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const [disableTabSelection, setDisableTabSelection] = useState(true);
    const [cohortIdOptions, setCohortIdOptions] = useState(null);

    const cohortsData = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            try {
                dispatch(getCohorts(currentUser?.companyId));

            } catch (error) {
                console.error("Error fetching cohorts:", error);
            }
        }
    }, [isAppLoaded, currentUser, dispatch]);

    useEffect(() => {
        if (cohortsData && cohortsData.length > 0) {
            setCohortIdOptions(cohortsData?.filter(item => item.retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Scored).map(item => ({ id: item.id, value: item.name })));
        }
    }, [cohortsData]);

    const tabsContent = [
        {
            eventKey: 'companyAge',
            title: 'Age',
            reportName: 'companyAge'
        },
        {
            eventKey: 'companyGender',
            title: 'Gender',
            reportName: 'companyGender'
        },
        {
            eventKey: 'companyLanguage',
            title: 'Language',
            reportName: 'companyLanguage',
        },
        {
            eventKey: 'companyRaceEthnicity',
            title: 'Race/Ethnicity',
            reportName: 'companyRaceEthnicity',
        },
        {
            eventKey: 'companyAgeXGender',
            title: 'Age X Gender',
            reportName: 'companyAgeXGender',
        },
        {
            eventKey: 'companyAgeXLanguage',
            title: 'Age X Language',
            reportName: 'companyAgeXLanguage',
        },
        {
            eventKey: 'companyAgeXRaceEthnicity',
            title: 'Age X Race/Ethnicity',
            reportName: 'companyAgeXRaceEthnicity'
        },
        {
            eventKey: 'companyGenderXLanguage',
            title: 'Gender X Language',
            reportName: 'companyGenderXLanguage',
        },
        {
            eventKey: 'companyGenderXRaceEthnicity',
            title: 'Gender X Race/Ethnicity',
            reportName: 'companyGenderXRaceEthnicity',
        },
        {
            eventKey: 'companyRaceEthnicityXLanguage',
            title: 'Race/Ethnicity X Language',
            reportName: 'companyRaceEthnicityXLanguage',
        },
    ];

    const reportRendered = (val) => {
        setDisableTabSelection(!val);
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className={`flex-grow-1 tab-container`}>
                {cohortIdOptions && cohortIdOptions.length > 0 ?
                    <Tabs
                        defaultActiveKey="companyAge"
                        activeKey={key}
                        onSelect={(k) => setKey(k)}
                        variant="underline"
                    >
                        {
                            tabsContent.map((content) => (
                                <Tab
                                    key={content.eventKey}
                                    eventKey={content.eventKey}
                                    disabled={disableTabSelection}
                                    title={content.title}
                                    className="flex-grow-1 mt-1"
                                >
                                    {content.eventKey === key && <PowerBiReport
                                        title={content.title}
                                        reportName={content.reportName}
                                        reportRendered={(val) =>
                                            reportRendered(val)
                                        }
                                    />}
                                </Tab>
                            ))
                        }
                    </Tabs> : (
                        <>
                            <ActionBar back={true}>
                                <div className={`${styles.cohortSelector} w-auto`}>
                                    <Form.Select
                                        defaultValue={0}
                                        disabled={true}>
                                        <option
                                            value={0}>
                                            No Scored Cohort
                                        </option>
                                    </Form.Select>
                                </div>
                            </ActionBar>
                            <div className="embed-wrapper h-100">
                                <Placeholder
                                    title={`Power BI `}
                                />
                            </div>
                        </>
                    )}
                < div className="bg-light p-3 mb-0">
                    Lorem Ipsum Dolor Sit Amet, Consetetur Sadipscing Elitr, Sed Diam Nonumy Eirmod Tempor Invidunt Ut Labore.
                </div>
            </div>
        </div >
    );
};

export default withAuth(CompanyView);
