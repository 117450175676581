import React, { useContext, useState } from "react";
import Button from "react-bootstrap/Button";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ActionBar from "../../../components/ui/ActionBar";
import withAuth from "../../../components/hoc/withAuth";
import OverflowText from "../../../components/ui/OverflowTooltip";
import EditMemberModal from "./EditMemberModal";
import BackButton from "../../../components/ui/BackButton";
import NewButton from "../../../components/ui/NewButton";
import GridButton from "../../../components/ui/GridButton";
import Icon from "../../../components/ui/Icon";
import NewCohortModal from "../../cohort-management/NewCohortModal";
import { MemberData } from './MemberData';
import { formatPhoneNumber, pickProps, toDate } from '../../../utils/utils';
import { updateMember } from "../../../ducks/cohort";
import messages from "../../../utils/helper/messages";
import { AlertContext } from "../../../context/AlertContext";
import {
    ALERT_TYPE,
    COHORT_STATUS,
    RETENTION_MANAGEMENT_MEMBER_STATUS,
    RETENTION_MANAGEMENT_MEMBER_STATUS_VALUE
} from "../../../constants";
import CohortMemberIdModal from "./CohortMemberIdModal";

const MemberList = () => {
    const { cohortId, cohortStatus } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { showAlert } = useContext(AlertContext);

    const [confirmModal, setConfirmModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [currentMember, setCurrentMember] = useState(null);
    const [addModal, setAddModal] = useState(false);
    const [openCohortMemberIdModal, setOpenCohortMemberIdModal] = useState(false);
    const [currentCohortId, setCurrentCohortId] = useState(null);

    const columns = [
        {
            id: 'firstName',
            header: 'Name',
            cell: ({ row, cell }) => {
                let name = `${row.original.firstName} ${row.original.lastName}`;
                return (<OverflowText id={cell.id}>{name}</OverflowText>)
            },
            maxSize: 100,
        },
        {
            id: 'addressLine1',
            header: 'Address',
            cell: ({ row, cell }) => {
                let address = `${row.original.addressLine1} ${row.original.addressLine2 ?? ' '} ${row.original.state} ${row.original.postalCode}`;
                return (<OverflowText id={cell.id}>{address}</OverflowText>)
            },
            maxSize: 200,
        },
        {
            id: 'birthday',
            header: 'Birthday',
            accessorKey: 'birthday',
            accessorFn: row => (new Date(row.birthday)),
            cell: ({ row }) => `${toDate(row.original.birthday)}`,
            minSize: 100,
        },
        {
            id: 'email',
            header: 'Email',
            accessorKey: 'email',
            cell: ({ row, cell }) => (<OverflowText id={cell.id}>{row.original.email}</OverflowText>),
            maxSize: 200,
        },
        {
            id: 'phone',
            header: 'Phone',
            accessorKey: 'phone',
            cell: ({ row }) => (<div className="text-nowrap">{formatPhoneNumber(row.original.phone)}</div>),
            size: 120,
        },
        {
            id: 'enrollDate',
            header: 'Enroll Date',
            accessorKey: 'enrollDate',
            accessorFn: row => (new Date(row.enrollDate)),
            cell: ({ row }) => `${toDate(row.original.enrollDate)}`,
            minSize: 100,
        },
        {
            id: 'terminationDate',
            header: 'Termination Date',
            accessorKey: 'terminationDate',
            accessorFn: row => (new Date(row.terminationDate)),
            cell: ({ row }) => `${toDate(row.original.terminationDate)}`,
            minSize: 100,
        },
        {
            id: 'contract',
            header: 'Contract',
            accessorKey: 'contract',
        },
        {
            id: 'pbpId',
            header: 'PBP ID',
            accessorKey: 'pbpId',
        },
        {
            id: 'salesChannelName',
            header: 'Sales Channel Name',
            accessorKey: 'salesChannelName',
        },
        {
            id: 'status',
            header: 'Status',
            accessorKey: 'status',
            accessorFn: row => (RETENTION_MANAGEMENT_MEMBER_STATUS_VALUE[row.status.trim()]),
        },
        {
            name: 'actions',
            header: 'Actions',
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View Cohort ID"
                        onClick={() => handleViewCohortMember(row.original)}>
                        <Icon icon="cohort-id" size={14} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Edit"
                        onClick={() => handleEditCohortMember(row.original)}>
                        <Icon icon="edit" size={14} />
                    </GridButton>
                </>
            )
        },
    ];

    const handleViewCohortMember = (data) => {
        setOpenCohortMemberIdModal(true);
        const cohortId = pickProps(data, ['id'])
        setCurrentCohortId({ ...cohortId });
    };

    const handleEditCohortMember = (member) => {
        setConfirmModal(true);
        setCurrentMember({
            ...member,
            birthday: toDate(member.birthday),
            enrollDate: toDate(member.enrollDate),
            terminationDate: toDate(member.terminationDate),
            excludeMember: member.status.trim() === RETENTION_MANAGEMENT_MEMBER_STATUS.REMOVED ? [RETENTION_MANAGEMENT_MEMBER_STATUS.REMOVED] : []
        });
    }

    const handleConfirm = async (formData) => {
        setIsLoading(true);
        editMemberData({
            ...formData,
            cohortId: currentMember.cohortId
        });
        setIsLoading(false);
        handleConfirmClose();
    }

    const editMemberData = (formData) => {
        dispatch(updateMember(formData));
        showAlert(messages.success.updateCohortMember, ALERT_TYPE.SUCCESS);
    }

    const handleConfirmClose = () => {
        setConfirmModal(false);
    }

    const handleAddModalClose = () => {
        setAddModal(false);
    }

    return (
        <>
            <ActionBar back={true}>
                <BackButton
                    onBack={() => navigate('/admin/cohort-management')}
                />
                <div className="d-flex">
                    {cohortStatus !== COHORT_STATUS.SCHEDULED &&
                        cohortStatus !== COHORT_STATUS.ENRICHED && (
                            <Button
                                type="button"
                                variant="secondary"
                                className="me-2"
                                onClick={() =>
                                    navigate(
                                        `/admin/cohort-management/edit/${cohortId}`
                                    )
                                }>
                                Re-upload
                            </Button>
                        )}
                    <NewButton onClick={() => setAddModal(true)} />
                </div>
            </ActionBar>

            <MemberData cohortId={cohortId} columns={columns} />

            <EditMemberModal
                loading={isLoading}
                show={confirmModal}
                member={currentMember}
                variant="danger"
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />

            <NewCohortModal
                show={addModal}
                onCancel={handleAddModalClose}
                onUpload={handleAddModalClose}
            />
            <CohortMemberIdModal
                show={openCohortMemberIdModal}
                data={currentCohortId}
                onCancel={() => setOpenCohortMemberIdModal(false)}
            />
        </>
    );
}

export default withAuth(MemberList);
