import { useState } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Card } from 'react-bootstrap';
import Blog from './Blog';
import Login from "../Login";
import styles from './Home.module.scss';
import Icon from "../../components/ui/Icon";
import ProductUpdates from "./ProductUpdates";
import ContentCard from "./ContentCard";
import { CONTENT_TYPE } from "../../constants";

// PDFs
import ContentFileModal from '../../components/ui/ContentFileModal';

const Home = () => {
    const [showFileModal, setShowFileModal] = useState(false);
    const [blogContent, setBlogContent] = useState(null);

    const openFile = (content) => {
        setBlogContent(content);
        setShowFileModal(true);
    }

    return (
        <>
            <UnauthenticatedTemplate>
                <Login />
            </UnauthenticatedTemplate>

            <AuthenticatedTemplate>
                <>
                    <div className={`${styles.container} pt-4`}>
                        <div className={styles.box1}>
                            <Card className={`border-1 h-100 ${styles.box} ${styles.borderDarkSalmon}`}>
                                <Card.Body className="d-flex flex-column">
                                    <h3 className={`${styles.sectionHeader} flex-shrink-0 mb-3`}><Icon icon="graph-of-the-month" size={22} className={`text-muted me-2 ${styles.colorDarkSalmon}`} /><strong>Graph of the Month</strong></h3>
                                    <ContentCard openFile={openFile} type={CONTENT_TYPE.GraphOfMonth} title='Graph of the Month' contentLink='View graph' />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={styles.box2}>
                            <Card className={`border-1 h-100 ${styles.box} ${styles.borderBlue}`}>
                                <Card.Body className="d-flex flex-column">
                                    <h3 className={`${styles.sectionHeader} flex-shrink-0 mb-3`}><Icon icon="external-viewport" size={22} className={`text-muted me-2 ${styles.colorBlue}`} /><strong>External Viewpoint</strong></h3>
                                    <ContentCard openFile={openFile} type={CONTENT_TYPE.News} title='External Viewpoint' contentLink='View survey' />
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={styles.blog}>
                            <Blog customStyle={`border-1 h-100 ${styles.box} ${styles.borderBlack} ${styles.noHover}`} openFile={openFile} />
                        </div>
                        <div className={`flex-grow-1 mb-n3 ${styles.section} ${styles.desc}`}>
                            <h3 className={`${styles.sectionHeaderLarge}`}><strong>Product Updates</strong></h3>
                            <ProductUpdates openFile={openFile} />
                        </div>
                    </div>

                    {(blogContent && showFileModal) && <ContentFileModal
                        show={showFileModal}
                        contentId={blogContent.id}
                        title={blogContent.title}
                        contentType={blogContent.type}
                        className={styles.viewContent}
                        modalClose={() => setShowFileModal(false)}
                    />}
                </>
            </AuthenticatedTemplate>
        </>
    );
}

export default Home;
