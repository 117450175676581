import React, { useContext } from 'react';
import {  useSelector, useDispatch } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import Loader from '../../../../components/ui/Loader';
import { POSITIONS, ALIGN, ALERT_TYPE } from '../../../../constants';
import { getNameList, optionValues } from '../../../../utils/utils';
import { updateMeetingLocationSupportStaff, getAdvisoryGroupMeetingLocations } from '../../../../ducks/advisoryGroup';
import messages from '../../../../utils/helper/messages';
import { AlertContext } from '../../../../context/AlertContext';

const SupportStaffDetailsModal = ({ show, onCancel, data, meetingId }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const meetingLocationSupportStaffDetails = useSelector(state => state.advisoryGroup?.meetingLocationSupportStaffDetails);

    const meetingLocationSupportStaffOptions = optionValues(getNameList(meetingLocationSupportStaffDetails), true);

    const meetingLocationSupportStaffData = meetingLocationSupportStaffDetails?.find(supportStaff => supportStaff.id === data.staffId);

    const meetingLocationSupportStaffInfo = {...meetingLocationSupportStaffData, hostName: data.hostName};

    const arr = [
        {
            key: 'host',
            labelName: 'Host Name',
            placeholderName: 'Host Name',
            type: 'options',
            optionValues: meetingLocationSupportStaffOptions
        },
    ];

    const supportStaffArr = [
        {
            key: 'hostName',
            labelName: 'Host Name',
            placeholderName: 'Host Name',
            required: false
        },
        {
            key: 'specificAgShortName',
            labelName: 'Specific AG',
            placeholderName: 'Specific AG',
            required: false,
        },
        {
            key: 'organization',
            labelName: 'Organization',
            placeholderName: 'Organization',
            required: false
        },
        {
            key: 'email',
            labelName: 'Email',
            placeholderName: 'Email',
            required: false
        },
        {
            key: 'cellPhone',
            labelName: 'Cellphone',
            placeholderName: 'Cellphone',
            required: false
        },
        {
            key: 'role',
            labelName: 'Role',
            placeholderName: 'Role',
            required: false
        }
    ];

    const onHideCallback = () => {
        onCancel();
    };

    const handleSuccess = () => {
        showAlert(messages.success.addMeetingLocationSupportStaff, ALERT_TYPE.SUCCESS);
        dispatch(getAdvisoryGroupMeetingLocations(meetingId));
        onCancel();
    };

    const handleError = (error) => {
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleSubmit = (formData) => {
        const submitData = {
            id: data?.id,
            supportStaffId: formData?.host
        };
        dispatch(updateMeetingLocationSupportStaff(submitData, handleSuccess, handleError));
    };

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>
                    {data?.hostName ? 'Host Details' : 'Select Location Host'}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    {meetingLocationSupportStaffDetails === null ? <Loader /> : (
                        <SharedForm
                            cols={12}
                            array={data?.hostName ? supportStaffArr : arr}
                            modelObj={data?.hostName ? meetingLocationSupportStaffInfo : data}
                            onSubmit={handleSubmit}
                            onCancel={onHideCallback}
                            actionBarPosition={POSITIONS.TOP}
                            actionBarAlign={ALIGN.RIGHT}
                            submitButtonText="Save"
                            viewOnly={data?.hostName ? true : false}
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default SupportStaffDetailsModal;
