import React, { useState } from "react";
import SessionTimeoutDialog from "./SessionTimeoutDialog";
import {IdleTimerProvider} from "react-idle-timer";
import {useIsAuthenticated} from "@azure/msal-react";
import * as SessionHandler from "../services/sessionHandler";


let countdownInterval;
let timeout;

export default function SessionTimeout() {

    const isAuthenticated = useIsAuthenticated();

    const [timeoutModalOpen, setTimeoutModalOpen] = useState(false);
    const [timeoutCountdown, setTimeoutCountdown] = useState(0);

    const clearSessionTimeout = () => {
        clearTimeout(timeout);
    };

    const clearSessionInterval = () => {
        clearInterval(countdownInterval);
    };

    const handleLogout = async (isTimeout) => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
        localStorage.removeItem('mainNavItem');
        localStorage.removeItem('pageName');

        let redirectURL = isTimeout ? `${window.location.origin}/session-expired` : `${window.location.origin}`;
        await SessionHandler.handleLogout(redirectURL);
    };

    const handleContinue = () => {
        setTimeoutModalOpen(false);
        clearSessionInterval();
        clearSessionTimeout();
    };

    const onActive = () => {
        if (!timeoutModalOpen) {
            clearSessionInterval();
            clearSessionTimeout();
        }
    };

    const onIdle = () => {
        const delay = 5000; // 5 seconds
        if (isAuthenticated && !timeoutModalOpen) {
            timeout = setTimeout(() => {
                let countDown = 10; // in seconds
                setTimeoutModalOpen(true);
                setTimeoutCountdown(countDown);
                countdownInterval = setInterval(async () => {
                    if (countDown > 0) {
                        setTimeoutCountdown(--countDown);
                    } else {
                        await handleLogout(true);
                    }
                }, 1000);
            }, delay);
        }
    };

    return (
        <>
            <IdleTimerProvider
                onActive={onActive}
                onIdle={onIdle}
                debounce={250}
                timeout={900000} // 15 mins; in milliseconds
            />
            <SessionTimeoutDialog
                countdown={timeoutCountdown}
                onContinue={handleContinue}
                onLogout={async () => await handleLogout(false)}
                open={timeoutModalOpen}
            />
        </>
    );
}
