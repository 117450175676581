import api from "../../services/api/contentService";
import keyMirror from "../../utils/keyMirror";

export const actionTypes = keyMirror(
    {
        GET_CONTENT_DETAILS: null,
        GET_ARCHIVED_CONTENT: null,
        GET_APPLICATION_CONTENT: null,
        GET_CONTENT_DETAILS_BY_ID: null,
        RESET_CONTENT: null,
        CREATE_CONTENT: null,
        UPDATE_CONTENT: null,
        ARCHIVE_CONTENT: null,
        UPDATE_CONTENT_PARAMS: null,
        GET_CONTENT_FILE: null,
    },
    "Content"
);

// Action Creators
export const getContent = () => dispatch => {
    api.GetContentData().then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_CONTENT_DETAILS,
            payload: data
        });
    });
};

export const getArchivedContent = (status) => dispatch => {
    api.GetContentDataByStatus(status).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_ARCHIVED_CONTENT,
            payload: data
        });
    });
}

export const getContentByApplication = (app) => dispatch => {
    api.GetContentDataByApplication(app).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_APPLICATION_CONTENT,
            payload: data
        });
    });
}

export const getContentById = (contentId = null) => dispatch => {
    api.GetContentDataById(contentId).then(response => {
        const { data } = response;
        dispatch({
            type: actionTypes.GET_CONTENT_DETAILS_BY_ID,
            payload: data
        });
    });
};

export const createContent = (data, onUpload, successHandler, errorHandler) => dispatch => {
    api.CreateContentData(data, onUpload).then(() => {
        dispatch({
            type: actionTypes.CREATE_CONTENT,
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    })
};

export const updateContent = (data, onUpload, successHandler, errorHandler) => dispatch => {
    api.UpdateContentData(data, onUpload).then(() => {
        dispatch({
            type: actionTypes.UPDATE_CONTENT,
            payload: data
        });
        successHandler();
    }).catch((error) => {
        errorHandler(error);
    })
};

export const archiveContent = (data, successHandler) => dispatch => {
    api.UpdateContentData(data).then(() => {
        dispatch({
            type: actionTypes.ARCHIVE_CONTENT,
            payload: data
        });
    });
    successHandler();
};

export const getContentFile = (id, successHandler, errorHandler) => dispatch => {
    api.GetContentFile(id).then((response) => {
        const { data } = response;
        dispatch({
            type: actionTypes.UPDATE_CONTENT_PARAMS,
            payload: { id: id, fileURL: data },
        });
        successHandler();
    }).catch(function (error) {
        console.log(error);
        errorHandler();
    })
}


// Reducer

export const initialState = {
    contents: null,
    archivedContents: null,
    appContent: null, // Filtered content
};

export default function contentReducer(state = initialState, { type, payload }) {
    switch (type) {
        case actionTypes.GET_CONTENT_DETAILS:
            return {
                ...state,
                contents: [...payload]
            }
        case actionTypes.GET_ARCHIVED_CONTENT:
            return {
                ...state,
                archivedContents: [...payload]
            }
        case actionTypes.GET_APPLICATION_CONTENT:
            return {
                ...state,
                appContent: [...payload]
            }
        case actionTypes.GET_CONTENT_DETAILS_BY_ID: {
            return {
                ...state,
                contents: [
                    { ...payload, fileURL: '' }
                ],
            };
        }
        case actionTypes.UPDATE_CONTENT_PARAMS: {
            let contentsCopy = [...state.contents];
            const contentIndex = contentsCopy.findIndex(element => element.id === payload.id);
            contentsCopy[contentIndex] = { ...contentsCopy[contentIndex], ...payload };

            return {
                ...state,
                contents: [...contentsCopy]
            }
        }
        case actionTypes.UPDATE_CONTENT: {
            let contentsCopy = [...state.contents];
            const contentIndex = contentsCopy.findIndex(element => element.id === payload.id);
            contentsCopy[contentIndex] = { ...payload };

            return {
                ...state,
                contents: [...contentsCopy]
            }
        }
        case actionTypes.ARCHIVE_CONTENT: {
            let contentsCopy = [...state.contents];
            const contentIndex = contentsCopy.findIndex(element => element.id === payload.id);
            const archivedContent = contentsCopy[contentIndex];
            contentsCopy.splice(contentIndex, 1);

            return {
                ...state,
                contents: [...contentsCopy],
                archivedContents: [...state.archivedContents, archivedContent],
            }
        }
        case actionTypes.GET_CONTENT_FILE: {
            let contentsCopy = [...state.contents];
            const contentIndex = contentsCopy.findIndex(element => element.id === payload.id);
            contentsCopy[contentIndex] = { ...contentsCopy[contentIndex], fileURL: payload.data };
            return {
                ...state,
                contents: [...contentsCopy]
            };
        }

        default:
            return state;
    }
}
