import React from "react";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../../components/ui/Form";
import { ALIGN, POSITIONS } from "../../../../constants";

const RepScoreModal = ({ show, onCancel, data, viewOnly }) => {
    const arr = [
        { key: 'modifiedDateTime', labelName: 'Date', type: 'date' },
        { key: 'type', labelName: 'Scorecard Type', type: 'text' },
        { key: 'aggregateScore', labelName: 'Aggregate Score', type: 'text' },
    ];

    const onHideCallback = () => {
        onCancel();
    }

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Scorecard - Last Baseline Date</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        onCancel={onHideCallback}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={'Submit'}
                        viewOnly={viewOnly}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default RepScoreModal;
