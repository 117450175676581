import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../components/ui/Form";
import {
    AG_RECRUITMENT_COMMUNICATION_TYPE,
    AG_RECRUITMENT_MEMBER_STATUS, AG_RECRUITMENT_RETIREMENT_REASON, AG_STATUS,
    ALERT_TYPE,
    ALIGN,
    POSITIONS,
    VALIDATIONS,
} from '../../../constants';
import { AlertContext } from "../../../context/AlertContext";
import { updateAdvisoryGroupRecruitmentMemberData, updateAdvisoryGroupStatus } from '../../../ducks/advisoryGroup';
import messages from "../../../utils/helper/messages";
import { isObjectEmpty, optionValues, pickProps } from "../../../utils/utils";

const MemberRecordModal = ({ show, data, agInfo, onUpdateAgStatus, onCancel }) => {

    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);

    const [isLoading, setIsLoading] = useState(false);
    const [memberDetails, setMemberDetails] = useState(null);

    const arr = [
        {
            key: 'companyId',
            labelName: 'Company ID',
            placeholderName: 'Company ID',
            type: 'text',
            disabled: true,
        },
        {
            key: 'applicationHealthPlanId',
            labelName: 'Health Plan ID',
            placeholderName: 'Health Plan ID',
            type: 'text',
            required: false,
        },
        {
            key: 'firstName',
            labelName: 'First Name',
            placeholderName: 'First Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'lastName',
            labelName: 'Last Name',
            placeholderName: 'Last Name',
            type: 'text',
            maxLength: 35,
            valid: VALIDATIONS.String.isName,
        },
        {
            key: 'memberStatus',
            labelName: 'Member Status',
            placeholderName: 'Member Status',
            type: 'options',
            optionValues: optionValues(AG_RECRUITMENT_MEMBER_STATUS),
            required: false,
        },
        {
            key: 'lastCommunicationType',
            labelName: 'Last Communication Type',
            placeholderName: 'Last Communication Type',
            type: 'options',
            optionValues: optionValues(AG_RECRUITMENT_COMMUNICATION_TYPE),
        },
        {
            key: 'memberCommunicationLastDate',
            labelName: 'Last Communication Date',
            placeholderName: 'Last Communication Date',
            type: 'date',
            required: false,
        },
        {
            key: 'memberStartDate',
            labelName: 'Member Start Date',
            placeholderName: 'Member Start Date',
            type: 'date',
            required: false,
            maxDepends: 'memberRetireDate',
        },
        {
            key: 'memberRetireDate',
            labelName: 'Member Retire Date',
            placeholderName: 'Member Retire Date',
            type: 'date',
            required: false,
            minDepends: 'memberStartDate',
        },
        {
            key: 'retirementReason',
            labelName: 'Retirement Reason',
            placeholderName: 'Retirement Reason',
            type: 'options',
            optionValues: optionValues(AG_RECRUITMENT_RETIREMENT_REASON, true),
            required: false,
            disabled: true,
            conditional: true,
            dependsOn: 'memberStatus',
            dependsValue: [AG_RECRUITMENT_MEMBER_STATUS.Retired]
        },
    ];

    const onHideCallback = () => {
        setMemberDetails(null);
        onCancel();
    }

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(messages.success.updateMemberRecord, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleMemberDetailsChange = (update) => {
        setMemberDetails((details) => ({ ...details, ...update }));
    }

    const handleInputChange = (name, value) => {
        handleMemberDetailsChange({ [name]: value });
    };

    const isValidMemberInfo = () => {
        return !(isObjectEmpty(memberDetails));
    }

    const updateAgStatus = () => {
        const updateData = {
            id: agInfo.id,
            status: AG_STATUS.Active
        };
        dispatch(updateAdvisoryGroupStatus(updateData, () => {
            onUpdateAgStatus();
        }, handleError));
    }

    const handleSubmit = async () => {
        setIsLoading(true);
        const submitData = {
            id: data.id,
            applicationId: data.applicationId,
            companyId: data.companyId,
            lastUsertoUpdateRecord: currentUser?.id,
            firstName: memberDetails.firstName,
            lastName: memberDetails.lastName,
            applicationHealthPlanId: memberDetails.applicationHealthPlanId,
            memberStatus: memberDetails.memberStatus,
            memberCommunicationLastDate: memberDetails.memberCommunicationLastDate,
            lastCommunicationType: memberDetails.lastCommunicationType,
            memberStartDate: memberDetails.memberStartDate,
            memberRetireDate: memberDetails.memberRetireDate,
            retirementReason: memberDetails.retirementReason,
        };

        dispatch(updateAdvisoryGroupRecruitmentMemberData(submitData, () => {
            handleSuccess();
            // check: if member status is changed to selected or enrolled AND the AG current status is New
            // action: update AG status to Active
            if (
                [AG_RECRUITMENT_MEMBER_STATUS.Selected, AG_RECRUITMENT_MEMBER_STATUS.Enrolled].includes(submitData.memberStatus) &&
                agInfo.status === AG_STATUS.New &&
                submitData.memberStatus !== data.memberStatus // only if memberStatus is updated
            ) {
                updateAgStatus();
            }
        }, handleError));
    }

    useEffect(() => {
        if (data) {
            const initialData = pickProps(data, [
                'firstName',
                'lastName',
                'applicationHealthPlanId',
                'memberStatus',
                'memberCommunicationLastDate',
                'lastCommunicationType',
                'memberStartDate',
                'memberRetireDate',
                'retirementReason',
            ]);
            setMemberDetails(initialData);
        }
    }, [data]);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>AG Member Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        loading={isLoading}
                        onInputChanged={handleInputChange}
                        onSubmit={handleSubmit}
                        onCancel={onHideCallback}
                        disabled={!isValidMemberInfo()}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default MemberRecordModal;
