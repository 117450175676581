import { useRef, useState } from 'react';
import {useNavigate} from "react-router-dom";
import {Form, Button, Row, Col} from "react-bootstrap";
import {FaFilter, FaSave} from "react-icons/fa";
import {IoMdArrowRoundBack} from "react-icons/io";
import withAuth from "../../../components/hoc/withAuth";
import ActionBar from "../../../components/ui/ActionBar";
import Placeholder from "../../../components/ui/Placeholder";
import LoadingButton from "../../../components/ui/LoadingButton";

const RiskIdentification = () => {
    const navigate = useNavigate();
    const inputRef = useRef(null);
    const [isLoading, setIsLoading] = useState(false);

    const onSave = async () => {
        // on save
        setIsLoading(true);

        try {
            // await api.method()
        } catch (error) {
            console.log(error);
        }

        setIsLoading(false);
    }

    return (
        <div className="h-100 d-flex flex-column">
            <ActionBar>
                <Row className="justify-content-end w-75">
                    <Col lg={10} xl={8} xxl={6} className="d-flex">
                        <Button type="button" className="flex-shrink-0" variant="light" onClick={() => navigate(-1)}>
                            <IoMdArrowRoundBack className="me-1" />Back
                        </Button>
                        <div className="vr mx-2" />
                        <div className="me-2 w-100">
                            <Form.Control
                                type="text"
                                placeholder="Cohort Name"
                                ref={inputRef}
                                value={""}
                                onChange={(e) => e}
                            />
                        </div>
                        <Button type="button" className="me-2 fw-bold flex-shrink-0" variant="light">
                            <FaFilter size={14} className="me-2" />Filter
                        </Button>
                        <LoadingButton type="button" className="me-2 fw-bold flex-shrink-0" disabled={isLoading} loading={isLoading} onClick={onSave}>
                            <FaSave size={14} className="me-2" />Save
                        </LoadingButton>
                    </Col>
                </Row>
            </ActionBar>
            <div className="flex-grow-1">
                <Placeholder title="Risk Report" />
            </div>
        </div>
    );
}

export default withAuth(RiskIdentification);
