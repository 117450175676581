import { Col, Row } from 'react-bootstrap';
import { FaFileInvoiceDollar, FaHospital, FaUserNurse } from 'react-icons/fa';
import Blog from '../Blog';
import InfoCard from '../../components/ui/InfoCard';
import withAuth from '../../components/hoc/withAuth';

const QualityManagementHome = () => {
    return (
        <Row className="h-100">
            <Col md={6} className="d-flex">
               <div>
                    <p className="lead p-3">The <span className="fw-bold">Predict Health Quality Management</span> application provides users with data and insights related to industry standard measures of quality.
                        Select one of the surveys below to explore this data in more detail.</p>

                   <InfoCard title="Hospital Quality" icon={FaHospital} link="/quality-management/hospital">
                       <p className="mb-0">The Hospital Survey is oriented around the measurements used in the CMS Hospital Stars initiative. Predict Health has enriched this core data with descriptive data from other sources.</p>
                   </InfoCard>

                   <InfoCard title="Payer Quality" icon={FaFileInvoiceDollar}>
                       <p className="mb-0">The Payer Survey is built around two related sets of data: the CMS Stars results and a
                           proprietary set of data on health plan operations as it relates to CMS Stars and other
                           quality initiatives</p>
                   </InfoCard>

                   <InfoCard title="SNF Quality" icon={FaUserNurse}>
                       <p className="w-100 mb-0">Under Construction</p>
                   </InfoCard>

               </div>
            </Col>
            <Col md={6} className="pt-md-3 ps-md-4">
                <Blog />
            </Col>
        </Row>
    );
}

export default withAuth(QualityManagementHome);
