import React from "react";
import withAuth from '../../../components/hoc/withAuth';
import Placeholder from '../../../components/ui/Placeholder';

const HealthEquity = () => {
    return (
        <Placeholder title='Health Equity' type="graphic" />
    );
};

export default withAuth(HealthEquity);
