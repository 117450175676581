import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'react-bootstrap';
import SharedForm from "../../../components/ui/Form";
import { ALERT_TYPE, ALIGN, FORM_ACTION_TYPE, POSITIONS, VALIDATIONS } from "../../../constants";
import { addPortalDetails, getPortalDetails, updatePortalDetails } from "../../../ducks/advisoryGroup";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import { AlertContext } from "../../../context/AlertContext";
import { isObjectEmpty, toDateTime, checkObjectsEqual } from "../../../utils/utils";
import messages from "../../../utils/helper/messages";
import ConfirmModal from "../../../components/ui/ConfirmModal";

const MemberPortalModal = ({ data, action, show, onCancel }) => {
    const dispatch = useDispatch();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user?.currentUser);
    const portal = useSelector(state => state.advisoryGroup?.currentMemberPortal);

    const [portalData, setPortalData] = useState(null);
    const [isLoading, setIsLoading] = useState(null);
    const [isEditMode, setIsEditMode] = useState(true);
    const [confirmModal, setConfirmModal] = useState(false);
    const [memberData, setMemberData] = useState({});
    const [isInputChanged, setIsInputChanged] = useState(false);

    const memberPortalData = {
        shortName: data.shortName,
        portalDescription: portal?.portalDescription ? portal.portalDescription : null,
        portalURL: portal?.portalURL ? portal.portalURL : null,
        portalModifiedDate: portal?.portalModifiedDate ? toDateTime(portal.portalModifiedDate) : null,
        portalModifiedUser: portal?.portalModifiedFirstName ? `${portal.portalModifiedFirstName} ${portal?.portalModifiedLastName}` : null,
    };

    const arr = [
        { key: 'shortName', labelName: 'AG Short Name', type: 'text', disabled: true },
        { key: 'portalDescription', labelName: 'Portal Member Description', type: 'textarea', maxLength: 64, valid: VALIDATIONS.String.noSpecialChars, required: false, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'portalURL', labelName: 'Portal Member URL', type: 'text', maxLength: 128, valid: VALIDATIONS.String.isURL, disabled: action === FORM_ACTION_TYPE.View },
        { key: 'portalModifiedDate', labelName: 'Last Update Date', type: 'text', disabled: true, required: false },
        { key: 'portalModifiedUser', labelName: 'Last Update User', type: 'text', disabled: true, required: false },
    ];

    const handleSuccess = () => {
        setIsLoading(false);
        showAlert(portal.id ? messages.success.updatePortal : messages.success.addPortal, ALERT_TYPE.SUCCESS);
        onCancel();
    }

    const handleError = (error) => {
        setIsLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = (formData) => {
        setIsLoading(true);

        const portalId = portal?.id;

            if (portalId) {
                // update portal
                const submitData = {
                    id: portal.id,
                    portalModifiedBy: currentUser?.id,
                    ...formData
                }

                dispatch(updatePortalDetails(submitData, handleSuccess, handleError));
            } else {
                // create portal
                const submitData = {
                    agId: data.id,
                    companyId: data.companyId,
                    portalCreatedBy: currentUser?.id,
                    portalModifiedBy: currentUser?.id,
                    ...formData
                }

                dispatch(addPortalDetails(submitData, handleSuccess, handleError));
            }

    };

    useEffect(() => {
        if(isAppLoaded) {
            if (!currentUser) {
                return;
            }
            if (data?.id) {
                dispatch(getPortalDetails(data.id))
            }
        }
    }, [isAppLoaded, currentUser, data?.id]);

    useEffect(() => {
        if (!isObjectEmpty(portal)) {
            setIsEditMode(true);
            setPortalData(memberPortalData);
            setMemberData(memberPortalData);
        }
    }, [portal]);

    useEffect(() => {
        if (data && !portal) {
            setIsEditMode(false);
            setPortalData({
                shortName: data.shortName,
            });
            setMemberData({
                shortName: data.shortName,
            });
        }
    }, [data, portal]);

    const handleCancel = () => {
        if (action === FORM_ACTION_TYPE.Edit && !checkObjectsEqual(memberPortalData, portalData) && isInputChanged) {
            setConfirmModal(true);
        } else {
            setPortalData(null);
            setMemberData(null);
            onCancel();
        }
    };

    const handleConfirm = () => {
        setPortalData(null);
        setMemberData(null);
        handleConfirmClose();
        onCancel();
        setIsInputChanged(false);
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setIsInputChanged(false);
    };

    const inputChange = (name, value) => {
        handlePortalDetailsChange({ [name]: value });
    };

    const handlePortalDetailsChange = (update) => {
        setIsInputChanged(true);
        setPortalData(details => ({ ...details, ...update }));
    };

    const isValidPortalInfo = () => {
        if (isObjectEmpty(portalData)) {
            return false;
        }
        if (isEditMode) {
            return !(
                memberData.portalURL === portalData.portalURL &&
                memberData.portalDescription === portalData.portalDescription
            )
        } else {
            return portalData?.portalURL !== ""
        }
    }

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>{isEditMode ? 'Update' : 'Add'} AG Management Portal Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={memberData}
                        loading={isLoading}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Save"
                        disabled={!isValidPortalInfo()}
                        onInputChanged={inputChange}
                    />
                </div>
                <ConfirmModal
                    show={confirmModal}
                    message={messages.confirm.discardProgramEdit}
                    onConfirm={handleConfirm}
                    onCancel={handleConfirmClose}
                    onHideCallback={handleConfirmClose}
                />
            </Modal.Body>
        </Modal>
    );
}

export default MemberPortalModal;
