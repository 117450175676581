import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import DataGrid from "../../components/DataGrid";
import OverflowText from "../../components/ui/OverflowTooltip";
import withAuth from "../../components/hoc/withAuth";
import api from "../../services/api/contentService";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import styles from "./Blog.module.scss";
import { isUser, toDateTime } from "../../utils/utils";
import {
    AUDIENCE_APPLICATION,
    AUDIENCE_SEGMENT,
    CMS_CONTENT_TYPE,
    CMS_CONTENT_FORMAT, MIME_TYPES,
    AUDIENCE_USER_ROLE
} from "../../constants";
import Loader from '../../components/ui//Loader';

const ProductUpdates = ({ openFile }) => {
    const [productUpdatesdata, setProductUpdatesdata] = useState(null);
    const currentUser = useSelector((state) => state.user.currentUser);

    const isAppLoaded = useContext(AppLoadContext);


    const handleClick = (blog) => {
        const { id, title, format } = blog;
        const type = MIME_TYPES[CMS_CONTENT_FORMAT[format]];
        const content = { id, title, type };
        openFile(content);
    };

    const columns = [
        {
            id: 'updatedDateTime',
            header: 'Date Updated',
            accessorKey: 'updatedDateTime',
            sortingFn: 'datetime',
            accessorFn: (row) => (new Date(row.updatedDateTime)),
            cell: ({ row }) => `${toDateTime(row.original.updatedDateTime)}`,
        },
        {
            id: 'type',
            header: 'Content Type',
            accessorKey: 'type',
            accessorFn: (row) => `${CMS_CONTENT_TYPE[row.type]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {CMS_CONTENT_TYPE[row.original.type]}
                </OverflowText>
            ),
            enableColumnFilter: false,
            minSize: 180,
            size: 180,
        },
        {
            id: 'title',
            header: 'Title',
            accessorKey: 'title',
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>{row.original.title}</OverflowText>
            ),
            minSize: 200,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
        {
            id: 'audienceSegment',
            header: 'Segment',
            accessorKey: 'audienceSegment',
            accessorFn: (row) => `${AUDIENCE_SEGMENT[row.audienceSegment]}`,
            cell: ({ row }) => AUDIENCE_SEGMENT[row.original.audienceSegment],
            size: 155,
        },
        {
            id: 'audienceApplication',
            header: 'Application',
            accessorKey: 'audienceApplication',
            accessorFn: (row) => `${AUDIENCE_APPLICATION[row.audienceApplication]}`,
            cell: ({ row, cell }) => (
                <OverflowText id={cell.id}>
                    {AUDIENCE_APPLICATION[row.original.audienceApplication]}
                </OverflowText>
            ),
            size: 165,
        },
        {
            id: 'fileName',
            header: 'Content',
            accessorKey: 'fileName',
            cell: ({ row }) => (
                <h4 className={`mb-1 ${styles.blogTitle}`} onClick={() => handleClick(row.original)}>
                    <span>{row.original.fileName}</span>
                </h4>
            ),
            maxSize: 250,
            enableColumnFilter: false,
            sortingFn: 'text'
        },
    ];

    const hideColumn = {
        updatedDateTime: false,
    };
    const defaultSort = [
        {
            id: 'updatedDateTime',
            desc: true,
        },
    ];

    useEffect(() => {
        if (isAppLoaded) {
            api.GetProductUpdatesData().then((res) => {
                let content = res.data;
                if (isUser(currentUser)) {
                    content = content.filter((data) => AUDIENCE_USER_ROLE[data.audienceUser] === AUDIENCE_USER_ROLE.A
                        || AUDIENCE_USER_ROLE[data.audienceUser] === AUDIENCE_USER_ROLE.U)
                }
                setProductUpdatesdata(content)
            });
        }
    }, [isAppLoaded]);

    return (
        <>
            {productUpdatesdata === null ? (
                <Loader />
            ) : (
                <DataGrid
                    columns={columns}
                    data={productUpdatesdata}
                    columnDisplay={hideColumn}
                    sort={defaultSort}
                />
            )}
        </>
    );
}

export default withAuth(ProductUpdates);
