import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import withAuth from '../../../components/hoc/withAuth';
import Placeholder from '../../../components/ui/Placeholder';
import AgSelector from '../../../components/ui/Selector';
import ActionBar from '../../../components/ui/ActionBar';
import {
    archiveRecruitmentMembers,
    getAdvisoryGroupData,
    getAdvisoryGroupRecruitmentApplicationData,
    resetAdvisoryGroupRecruitmentApplicationData,
} from '../../../ducks/advisoryGroup';
import { AG_RECRUITMENT_MEMBER_STATUS, AG_STATUS, ALERT_TYPE, FORM_ACTION_TYPE } from '../../../constants';
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import Loader from '../../../components/ui/Loader';
import DataGrid from '../../../components/DataGrid';
import { pickProps, sortingFnDateOnly, toDate } from '../../../utils/utils';
import NewButton from '../../../components/ui/NewButton';
import Icon from '../../../components/ui/Icon';
import GridButton from '../../../components/ui/GridButton';
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import RecruitmentMemberArchiveConfirmModal from './RecruitmentMemberArchiveConfirmModal';
import MemberRecordModal from './MemberRecordModal';
import MemberApplicationModal from './MemberApplicationModal';
import ProcessApplicationModal from './ProcessApplicationModal';

const ApplicationDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);

    const currentUser = useSelector((state) => state.user.currentUser);
    const advisoryGroups = useSelector((state) => state.advisoryGroup.masterList);
    const applicationData = useSelector((state) => state.advisoryGroup.applicationList);

    const [selectedAgId, setSelectedAgId] = useState(0);
    const [selectedAgStatus, setSelectedAgStatus] = useState('');
    const [agList, setAgList] = useState(null);
    const [masterChecked, setMasterChecked] = useState(false);
    const [checkedIds, setCheckedIds] = useState([]);
    // confirm modal
    const [confirmModal, setConfirmModal] = useState(false);
    const [confirmModalData, setConfirmModalData] = useState(null);
    const [selectedMemberId, setSelectedMemberId] = useState(null);
    // member, application, process modals
    const [memberModal, setMemberModal] = useState(false);
    const [applicationModal, setApplicationModal] = useState(false);
    const [processApplicationModal, setProcessApplicationModal] = useState(false);
    const [selectedApplication, setSelectedApplication] = useState(false);
    const [action, setAction] = useState(null);
    const [state] = useState(location.state || {});

    const defaultSort = [
        {
            id: 'submittedTime',
            desc: true,
        },
    ];

    const rowActions = ({ row }) => {
        return (
            <label key={row.original.id} htmlFor={`input-${row.original.id}`}>
                <input
                    id={`input-${row.original.id}`}
                    type="checkbox"
                    checked={checkedIds.includes(row.original.id)}
                    onChange={() => handleCheckboxChange(row.original.id, event)}
                />
            </label>
        );
    };

    const rowActionHeader = () => {
        return (
            <label key={0} htmlFor="masterCheck">
                <input
                    id="masterCheck"
                    type="checkbox"
                    checked={masterChecked}
                    disabled={!applicationData || applicationData?.length === 0}
                    onChange={handleMasterCheckboxChange}
                />
            </label>
        );
    };

    const columns = [
        {
            id: 'select',
            header: rowActionHeader,
            cell: rowActions,
            size: 32,
            minSize: 32,
            enableSorting: false,
        },
        {
            id: 'firstName',
            header: 'First Name',
            accessorKey: 'firstName',
            enableColumnFilter: false,
            sortingFn: 'text',
        },
        {
            id: 'lastName',
            header: 'Last Name',
            accessorKey: 'lastName',
            enableColumnFilter: false,
            sortingFn: 'text',
        },
        {
            id: 'submittedTime',
            header: 'Date of Application',
            accessorKey: 'submittedTime',
            accessorFn: (row) => toDate(row.submittedTime),
            sortType: 'date',
            sortingFn: sortingFnDateOnly,
            filterFn: 'equalsString'
        },
        {
            id: 'memberStatus',
            header: 'Status',
            accessorKey: 'memberStatus',
        },
        {
            id: 'city',
            header: 'City',
            accessorKey: 'city',
        },
        {
            id: 'primaryLanguage',
            header: 'Language',
            accessorKey: 'primaryLanguage',
            sortingFn: 'text',
        },
        {
            id: 'actions',
            header: 'Actions',
            size: 160,
            cell: ({ row }) => (
                <>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View/Edit Application"
                        onClick={() => handleApplicationModalOpen(row.original)}>
                        <Icon icon="view-application" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="View/Edit AG Member"
                        onClick={() => handleMemberModalOpen(row.original)}>
                        <Icon icon="member-record" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="me-1 my-1" title="Evaluate Application"
                        onClick={() => handleProcessApplicationModalOpen(row.original)}>
                        <Icon icon="evaluate-application" size={16} />
                    </GridButton>
                    <GridButton variant="light" size="sm" className="my-1" title="Archive"
                        onClick={() => handleGridArchive(row.original)}>
                        <Icon icon="archive" size={14} />
                    </GridButton>
                </>
            ),
        }
    ];

    const handleCheckboxChange = (memberId, event) => {
        if (event.target.checked) {
            if (!checkedIds.includes(memberId)) {
                setCheckedIds((prevIds) => [...prevIds, memberId]);
            }
        } else if (checkedIds.includes(memberId)) {
            setCheckedIds((prevIds) =>
                prevIds.filter((prevId) => prevId !== memberId)
            );
        }
    };

    const handleMasterCheckboxChange = (event) => {
        if (event.target.checked) {
            const memberIds = applicationData.map((member) => member.id);
            setCheckedIds(memberIds);
            setMasterChecked(true);
        } else {
            setCheckedIds([]);
            setMasterChecked(false);
        }
    };

    const handleSelectChange = (e) => {
        const agId = Number(e.target.value);
        setSelectedAgId(agId);
        setMasterChecked(false);

        const selectedAg = advisoryGroups?.find(ag => ag.id === agId);
        setSelectedAgStatus(selectedAg.status);
    };

    // Archive
    const handleGridArchive = (data) => {
        setConfirmModal(true);
        setSelectedMemberId(data.id);
        setConfirmModalData([
            pickProps(data, ['memberId', 'firstName', 'lastName'])
        ]);
    };

    const handleArchiveConfirm = () => {
        setConfirmModal(true);
    };

    const handleConfirmClose = () => {
        setConfirmModal(false);
        setCheckedIds([]);
        setSelectedMemberId(null);
        setMasterChecked(false);
        setConfirmModalData(null);
    };

    const handleConfirm = () => {
        let memberIds = [];
        if (checkedIds.length > 0) {
            memberIds = [...checkedIds]; // multiple select
        } else if (selectedMemberId) {
            memberIds = [selectedMemberId]; // grid row select
        }

        let memberUpdateData = [];
        for (let memberId of memberIds) {
            memberUpdateData.push({
                memberId: memberId,
                memberStatus: AG_RECRUITMENT_MEMBER_STATUS.Archive,
            });
        }

        dispatch(
            archiveRecruitmentMembers(
                memberUpdateData,
                () => {
                    showAlert(
                        messages.success.archiveRecruitmentMembers,
                        ALERT_TYPE.SUCCESS
                    );
                    dispatch(getAdvisoryGroupRecruitmentApplicationData(selectedAgId));
                    handleConfirmClose();
                },
                (error) => {
                    showAlert(
                        `${messages.error.prefix} ${error}.`,
                        ALERT_TYPE.ERROR
                    );
                }
            )
        );
    }

    // new application modal
    const handleAddApplicationModalOpen = () => {
        setApplicationModal(true);
        setSelectedApplication({
            aGId: selectedAgId,
            companyId: currentUser?.companyId,
            dietaryRestrictionsAccommodation: null,
            specificAccommodation: null
        });
        setAction(FORM_ACTION_TYPE.New);
    }

    // member, application and process modals
    const handleMemberModalOpen = (data) => {
        setSelectedApplication(data);
        setMemberModal(true);
    }

    const handleApplicationModalOpen = (data) => {
        setSelectedApplication(data);
        setApplicationModal(true);
        setAction(FORM_ACTION_TYPE.Edit);
    }

    const handleProcessApplicationModalOpen = (data) => {
        setSelectedApplication(data);
        setProcessApplicationModal(true);
    }

    const handleMemberModalClose = () => {
        setSelectedApplication(null);
        setMemberModal(false);
    }

    const handleApplicationModalClose = () => {
        setSelectedApplication(null);
        setAction(null);
        setApplicationModal(false);
        dispatch(getAdvisoryGroupRecruitmentApplicationData(selectedAgId));
    }

    const handleProcessApplicationModalClose = () => {
        setSelectedApplication(null);
        setProcessApplicationModal(false);
    }

    const handleAgUpdateStatus = () => {
        dispatch(getAdvisoryGroupData(currentUser?.companyId));
        selectedAgId && dispatch(getAdvisoryGroupRecruitmentApplicationData(selectedAgId));
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getAdvisoryGroupData(currentUser?.companyId));
        }
    }, [isAppLoaded, dispatch, currentUser]);

    useEffect(() => {
        if (advisoryGroups?.length) {
            let activeAgs = advisoryGroups.filter(ag => ag.status === AG_STATUS.Active || ag.status === AG_STATUS.New);
            setAgList(activeAgs);

            if (selectedAgId) {
                setSelectedAgId(Number(selectedAgId));
            }
        }
    }, [advisoryGroups]);

    useEffect(() => {
        if (!currentUser) {
            return;
        }
        selectedAgId === 0 && dispatch(resetAdvisoryGroupRecruitmentApplicationData());
        selectedAgId && dispatch(getAdvisoryGroupRecruitmentApplicationData(selectedAgId));
    }, [selectedAgId]);

    useEffect(() => {
        if (checkedIds.length) {
            let archiveMembers = [];
            for (let id of checkedIds) {
                let archiveMember = applicationData.find(member => {
                    return member.id === id
                });
                let confirmArchiveMember = pickProps(archiveMember, ['memberId', 'firstName', 'lastName']);
                archiveMembers.push(confirmArchiveMember);
            }
            setConfirmModalData(archiveMembers);
        }
    }, [checkedIds]);

    useEffect(() => {
        if (state && state.agId) {
            setSelectedAgId(state.agId);
        }
        selectedAgId === 0 && dispatch(resetAdvisoryGroupRecruitmentApplicationData());
    }, [location.pathname]);

    useEffect(() => {
        navigate(".", { replace: true });
    }, [navigate]);

    return (
        <div className="mb-4">
            <AgSelector
                items={agList}
                param="shortName"
                value={selectedAgId}
                placeholder="Select Advisory Group"
                noRecordMessage="No advisory groups"
                onSelectChange={handleSelectChange}
                wrapperStyle="w-auto mb-3"
            />
            <ActionBar title="Recruitment Statistics" />
            <div className="mb-4">
                <Placeholder title="Recruitment Statistics - Power BI" />
            </div>
            <ActionBar title="Current Applications">
                <div className="d-flex gap-2">
                    <NewButton
                        disabled={selectedAgId === 0}
                        onClick={handleAddApplicationModalOpen}
                    />
                    {selectedAgId !== 0 && (
                        <Button
                            type="button"
                            onClick={() => {
                                navigate(
                                    `/advisory-group-management/recruitment/${selectedAgStatus === AG_STATUS.New ? 'panel-selection' : 'panel-maintenance'}`,
                                    { state: { agId: selectedAgId } }
                                );
                            }}>
                            {selectedAgStatus === AG_STATUS.New ? 'Panel Selection' : 'Panel Maintenance'}
                        </Button>
                    )}
                    <Button
                        type="button"
                        onClick={handleArchiveConfirm}
                        disabled={checkedIds.length === 0 || selectedAgId === 0}
                    >
                        Archive
                    </Button>
                </div>
            </ActionBar>
            <div>
                {applicationData === null && selectedAgId !== 0 ? (
                    <Loader />
                ) : (
                    <DataGrid
                        data={applicationData || []}
                        columns={columns}
                        sort={defaultSort}
                        enableFilters={false}
                    />
                )}
            </div>

            {memberModal &&
                <MemberRecordModal
                    show={memberModal}
                    data={selectedApplication}
                    agInfo={{
                        id: selectedAgId,
                        status: selectedAgStatus
                    }}
                    onUpdateAgStatus={handleAgUpdateStatus}
                    onCancel={handleMemberModalClose}
                />
            }

            {applicationModal &&
                <MemberApplicationModal
                    action={action}
                    show={applicationModal}
                    data={selectedApplication}
                    onCancel={handleApplicationModalClose}
                />
            }

            {processApplicationModal &&
                <ProcessApplicationModal
                    show={processApplicationModal}
                    applicationData={selectedApplication}
                    statusData={{
                        memberId: selectedApplication.memberId,
                        memberStatus: selectedApplication.memberStatus
                    }}
                    onUpdate={() => dispatch(getAdvisoryGroupRecruitmentApplicationData(selectedAgId))}
                    onCancel={handleProcessApplicationModalClose}
                />
            }

            <RecruitmentMemberArchiveConfirmModal
                show={confirmModal}
                members={confirmModalData}
                message={messages.confirm.archiveRecruitmentMember}
                onConfirm={handleConfirm}
                onCancel={handleConfirmClose}
                onHideCallback={handleConfirmClose}
            />
        </div>
    );
};

export default withAuth(ApplicationDetail);
