import React, { useContext, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { Row, Tab, Tabs } from "react-bootstrap";
import { AppLoadContext } from '../../../components/ui/AppLoadContext';
import withAuth from '../../../components/hoc/withAuth';
import api from "../../../services/api/advisoryGroupService";
import AgSelector from "../../../components/ui/Selector";
import PowerBiReport from "../../../components/powerBi/PowerBiReport";
import NewButton from '../../../components/ui/NewButton';
import Placeholder from '../../../components/ui/Placeholder';

const tabsContent = [
    {
        eventKey: 'empaneledDemographic',
        title: 'Demographics',
        reportName: 'empaneledDemographic'
    },
    {
        eventKey: 'empaneledCompareCohort',
        title: 'Cohort Comparision',
        reportName: 'empaneledCompareCohort',
    },
    {
        eventKey: 'empaneledGeographicDispersion',
        title: 'Geography',
        reportName: 'empaneledGeographicDispersion',
    },
    {
        eventKey: 'empaneledTechnology',
        title: 'Technology',
        reportName: 'empaneledTechnology'
    },
    {
        eventKey: 'empaneledHealthAccess',
        title: 'Health Access',
        reportName: 'empaneledHealthAccess',
    },
    {
        eventKey: 'empaneledAttendanceDemographic',
        title: 'Attendance Demographics',
        reportName: 'empaneledAttendanceDemographic',
    },
    {
        eventKey: 'empaneledAttendanceGeographicDispersion',
        title: 'Attendance Geography',
        reportName: 'empaneledAttendanceGeographicDispersion',
    },
    {
        eventKey: 'empaneledAttendanceSummaryStatsByMeeting',
        title: 'Attendance Summary Stats by Meeting',
        reportName: 'empaneledAttendanceSummaryStatsByMeeting',
    }
];
const PopulationCharacterization = () => {
    const isAppLoaded = useContext(AppLoadContext);
    const filter = {
        $schema: "http://powerbi.com/product/schema#basic",
        target: {
            table: "AdvisoryGroup PopCharMember",
            column: "masterAGId"
        },
        operator: "In",
        values: [1]
    };

    const currentUser = useSelector((state) => state.user.currentUser);

    const [selectedAgId, setSelectedAgId] = useState(0);
    const [agList, setAgList] = useState(null);
    const [disableCohortSelection, setDisableCohortSelection] = useState(true);
    const [activeKey, setActiveKey] = useState('empaneledDemographic');
    const [filterState, setFilterState] = useState(filter);
    const [printReport, setPrintReport] = useState(false);

    const handleSelectChange = (e) => {
        setSelectedAgId(Number(e.target.value));
    };

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            api.getAdvisoryGroupPopChar(currentUser?.companyId).then(({ data }) => {
                setAgList(data);
                if (data.length > 0) {
                    setSelectedAgId(Number(data[0].id));
                }
            });
        }
    }, [isAppLoaded, currentUser]);

    useEffect(() => {
        if (selectedAgId) {
            setFilterState({ ...filter, values: [selectedAgId] });
        }
    }, [selectedAgId]);

    const reportRendered = (val) => {
        setDisableCohortSelection(!val);
    }

    return (
        <div className="h-100 d-flex flex-column">
            <div className={`flex-grow-1 tab-container`}>
                <Tab.Container >
                    <Row className="align-items-start gx-3 align-items-lg-end">

                        <div className={`d-flex align-items-center flex-shrink-0 mb-2 py-1 justify-content-between`}>
                            <AgSelector
                                items={agList}
                                param="shortName"
                                value={selectedAgId}
                                placeholder="Select Advisory Group"
                                noRecordMessage="No active advisory groups"
                                onSelectChange={handleSelectChange}
                                wrapperStyle="w-auto mb-3"
                                disabled={disableCohortSelection}
                            />
                            <NewButton onClick={() => setPrintReport(true)} label="Print"
                                disabled={disableCohortSelection} />
                        </div>
                    </Row>
                    {agList && agList.length > 0 ? <Tab.Content className="mb-4">
                        <Tabs
                            defaultActiveKey="core-demographics"
                            activeKey={activeKey}
                            onSelect={(k) => setActiveKey(k)}
                            variant="underline"
                        >
                            {
                                tabsContent.map((content) => (
                                    <Tab
                                        key={content.eventKey}
                                        eventKey={content.eventKey}
                                        title={content.title}
                                        className="flex-grow-1 mt-1"
                                    >
                                        {content.eventKey === activeKey && <PowerBiReport
                                            title={content.title}
                                            reportName={content.reportName}
                                            filterState={filterState}
                                            printReport={printReport}
                                            setPrintReport={(val) =>
                                                setPrintReport(val)
                                            }
                                            reportRendered={(val) =>
                                                reportRendered(val)
                                            }
                                        />}
                                    </Tab>
                                ))
                            }
                        </Tabs>
                    </Tab.Content> : <div className="embed-wrapper h-100">
                        <Placeholder
                            title={`Power BI `}
                        />
                    </div>
                    }
                </Tab.Container>
            </div>
        </div>
    );
};

export default withAuth(PopulationCharacterization);
