import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

const EmbedReport = ({ accessToken, embedUrl, embedId, filterState, reportRendered, printReport, setPrintReport }) => {
    const [report, setReport] = useState(null);
    const [reportLoaded, setReportLoaded] = useState(false);

    useEffect(() => {
        if (filterState && reportLoaded === true) {
            updateFilters();
        }
    }, [filterState?.values, reportLoaded]);

    useEffect(() => {
        printPowerBiReport();
    }, [printReport]);

    const printPowerBiReport = async () => {
        if (report && printReport === true) {
            await report.print();
            setPrintReport(false);
        }
    }

    const updateFilters = async () => {
        try {
            await report.updateFilters(models.FiltersOperations.Replace, [filterState]);
        }
        catch (errors) {
            console.log(errors);
        }
    }

    const handleLoaded = () => {
        setReportLoaded(true);
    }

    const handleRendered = () => {
        if (reportRendered) {
            reportRendered(true);
        }
    }

    return (
        <>
            <PowerBIEmbed
                embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual, qna, paginated report and create
                    id: embedId,
                    embedUrl: embedUrl,
                    accessToken: accessToken,
                    tokenType: models.TokenType.Embed, // Use models.TokenType.Aad for SaaS embed
                    settings: {
                        panes: {
                            filters: {
                                expanded: false,
                                visible: false
                            }
                        },
                        navContentPaneEnabled: false,
                        background: models.BackgroundType.Transparent,
                    },
                }}

                eventHandlers={
                    new Map([
                        ['loaded', handleLoaded],
                        ['rendered', handleRendered],
                        ['error', function (event) { console.log(event.detail); }],
                    ])
                }
                getEmbeddedComponent={(embedObject) => {
                    setReport(embedObject);
                }}
            />
        </>
    );
};

export default EmbedReport;
