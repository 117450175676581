import { Button } from "react-bootstrap";
import Icon from "./Icon";

const NewButton = ({ btnStyle, label = 'New', ...props}) => {
    return (
        <Button
            type="button"
            variant="primary"
            className={`d-flex align-items-center ${btnStyle ?? ''}`}
            {...props}
        >
            <Icon icon="new" size={10} className="me-1" />{label}
        </Button>
    );
}

export default NewButton;
