import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import ActionBar from "../../../components/ui/ActionBar";
import Loader from "../../../components/ui/Loader";
import DataGrid from "../../../components/DataGrid";
import { AppLoadContext } from "../../../components/ui/AppLoadContext";
import withAuth from "../../../components/hoc/withAuth";
import { getCohortRiskScores, getCohorts, resetCohortRiskScores } from "../../../ducks/cohort";
import { EXPORT_RETENTION_RISK_FORMAT, COHORT_RETENTION_RISK_STATUS, COHORT_STATUS } from "../../../constants";
import { exportData, isValidName, optionValues, sortByString, toDateTime, getRiskScoreColor } from "../../../utils/utils";
import ExportModal from "./ExportModal";
import CohortSelector from "../../../components/ui/Selector";


const RiskScore = () => {
    const { cohortId } = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [selectedCohortId, setSelectedCohortId] = useState(0);
    const [exportModal, setExportModal] = useState(false);
    const [cohortName, setCohortName] = useState('');
    const [sortedCohorts, setSortedCohorts] = useState([]);
    const [sensitivityColumns, setSensitivityColumns] = useState([]);

    const isAppLoaded = useContext(AppLoadContext);
    const cohortList = useSelector((state) => state.cohort.cohorts);
    const sensitivityScores = useSelector((state) => state.cohort.sensitivityScores);
    const currentUser = useSelector((state) => state.user.currentUser);

    const columns = [
        {
            id: 'healthPlanMemberId',
            header: 'Health Plan ID',
            accessorKey: 'healthPlanMemberId',
            accessorFn: (originalRow) => originalRow.healthPlanMemberId?.toString(),
            sortingFn: 'alphanumeric',
        },
        {
            id: 'riskScore3',
            header: 'Risk Score - 3 months',
            accessorKey: 'riskScore3',
            enableColumnFilter: false,
            minSize: 120,
            cell: ({ row }) => (
                <div className={`px-2${(getRiskScoreColor(row.original.riskScore3))}`}>
                    {row.original.riskScore3}
                </div>
            ),
        },
        {
            id: 'riskScore6',
            header: 'Risk Score - 6 months',
            accessorKey: 'riskScore6',
            enableColumnFilter: false,
            minSize: 120,
            cell: ({ row }) => (
                <div className={`px-2${(getRiskScoreColor(row.original.riskScore6))}`}>
                    {row.original.riskScore6}
                </div>
            ),
        },
        {
            id: 'riskScore12',
            header: 'Risk Score - 12 months',
            accessorKey: 'riskScore12',
            enableColumnFilter: false,
            minSize: 120,
            cell: ({ row }) => (
                <div className={`px-2${(getRiskScoreColor(row.original.riskScore12))}`}>
                    {row.original.riskScore12}
                </div>
            ),
        },
        ...sensitivityColumns
    ];
    const exportTypeOptions = optionValues(EXPORT_RETENTION_RISK_FORMAT);
    const arr = [
        { key: 'cohort', labelName: 'File Name', type: 'text', cols: 9 },
        { key: 'type', labelName: 'File Type', type: 'options', optionValues: exportTypeOptions, cols: 3 },
    ];
    const defaultSort = [
        {
            id: 'healthPlanMemberId',
            desc: false,
        },
    ];

    const handleSelectChange = (e) => {
        setSelectedCohortId(Number(e.target.value));
    }

    const handleInputChange = (name, value) => {
        if (name === 'cohort') {
            setCohortName(value);
        }
    }

    const handleExport = (data) => {
        const exportSensitivityColoumns = sensitivityColumns.map(
            ({ accessorKey }) => {
                return `${accessorKey}`;
            }
        );
        let columns = [["Id", "Health Plan ID", "Risk Score - 3 months", "Risk Score - 6 months", "Risk Score - 12 months", ...exportSensitivityColoumns
        ]];
        let fileName = `${toDateTime(new Date())}_${data.cohort}`;

        const riskScoreData = sensitivityScores.map(
            (data) => {
                const { id, healthPlanMemberId, riskScore3, riskScore6, riskScore12 } = data;
                let senScores = {};
                exportSensitivityColoumns?.forEach(element => {
                    senScores = { ...senScores, [element]: data[element] };
                });
                return ({ id, 'Health Plan ID': healthPlanMemberId, 'Risk Score - 3 months': riskScore3, 'Risk Score - 6 months': riskScore6, 'Risk Score - 12 months': riskScore12, ...senScores })
            }
        );

        const rows = sensitivityScores.map(
            (data) => {
                const { id, healthPlanMemberId, riskScore3, riskScore6, riskScore12 } = data;
                let senScores = {};
                exportSensitivityColoumns?.forEach(element => {
                    senScores = { ...senScores, [element]: data[element] };
                });
                return ([id, healthPlanMemberId, riskScore3, riskScore6, riskScore12, ...Object.values(senScores)])
            }
        );

        exportData(riskScoreData, rows, columns, fileName, data.type);
    }

    const handleExportModalShow = () => {
        setExportModal(true);
    }

    const handleExportModalClose = () => {
        setExportModal(false);
        setCohortName('');
    }

    const isValidCohortInfo = () => {
        return isValidName(cohortName);
    }

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
    }, [isAppLoaded]);

    useEffect(() => {
        if (cohortList) {
            setSortedCohorts(sortByString(cohortList.filter(a => a.status !== COHORT_STATUS.ERROR
                && a.retentionRiskStatus === COHORT_RETENTION_RISK_STATUS.Scored), 'name'));
        }
    }, [cohortList]);

    useEffect(() => {
        if (!currentUser || selectedCohortId === 0) {
            return;
        }
        setSensitivityColumns([]);
        selectedCohortId && dispatch(getCohortRiskScores(selectedCohortId));
    }, [selectedCohortId]);

    useEffect(() => {
        if (cohortList?.length > 0 && cohortId) {
            setSelectedCohortId(Number(cohortId));
        }
    }, [cohortList]);

    useEffect(() => {
        selectedCohortId === 0 && dispatch(resetCohortRiskScores());
    }, [location.pathname]);

    useEffect(() => {
        if (sensitivityScores?.length > 0 && selectedCohortId !== 0) {
            const arr = [];
            const uniqueName = [];
            for (let element of sensitivityScores) {
                if (element.sensitivityName !== null) {
                    for (let elementName of element.sensitivityName) {
                        if (!uniqueName.find(s => s === elementName)) {
                            uniqueName.push(elementName);
                            const val =
                            {
                                id: `${elementName}`,
                                header: `${elementName}`,
                                accessorKey: `${elementName}`,
                                sortingFn: 'text',
                                enableColumnFilter: false,
                                minSize: 120,
                                cell: ({ row }) => (
                                    <div className={`px-2${row.original[elementName] >= 80 ? ' bg-red' : ''}`}>
                                        {row.original[elementName]}
                                    </div>
                                ),
                            }

                            arr.push(val)
                        }
                    }
                }
            }

            setSensitivityColumns(arr)
        }
    }, [sensitivityScores]);

    return (
        <>
            <ActionBar back={true}>
                <CohortSelector
                    items={sortedCohorts}
                    param="name"
                    value={selectedCohortId}
                    placeholder="Select Cohort"
                    noRecordMessage="No cohorts"
                    onSelectChange={handleSelectChange}
                    wrapperStyle="w-auto"
                />
                <div>
                    <Button
                        type="button"
                        className="me-2"
                        variant="outline-primary"
                        onClick={() =>
                            navigate('/member-analytics/retention-risk')
                        }>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        variant="primary"
                        disabled={!sensitivityScores?.length}
                        onClick={handleExportModalShow}>
                        Export
                    </Button>
                </div>
            </ActionBar>

            {sensitivityScores === null && selectedCohortId !== 0 ? (
                <Loader />
            ) : (
                <DataGrid
                    columns={columns}
                    data={sensitivityScores || []}
                    sort={defaultSort}
                />
            )}

            <ExportModal
                show={exportModal}
                onCancel={handleExportModalClose}
                onSubmit={handleExport}
                onInputChange={handleInputChange}
                disabled={!isValidCohortInfo()}
                fields={arr}
                title="Risk Score - Export View"
            />
        </>
    );
}

export default withAuth(RiskScore);
