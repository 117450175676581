import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import styles from "./GridButton.module.scss";

const GridButton = ({ title, ...props }) => {
    const renderTooltip = (props) => (
        <Tooltip
            className={styles.tooltip}
            {...props}>
            {title}
        </Tooltip>
    );

    return (
        <OverlayTrigger
            placement="bottom"
            popperConfig={{
                modifiers: [
                    {
                        name: 'preventOverflow',
                        options: {
                            enabled: false,
                        }
                    }
                ]
            }}
            overlay={renderTooltip}>
            <Button {...props}>{props.children}</Button>
        </OverlayTrigger>
    );
};

export default GridButton;
