import Button from "react-bootstrap/Button";
import Icon from "./Icon";

const BackButton = ({ onBack }) => {
    return (
        <Button
            type="button"
            variant="link"
            className="text-decoration-none px-0"
            onClick={onBack}>
            <Icon
                icon="arrow-down"
                size="16"
                className="rotate-90"></Icon>
            <span className="ms-1">Back</span>
        </Button>
    );
}

export default BackButton;
