function SessionExpired() {
    return (
        <div className="d-flex justify-content-center align-items-center h-100">
            <div className="text-center p-3 p-md-5">
                <h1 className="fw-bold">Session Timed Out</h1>
                <div className="h5">Your current session has timed out. Please log in again to continue.</div>
            </div>
        </div>
    );
}

export default SessionExpired;
