import { useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import menuItems from './menuData';
import { DEFAULT_PAGE } from "../../constants";
import SidebarItem from './SidebarItem';
import styles from './Sidebar.module.scss';
import Logo from './Logo';

const SidebarNew = ({ access }) => {

    const currentUser = useSelector((state) => state.user.currentUser);
    const logoBgColor = currentUser?.fileURL?.metaData?.logobgcolor;
    const logoStyle = {
        backgroundColor: logoBgColor ? logoBgColor : 'transparent'
    };
    const pageLocation = useSelector((state) => state.location.currentLocation);
    const [section, setSection] = useState(DEFAULT_PAGE);

    useEffect(() => {
        setSection(pageLocation.name);
    }, [pageLocation, section]);

    return (
        <section className={"bg-primary d-flex flex-column " + styles.sidebar}>
            <div className={`flex-shrink-0 px-3 pt-2 ${styles.logoWrap}`} style={logoStyle}>
                <Logo/>
            </div>
            <div className="flex-grow-1 pb-3">
                {
                    access &&
                    <nav className={styles.mainNav}>
                        {
                            menuItems.map((item, index) => {
                                const key = `${item.label.replace(' ', '-').toLowerCase()}-${index}`;
                                return item.label === section && <SidebarItem key={key} item={item}/>
                            })
                        }
                    </nav>
                }
            </div>
        </section>
    );
}

export default SidebarNew;
