import underConstruction from "../../assets/images/under-development.png";
import styles from "./Placeholder.module.scss";


function Placeholder({ title, type = 'blank', customStyle = '' }) {

    const BlankPlaceholder = () => (
        <div className={`bg-light p-3 h-100 d-flex justify-content-center align-items-center h4 ${customStyle}`}>
            <h1 className="h4 mb-0">{title}</h1>
        </div>
    );

    const GraphicPlaceholder = () => (
        <div className={`p-3 h-100 d-flex flex-column justify-content-center align-items-center h4 ${customStyle}`}>
            <img src={underConstruction} alt="Under Construction" className={styles.underDevelopment} />
            <h1 className="h5 mb-0 text-secondary">{title}</h1>
        </div>
    );

    return (type === 'blank') ? <BlankPlaceholder /> : <GraphicPlaceholder />;
}

export default Placeholder;
