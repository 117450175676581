import { Button } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';

const LoadingButton = ({
    loading = false,
    className,
    onClick,
    children,
    ...props
}) => {
    return (
        <Button
            {...props}
            className={`${className}`}
            onClick={!loading && onClick ? onClick : null}>
            {children}
            {loading && (
                <Spinner
                    animation="border"
                    size="sm"
                    role="status"
                    className="ms-2">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            )}
        </Button>
    );
};

export default LoadingButton;
