import React, { useContext, useEffect, useRef, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Alert, Button, Dropdown, Form } from "react-bootstrap";
import Icon from "../../components/ui/Icon";
import styles from "./MergeCohortModal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { AppLoadContext } from "../../components/ui/AppLoadContext";
import { getCohorts } from "../../ducks/cohort";
import { ALERT_TYPE, COHORT_FILE_STATUS, COHORT_MARKET_TYPE, US_STATE, COHORT_TRAINING_FLAG } from "../../constants";
import { checkWhiteSpace, optionValues } from "../../utils/utils";
import api from "../../services/api/cohortService";
import messages from "../../utils/helper/messages";
import LoadingButton from "../../components/ui/LoadingButton";
import { BsFillExclamationTriangleFill } from "react-icons/bs";
import { AlertContext } from "../../context/AlertContext";

const MergeCohortModal = ({ show, onClose }) => {
    const dispatch = useDispatch();

    const isAppLoaded = useContext(AppLoadContext);
    const { showAlert } = useContext(AlertContext);
    const cohorts = useSelector((state) => state.cohort.cohorts);
    const currentUser = useSelector((state) => state.user.currentUser);
    const inputRef = useRef(null);

    const initialCohortState = { training: 'N', name: '', marketType: COHORT_MARKET_TYPE.Single, market: US_STATE.AL };
    const [mergedCohortItem, setMergedCohortItem] = useState(initialCohortState);
    const [tempMarket, setTempMarket] = useState('');
    const [selectedCohorts, setSelectedCohorts] = useState([]);
    const [cohortList, setCohortList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const marketTypeOptions = optionValues(COHORT_MARKET_TYPE);
    const marketOptions = optionValues(US_STATE);
    const trainingFlagOptions = optionValues(COHORT_TRAINING_FLAG);

    const isMarketTypeMultiple = COHORT_MARKET_TYPE[mergedCohortItem.marketType] === COHORT_MARKET_TYPE.Multiple;

    useEffect(() => {
        if (isAppLoaded) {
            if (!currentUser) {
                return;
            }
            dispatch(getCohorts(currentUser?.companyId));
        }
        // Safe to add dispatch to the dependencies array
    }, [dispatch, isAppLoaded, currentUser]);

    useEffect(() => {
        const validatedCohorts = cohorts?.filter(cohort => cohort.status === COHORT_FILE_STATUS.VALIDATED);
        setCohortList(validatedCohorts);
    }, [cohorts]);

    const handleCohortSelect = (id) => {
        let selectedCohort = cohortList.filter((cohort) => id === cohort.id);
        setSelectedCohorts((prevState) => [...prevState, ...selectedCohort]);
    };

    const handleCohortDeselect = (id) => {
        setSelectedCohorts((prevState) => [
            ...prevState.filter((cohort) => id !== cohort.id),
        ]);
    };

    const handleModalClose = () => {
        setMergedCohortItem(initialCohortState);
        setSelectedCohorts([]);
        onClose();
    }

    const isValid = () => {
        const hasWhiteSpace = checkWhiteSpace(mergedCohortItem.name);

        return !(
            selectedCohorts.length === 0 ||
            mergedCohortItem.name === "" ||
            selectedCohorts.length <= 1 ||
            hasWhiteSpace
        )
    }

    const handleMerge = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const cohortsData = {
            companyId: currentUser?.companyId,
            uploaderUserId: currentUser?.id,
            cohortName: mergedCohortItem.name,
            originalFileNames: selectedCohorts.map(s => s.fileName),
            cohortIds: selectedCohorts.map(s => s.id),
            fileSize: selectedCohorts.reduce((a, v) => a = a + v.size, 0),
            marketType: COHORT_MARKET_TYPE[mergedCohortItem.marketType],
            market: mergedCohortItem.market,
            training: mergedCohortItem.training
        }

        try {
            await api.mergeCohorts(cohortsData).then(() => {
                setIsLoading(false);
                showAlert(messages.success.mergeCohortMember, ALERT_TYPE.SUCCESS);

                handleModalClose();
            });
        }
        catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
        }
    };

    const onSelectionChange = (e, val) => {
        e.target.checked ? handleCohortSelect(val) : handleCohortDeselect(val);
    }

    const handleCohortItemChange = (name, value) => {
        if (name === "marketType") {
            handleMergeCohortStateUpdate({ [name]: value });
            if (COHORT_MARKET_TYPE[value] === COHORT_MARKET_TYPE.Multiple) {
                setTempMarket(mergedCohortItem.market);
                handleMergeCohortStateUpdate({ market: 'ZZ' });
            } else {
                handleMergeCohortStateUpdate({ market: tempMarket });
            }
        } else {
            handleMergeCohortStateUpdate({ [name]: value });
        }
    }

    const handleMergeCohortStateUpdate = (update) => {
        setMergedCohortItem((details) => ({ ...details, ...update }))
    }

    return (
        <Modal show={show} fullscreen={false} backdrop="static" onHide={handleModalClose} size="md">
            <Modal.Header closeButton>
                <Modal.Title>Merge Cohort</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <div className="hstack gap-2 justify-content-end mb-3">
                        <Button variant="outline-primary" onClick={handleModalClose}>
                            Cancel
                        </Button>
                        <LoadingButton
                            type="submit"
                            className="flex-shrink-0"
                            disabled={!isValid() || isLoading}
                            loading={isLoading}
                            onClick={handleMerge}
                        >
                            Merge
                        </LoadingButton>
                    </div>

                    <Form.Group>
                        <Form.Label>Cohort Name</Form.Label>
                        <Dropdown className={`${styles.cohortSelector} w-100`} autoClose="outside">
                            <Dropdown.Toggle variant="light" id="dropdown-autoclose-true">
                                Select Cohorts to Merge
                            </Dropdown.Toggle>

                            <Dropdown.Menu className="py-2">
                                {
                                    cohortList?.length ?
                                        cohortList.map((cohort) => (
                                            <div className="px-3" key={cohort.id}>
                                                <Form.Check
                                                    type="checkbox"
                                                    id={`cohort-${cohort.id}`}
                                                    label={cohort.name}
                                                    onChange={(e) => onSelectionChange(e, cohort.id)}
                                                />
                                            </div>
                                        ))
                                        :
                                        <div className="px-3 text-muted text-center">
                                            No validated cohorts
                                        </div>
                                }
                            </Dropdown.Menu>
                        </Dropdown>
                    </Form.Group>

                    <div className="my-3 text-center">
                        <Icon icon="merge" size={32} className={styles.mergeIcon} />
                    </div>

                    <Form.Group className="mb-3">
                        <Form.Label>New Cohort Name</Form.Label>
                        <Form.Control
                            type="text"
                            name="name"
                            ref={inputRef}
                            value={mergedCohortItem.name}
                            onChange={(e) => handleCohortItemChange(e.target.name, e.target.value)}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Training Cohort</Form.Label>
                        <Form.Select
                            value={mergedCohortItem.training}
                            name="training"
                            onChange={(e) => handleCohortItemChange(e.target.name, e.target.value)}
                        >
                            {trainingFlagOptions.map((optionInfo) => {
                                return (
                                    <option
                                        key={optionInfo.id}
                                        value={optionInfo.id}>
                                        {optionInfo.value}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Market Type</Form.Label>
                        <Form.Select
                            value={mergedCohortItem.marketType}
                            name="marketType"
                            onChange={(e) => handleCohortItemChange(e.target.name, e.target.value)}
                        >
                            {marketTypeOptions.map((optionInfo) => {
                                return (
                                    <option
                                        key={optionInfo.id}
                                        value={optionInfo.id}>
                                        {optionInfo.value}
                                    </option>
                                );
                            })}
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="mb-3">
                        <Form.Label>Market</Form.Label>
                        {isMarketTypeMultiple ? <Form.Control
                            value={mergedCohortItem.market}
                            name="market"
                            disabled={true}
                            onChange={(e) => handleCohortItemChange(e.target.name, e.target.value)}
                        /> : <Form.Select
                            name="market"
                            value={mergedCohortItem.market}
                            onChange={(e) => handleCohortItemChange(e.target.name, e.target.value)}
                        >
                            {marketOptions.map((optionInfo) => {
                                return (
                                    <option
                                        key={optionInfo.id}
                                        value={optionInfo.id}>
                                        {optionInfo.value}
                                    </option>
                                );
                            })}
                        </Form.Select>
                        }
                    </Form.Group>

                    <Alert
                        variant="warning"
                        className="mt-2 py-2 lh-sm"
                    >
                        <div className="d-flex align-items-center">
                            <BsFillExclamationTriangleFill className="me-2 flex-shrink-0" />
                            {messages.error.cohortNameValidation}
                        </div>
                    </Alert>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default MergeCohortModal;
