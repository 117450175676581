import React from "react";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../components/ui/Form";
import withAuth from "../../../components/hoc/withAuth";
import {
    ALIGN,
    POSITIONS,
} from '../../../constants';

const OperstionsStatusModal = ({ show, onCancel, data }) => {
    const arr = [
        {
            key: 'shortName',
            labelName: 'AG Name',
            type: 'text'
        },
        {
            key: 'market',
            labelName: 'Market',
            type: 'text'
        },
        {
            key: 'memberCount',
            labelName: '# of Members',
            type: 'text'
        },
        {
            key: 'nextMeetingTime',
            labelName: 'Next Meeting Date',
            type: 'date'
        },
        {
            key: 'lastMeetingTime',
            labelName: 'Last Meeting Date',
            type: 'date'
        },
        {
            key: 'selectionGrade',
            labelName: 'Selection Grade',
            type: 'text'
        },
        {
            key: 'currentParticipationGrade',
            labelName: 'Current Participation Grade',
            type: 'text'
        },
    ];

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Operations Status - View</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data}
                        onCancel={onCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        viewOnly={true}
                    />
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withAuth(OperstionsStatusModal);
