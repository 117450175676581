import React, {useEffect, useState} from 'react';
import withAuth from "../../components/hoc/withAuth";
import Iframe from 'react-iframe';
import {useDispatch, useSelector} from "react-redux";
import {getStarPlannerShinyURL} from "../../ducks/quality-management";
import {useMsal} from "@azure/msal-react";
import Loader from "../../components/ui/Loader";

function StarPlanner() {

    const {instance} = useMsal();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(true);

    const starPlannerShinyURL = useSelector((state) => state.qualityManagement.starPlannerShinyURL);

    useEffect(() => {
        async function fetchData() {
            if (!starPlannerShinyURL) {
                await dispatch(getStarPlannerShinyURL(instance));
            }

            setIsLoading(false);
        }

        fetchData();
    }, [dispatch, instance, starPlannerShinyURL]);

    if (isLoading) {
        return (
            <Loader />
        );
    }

    return (
        <>
        {
            starPlannerShinyURL ? <Iframe
                url={starPlannerShinyURL}
                width="100%"
                height="100%"
                sandbox=""
                display="block"
                position="relative"/> : null
        }
        </>
    );
}

export default withAuth(StarPlanner);
