import { protectedResources } from "../../msalAuthConfig";


export const acquireAccessToken = async (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        /*
        * User is not signed in. Throw error or wait for user to login.
        * Do not attempt to log a user in outside of the context of MsalProvider
        */
        return null;
    }

    const request = {
        scopes: protectedResources.api.scopes,
        account: activeAccount || accounts[0]
    };

    const authResult = await msalInstance.acquireTokenSilent(request);

    return authResult.accessToken;
};

export const getAccountId = (msalInstance) => {
    const activeAccount = msalInstance.getActiveAccount();
    const accounts = msalInstance.getAllAccounts();

    if (!activeAccount && accounts.length === 0) {
        return null;
    }

    if (activeAccount) {
        return activeAccount.username;
    } else {
        return accounts[0].username;
    }
};
