import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { isPhoneNumberValid, parsePhoneNumber } from "../../utils/utils";
import { ALERT_TYPE, USER_ROLE } from "../../constants";
import React, { useContext, useState } from "react";
import { acquireAccessToken } from "../../services/api/auth";
import { protectedResources } from "../../msalAuthConfig";
import { getCurrentUser, getUsers } from "../../ducks/user";
import { ApiInstance } from "../../views/admin/user/UserManagement";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import LoadingButton from "./LoadingButton";
import messages from "../../utils/helper/messages";
import { AlertContext } from "../../context/AlertContext";

export const UserProfileModal = ({ showModal, selectedUser, inputChanged, onHideCallback, onFormSubmitCallback, isReadOnly, isDirty = false }) => {
    const dispatch = useDispatch();
    const { instance } = useMsal();
    const { showAlert } = useContext(AlertContext);
    const [formValidated, setFormValidated] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const currentUser = useSelector((state) => state.user.currentUser);

    const onFormSubmit = async e => {
        const form = e.currentTarget;
        e.preventDefault();

        const formData = new FormData(e.target);
        const formDataObj = Object.fromEntries(formData.entries());

        if (!isPhoneNumberValid(formDataObj.phone)) {
            setFormValidated(false);
            return;
        }

        if (form.checkValidity() === false) {
            setFormValidated(true);
            return;
        }

        // normalize phone number
        if (formDataObj.phone) {
            formDataObj.phone = parsePhoneNumber(formDataObj.phone);
        }

        try {
            setIsLoading(true);
            let accessToken = await acquireAccessToken(instance);
            ApiInstance.setAuth(accessToken);
            ApiInstance.setContext(showAlert);
            let result = await ApiInstance({
                method: 'PUT',
                url: `${protectedResources.apiUsers.endpoint}/update`,
                data: {
                    userData: { id: selectedUser.id, recordStatus: selectedUser.recordStatus, ...formDataObj }
                }
            });
            if (result.data) {
                onFormSubmitCallback(result.data);
                showAlert(messages.success.updateUser, ALERT_TYPE.SUCCESS);

                // update user initials if current user
                if (Number(selectedUser.id) === currentUser.id) {
                    dispatch(getCurrentUser(instance));
                }

                // fetch and refresh users
                dispatch(getUsers(instance));

                onHideCallback();
            }
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            showAlert(`${messages.error.prefix} ${error?.response?.data?.error || error}.`, ALERT_TYPE.ERROR);
        }
    };

    return (
        <Modal show={showModal} fullscreen={false} onHide={onHideCallback}>
            <Modal.Header closeButton>
                <Modal.Title>{isReadOnly ? 'User Profile' : 'Edit User'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form noValidate validated={formValidated} onSubmit={onFormSubmit}>
                    <Form.Group className="mb-3" controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control
                            name="firstName"
                            placeholder="Enter first name"
                            value={selectedUser?.firstName}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control
                            name="lastName"
                            placeholder="Enter last name"
                            value={selectedUser?.lastName}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formEmail">
                        <Form.Label>Email Address</Form.Label>
                        <Form.Control
                            name="email"
                            type="email"
                            placeholder="Enter email"
                            value={selectedUser?.email}
                            onChange={inputChanged}
                            required
                            disabled={isReadOnly}
                        />
                        {!isReadOnly ? <Form.Text className="text-muted">
                            Updating this value will require the user to re-auth.
                        </Form.Text> : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formPhone">
                        <Form.Label>Phone Number</Form.Label>
                        <Form.Control
                            name="phone"
                            type="phone"
                            placeholder="Enter phone number"
                            value={selectedUser?.phone}
                            onChange={inputChanged}
                            isInvalid={!isPhoneNumberValid(selectedUser?.phone)}
                            required
                            disabled={isReadOnly}
                            maxLength={10}
                        />
                        <Form.Control.Feedback type="invalid">
                            It looks like the number entered isn't registered as a valid phone number.
                        </Form.Control.Feedback>
                        {!isReadOnly ? <Form.Text className="text-muted">
                            Updating this value will require the user to re-auth.
                        </Form.Text> : null}
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formRole">
                        <Form.Label>Role</Form.Label>
                        <Form.Select
                            name="roleId"
                            defaultValue={selectedUser?.roleId ? selectedUser.roleId : USER_ROLE.USER}
                            onChange={inputChanged}
                            disabled={isReadOnly}
                        >
                            <option value={USER_ROLE.USER}>User</option>
                            <option value={USER_ROLE.CLIENT_ADMIN}>Client Admin</option>
                            <option value={USER_ROLE.SYSTEM_ADMIN}>System Admin</option>
                        </Form.Select>
                    </Form.Group>

                    {!isReadOnly ? <LoadingButton variant="primary" type="submit" disabled={isLoading || !isDirty} loading={isLoading}>Update</LoadingButton> : null}
                </Form>

            </Modal.Body>
        </Modal>
    );
};
