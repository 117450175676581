import React, { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import SharedForm from "../../../components/ui/Form";
import FileProgress from "../../../components/ui/FileProgress";
import withAuth from "../../../components/hoc/withAuth";
import messages from "../../../utils/helper/messages";
import {
    ALERT_TYPE,
    ALIGN,
    POSITIONS,
    SUBMIT_ACTION
} from "../../../constants";
import { AlertContext } from "../../../context/AlertContext";

const ExportModal = ({ show, title, fields, onCancel, onSubmit, onInputChange, disabled = false }) => {
    const { showAlert } = useContext(AlertContext);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState({});

    const Submit = async (val) => {
        setIsLoading(true);
        try {
            setProgress(25);
            setFile({ name: 'tempFile', size: 20000 });
            await onSubmit(val);
            setTimeout(() => {
                setProgress(100);
                setTimeout(() => {
                    handleAfterSubmit(SUBMIT_ACTION.SUCCESS);
                }, 500);
            },200);
        } catch (error) {
            handleAfterSubmit(SUBMIT_ACTION.ERROR, error);
        }
    }

    const onHideCallback = () => {
        setFile({});
        onCancel();
    }

    const handleAfterSubmit = (type, error) => {
        setIsLoading(false);
        setProgress(0);
        if(type === SUBMIT_ACTION.SUCCESS) {
            showAlert(messages.success.cohortExport, ALERT_TYPE.SUCCESS);
            onHideCallback();
        } else {
            showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
            setFile({});
        }
    }

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={onHideCallback}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-3 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={fields}
                        onSubmit={Submit}
                        onCancel={onHideCallback}
                        onInputChanged={onInputChange}
                        disabled={disabled}
                        loading={isLoading}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText="Download"
                    />
                    {progress > 0 && (
                        <FileProgress
                            file={file}
                            progress={progress}
                            wrapperStyle="mt-2"
                        />
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
}

export default withAuth(ExportModal);
