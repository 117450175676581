import React, { useEffect, useState, useContext } from 'react';
import { AppLoadContext } from '../../components/ui/AppLoadContext'
import PowerBiStarExplorer from './powerBiStarExplorer';
import { useSelector } from 'react-redux'
import withAuth from "../../components/hoc/withAuth";

function Timely() {

    const [reportConfig, setReportConfig] = useState(null);

    const report = useSelector((state) => state.report);

    const isAppLoaded = useContext(AppLoadContext);

    useEffect(() => {
        if (isAppLoaded && report.timely.embedId)
            setReportConfig({ ...report.timely, accessToken: report.accessToken });
    }, [report, isAppLoaded]);

    return (
        <PowerBiStarExplorer
            reportConfig={reportConfig}
            title='Timely Card'
        />
    );
}

export default withAuth(Timely);
