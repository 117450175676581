import React, {useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {getCompany} from '../../ducks/company'
import {Button} from "react-bootstrap";
import DataGrid from "../../components/DataGrid";
import {FaPencilAlt, FaTrashAlt} from "react-icons/fa";

const handleEdit = () => {
    console.log('edit clicked');
}

const handleDelete = () => {
    console.log('delete clicked');
}

const columns = [
    {
        id: 'logo',
        header: 'Logo',
        cell: ({row}) => <img src={row.original.logo} alt={row.original.name} height={40} width={'auto'} />,
    },
    {
        id: 'name',
        header: 'Company Name',
        accessorKey: 'name',
        sortingFn: 'text'
    },
    {
        id: 'fullName',
        header: 'Contact Person',
        accessorFn: row => `${row.contactFirstName} ${row.contactLastName}`,
        sortingFn: 'text'
    },
    {
        id: 'contactEmail',
        header: 'Email',
        accessorKey: 'contactEmail',
        sortingFn: 'text'
    },
    {
        id: 'address',
        header: 'Company Address',
        accessorKey: 'address',
        enableSorting: false,
    },
    {
        id: 'city',
        header: 'City',
        accessorKey: 'city',
        sortingFn: 'text'
    },
    {
        id: 'state',
        header: 'State',
        accessorKey: 'state',
        sortingFn: 'text'
    },
    {
        name: 'actions',
        header: 'Actions',
        cell: () => (
            <>
                <Button variant="light" size="sm" className="me-1 my-1" onClick={handleEdit}>
                    <FaPencilAlt/>
                </Button>
                <Button variant="light" size="sm" className="my-1" onClick={handleDelete}>
                    <FaTrashAlt/>
                </Button>
            </>
        )
    },
];

function CompanyReactTable() {
    const dispatch = useDispatch()

    const company = useSelector((state) => state.company);
    useEffect(() => {
        dispatch(getCompany())
        // Safe to add dispatch to the dependencies array
    }, [dispatch])
    console.log(company.companies);

    return (<>
        { company.companies !== null &&
                <DataGrid
                    columns={columns}
                    data={company.companies}
                    enableFilters={false}
            />
        }
        </>
    );
}

export default CompanyReactTable;
