import { Col, Row } from 'react-bootstrap';
import { FaFile, FaList, FaStar } from 'react-icons/fa';
import Blog from '../Blog';
import InfoCard from '../../components/ui/InfoCard';
import withAuth from '../../components/hoc/withAuth';

const QualityManagementHospitalHome = () => {
    return (
        <Row className="h-100">
            <Col md={6} className="d-flex">
                <div>
                    <p className="lead p-3">The <span className="fw-bold">Hospital Survey</span> provides different tools that can be used to explore the
                        measurements found in the <span className="fw-bold">CMS Hospital Stars</span> program.</p>

                    <InfoCard title="Star Explorer" icon={FaStar} link="/quality-management/hospital/star-explorer">
                        <p className="mb-0">The Star Explorer provides the ability to view the summary results of
                            the recent Hospital Star assessment from a variety of different perspectives.
                            Information on national, state, and local results is coupled with other operational
                            views of this information</p>
                    </InfoCard>

                    <InfoCard title="Star Report Card" icon={FaFile} link="/quality-management/hospital/star-report">
                        <p className="mb-0">The Star Report Card allows you to view the results of an individual
                            facility at both a summary and detailed level. Multiple facilities can be selected and
                            viewed in a side-by-side comparison.</p>
                    </InfoCard>

                    <InfoCard title="Star Planner" icon={FaList} link="/quality-management/hospital/star-planner">
                        <p className="mb-0">The Star Planner allows the user to model the impact of changes to
                            either summary or detailed level results</p>
                    </InfoCard>

                </div>
            </Col>
            <Col md={6} className="pt-md-3 ps-md-4">
                <Blog/>
            </Col>
        </Row>
    );
}

export default withAuth(QualityManagementHospitalHome);
