import React, { useState, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import SharedForm from '../../../components/ui/Form';
import { POSITIONS, ALIGN, RECORD_STATUS_ABBR, ALERT_TYPE, VALIDATIONS } from "../../../constants";
import { archiveMemberComment, getMemberComments, insertMemberComment } from '../../../ducks/advisoryGroup';
import messages from '../../../utils/helper/messages';
import { AlertContext } from '../../../context/AlertContext';
import Comment from '../../../components/ui/Comment';

const CommentsOutreachModal = ({ data, agId, show, onCancel }) => {
    const dispatch = useDispatch();
    const { showAlert } = useContext(AlertContext);

    const [showCommentBox, setShowCommentBox] = useState(false);
    const [loading, setLoading] = useState(false);
    const [commentDetails, setCommentDetails] = useState(null);

    const currentUser = useSelector((state) => state.user?.currentUser);
    const comments = useSelector((state) => state.advisoryGroup?.memberCommentList);

    const handleCancel = () => {
        setShowCommentBox(false);
        onCancel();
        setCommentDetails(null);
    };

    const arr = [
        {
            key: 'comment',
            labelName: showCommentBox ? 'Comments' : '',
            placeholderName: 'Comment',
            type: 'textarea',
            required: false,
            hidden: !showCommentBox,
            maxLength: 255,
            valid: VALIDATIONS.String.noSpecialChars,
        },
    ];

    const handleDeleteSuccess = () => {
        setLoading(false);
        showAlert(messages.success.deleteOutreachComment, ALERT_TYPE.SUCCESS);
        dispatch(getMemberComments(data.id));
    };

    const handleDeleteError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error}.`, ALERT_TYPE.ERROR);
    };

    const handleCommentSuccess = () => {
        setLoading(false);
        onCancel();
        setCommentDetails(null);
        setShowCommentBox(false);
        showAlert(messages.success.addOutreachComment, ALERT_TYPE.SUCCESS);
    }

    const handleCommentError = (error) => {
        setLoading(false);
        showAlert(`${messages.error.prefix} ${error?.response?.data?.error || error}.`, ALERT_TYPE.ERROR);
    }

    const handleSubmit = (formData) => {
        const commentData = {
            agId: agId,
            status: RECORD_STATUS_ABBR.Operational,
            companyId: currentUser.companyId,
            agMemberId: data.id,
            userId: currentUser.id,
            comment: formData.comment,
        };
        if (formData.comment) {
            setLoading(true);
            dispatch(insertMemberComment(commentData, handleCommentSuccess, handleCommentError));
        }
    };

    const handleDelete = (id) => {
        const deleteCommentData = {
            id: id,
            status: RECORD_STATUS_ABBR.Archived,
        };
        dispatch(archiveMemberComment(deleteCommentData, handleDeleteSuccess, handleDeleteError));
    };

    const handleCommentDetailsChange = (update) => setCommentDetails((details) => ({ ...details, ...update }));

    const inputChange = (name, value) => handleCommentDetailsChange({ [name]: value });

    const handleAddComment = () => setShowCommentBox(true);

    return (
        <Modal
            show={show}
            fullscreen={false}
            backdrop="static"
            onHide={handleCancel}
            size="md">
            <Modal.Header closeButton>
                <Modal.Title>Comments</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="px-xl-2 py-xl-2">
                    <SharedForm
                        cols={12}
                        array={arr}
                        modelObj={data?.comment}
                        onSubmit={handleSubmit}
                        onCancel={handleCancel}
                        actionBarPosition={POSITIONS.TOP}
                        actionBarAlign={ALIGN.RIGHT}
                        submitButtonText={'Save'}
                        loading={loading}
                        disabled={!showCommentBox || commentDetails?.comment === ''}
                        onInputChanged={inputChange}
                    />
                    <Comment comments={comments} onAddComment={handleAddComment} commentBox={showCommentBox} onDelete={handleDelete} />
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CommentsOutreachModal;
